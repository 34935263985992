import { get } from "lodash";
import { getVipFreeActivity } from "@/service/vip";
import { getActCenterList } from "@/service/activity";

const defaultActList = {
  vip: {
    title: "VIP折扣",
    intro: "会员折扣限时领取，现在开通低至0.27元/天",
    time: "",
    bgImg:
      "https://qncweb.ktvsky.com/20230801/vadd/5c84c228882c95feb0f70ca905cae984.png",
    detail: "vip",
    color: "#FFE6D2",
  },
  singTask: {
    title: "演唱活动",
    intro: "活动期间内通过唱歌时长的累积，进行 VIP 会员发放",
    time: "活动时间：2023/08/01-2023/08/31",
    bgImg:
      "https://qncweb.ktvsky.com/20230801/vadd/35962cf763d164c329d541a92980daa5.png",
    detail: "singTask",
    color: "#E5F3FF",
  },
  signin: {
    title: "每日签到",
    intro: "根据签到的累积天数进行VIP奖励发放",
    time: "活动时间：2023/07/25-2023/07/31",
    bgImg:
      "https://qncweb.ktvsky.com/20230801/vadd/6df19a49a13d036464759205262f6506.png",
    detail: "signin",
    color: "#FAD6FF",
  },
};

const state = {
  actList: [],
  isShowActEnter: false,
  vipFreeActivity: null,
};

const getters = {};

const actions = {
  async setActList({ commit, dispatch }) {
    const res = await getActCenterList();
    commit("SAVE_ISSHOW_ACTENTER", res.online === 1);
    if (res.data.length) {
      let act = res.data
        .map((v) => {
          let obj = {};
          switch (v.name) {
            case "VIP折扣":
              obj = defaultActList.vip;
              break;
            case "演唱活动":
              obj = defaultActList.singTask;
              if (v.online === 1)
                dispatch("singTime/setIsShowSingTaskEnter", {}, { root: true });
              break;
            case "每日签到":
              obj = defaultActList.signin;
              // 此处有单独得活动状态接口 暂无需重复设置
              // if (v.online === 1) dispatch('signin/setIsShowSignIn', {}, { root: true })
              break;
          }
          return {
            ...v,
            ...obj,
          };
        })
        .filter((i) => i.online === 1);
      commit("SAVE_ACTLIST", act);
    }
  },
  async getVipFreeActivity({ commit }, unionid) {
    // console.log("unionid", unionid);
    const res = await getVipFreeActivity(unionid);
    commit("SAVE_VIP_FREE_ACTIVITY", get(res, "data.freevip_created", null));
  },
  resetVipFreeActivity({ commit }) {
    commit("SAVE_VIP_FREE_ACTIVITY", null);
  },
};

const mutations = {
  SAVE_ACTLIST(state, data) {
    state.actList = data;
  },
  SAVE_ISSHOW_ACTENTER(state, data) {
    state.isShowActEnter = data;
  },
  SAVE_VIP_FREE_ACTIVITY(state, data) {
    state.vipFreeActivity = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
