<template>
  <CommonModal ref="root" :onCancel="handleCancel" :zIndex="103">
    <div class="xifen-modal-content">
      <div class="left">
        <div class="title">{{payload.isLogin ? '关注公众号' : '授权登录'}} <span>免费解锁</span></div>
        <p>《{{payload.songInfo.music_name}}》</p>
        <p>此歌曲为VIP歌曲</p>
      </div>
      <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
    </div>
  </CommonModal>
</template>

<script setup>
import { ref, computed, onBeforeMount, defineProps, toRefs, watch } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus';

const props = defineProps({
  songid: {
    type: Number,
    default: 0 // 当通过点歌打开弹框时增加二维码增加songid参数，埋点使用
  },
  log: {
    type: String,
    default: '首页-画质歌单-画质权益弹窗'
  },
  cancelEvent: {
    type: Function,
    default: () => {}
  },
  payload: {
    type: Object,
    default: () => {}
  },
})

const { songid, log, payload } = toRefs(props)
const { getQRCodeURL } = useQRCode()

const root = ref(null)
const qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

const userType = computed(() => store.state.userInfo.userType)
const subscribe_gzh = computed(() => store.state.subscribe_gzh)
const freeVipNumber = computed(() => store.state.freeVipNumber)
const carplayInfo = computed(() => store.state.carplayInfo)

const getVipQrcode = () => {
  if (!payload.value.isLogin) {
    handleGuestFlow();
  } else {
    handleLoggedInFlow();
  }
};

const handleGuestFlow = async () => {
  const payQr = get(carplayInfo.value, 'pay_qr', '');

  if (payQr) {
    const queryParams = [
      `songid=${songid.value || ''}`,
      `log=${vipLogFrom.get(log.value)}`,
      `ut=${userType.value}`
    ].join('&');

    const qrCodeData = await getQRCodeURL(`${payQr}&${queryParams}`);

    if (qrCodeData) {
      qrCodeURL.value = qrCodeData;
      
      sendLog({
        event_type: '10000~50000',
        event_name: 30315,
        event_data: {
          str1: '首页',
          str2: '播放器-登陆拦截弹框',
          str3: '展示',
          str4: 'show',
          str5: '未登陆',
        },
      })
    }
  } else {
    Toast('未获取到登录二维码');
  }
};

const handleLoggedInFlow = async () => {
  qrCodeURL.value = await getQRCodeURL(subscribe_gzh.value.qr);
      
  sendLog({
    event_type: '10000~50000',
    event_name: 30316,
    event_data: {
      str1: '首页',
      str2: '播放器-关注拦截弹框',
      str3: '展示',
      str4: 'show',
      str5: '已登陆',
    },
  })
};

const handleCancel = () => {
  // 点击其他区域关闭弹窗时，也去刷一次
  store.dispatch('getCarplayInfo')
  store.dispatch('fetchFreeVip')
}

onBeforeMount(getVipQrcode)

watch(freeVipNumber, async val => {
  if (val > 0) {
    await eventBus.emit('xifen-order-song', payload.value)
    root.value.hide()
  }
})

</script>

<style lang="stylus" scoped>
.xifen-modal
  &-content
    position relative
    width 928px
    height 604px
    padding-top 0 !important
    background url(https://qncweb.ktvsky.com/20241105/other/18f8c1c29aee1d8d518ba906d1bfb01e.png) no-repeat
    background-size 100% 100%
    background-position center
    display flex
    justify-content space-between
    .left
      color #642F0E
      font-size 45px
      text-align center
      margin-top 80px
      margin-left 50px
      width 560px
      p
        font-weight 600
        max-width 100% !important
        width 100%
        overflow hidden
        text-overflow ellipsis
        display -webkit-box
        -webkit-line-clamp 2
        -webkit-box-orient vertical
      .title
        font-size 54px
        font-weight 900
        margin-bottom 20px
        span
          color #ED3F34
          font-weight 900
    .qrcode
      width 224px
      height 224px
      margin-right 75px
      margin-top 65px
      img
        width 100%
</style>