<template>
  <div class="singer-order-side">
    <div class="singer-order-side-main">
      <div ref="singerTabRefs" class="singer-tabs">
        <div class="tab"
          v-for="item in tabList"
          :key="item"
          :class="{'active':curTab == item}"
          @click="handleChangeTab(item)"
        >
          {{ item }}
        </div>
      </div>
      <LoadMore
        class="singer-list"
        ref="loadMoreRef"
        @load-more="fetchSingerList"
        safeAreaHeight="9.6991vw"
      >
        <template v-if="oftenSingSingerList.length">
          <SecContainer
            title="常唱歌手"
            class="often-sing-singer"
          >
            <div class="often-sing-singer-list">
              <SingerItem
                className="often-sing-singer-list-item"
                v-for="(item, index) in oftenSingSingerList"
                :singer="item"
                :key="index"
                @click="handleClickSinger(item, '常唱歌手')"
              />
            </div>
          </SecContainer>
          <div class="diver-line"></div>
        </template>
        <SingerItem
          v-for="(item, index) in singerUnionList"
          :singer="item"
          :key="index"
          @click="handleClickSinger(item, '列表歌手')"
        ></SingerItem>
      </LoadMore>
      <p class="hint" v-if="isEmpty && singerUnionList.length > 20">已加载全部</p>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount, ref, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import SecContainer from '@/components/section-container/index.vue'
import SingerItem from '@/components/singer-item/index.vue'
import { getSingsingerList } from '@/service/singing'
import { getSingerClassList, getSingerList } from '@/service/singer'
import { sendLog } from '@/directives/v-log/log'
import _ from 'lodash'
// import SingerTabs from './singer-tab.vue'

export default {
  name: 'SingerOrder',
  components: {
    SecContainer,
    SingerItem,
    // SingerTabs,
  },
  setup(props, { emit }) {
    const store = useStore()
    const unionid = computed(() => store.state.userInfo.unionid)
    const isLogin = computed(() => !!unionid.value)

    let loadMoreRef = ref(null)
    let singerTabRefs = ref(null)
    let tabScrollLeft = ref(0)
    let oftenSingSingerList = ref([])
    let tabList = ref([])
    let singerList = ref([])
    let curTab = ref('')
    let p = 1
    let version = {
      current: '',
      latest: ''
    }
    let isEmpty = ref(false)
    let isRequest = false

    let singerHeaderIsfixed = ref(false)

    const handleClickSinger = ({singername, singerheader, singerid}, type) => {
      sendLog({
        event_type: '10000~50000',
        event_name: type === '常唱歌手' ? 10101 : 10102,
        event_data: {
          str1: '快速点歌',
          str2: '歌手点歌',
          str3: `点击${type}`,
          str4: 'click',
        },
      })
      emit('singer-click', {
        singer: singername,
        singerhead: singerheader,
        singerid,
      })
    }

    const initOftenSingSinger = async () => {
      if (unionid.value) {
        oftenSingSingerList.value = await getSingsingerList({
          unionid: unionid.value
        })
      }
    }

    // 歌手列表去重
    const singerUnionList = computed(() => {
      const idsMap = new Map();
      singerList.value && singerList.value.forEach((singer) => {
        if (singer && !idsMap.has(singer.singerid)) {
          idsMap.set(singer.singerid, singer);
        }
      });
      return Array.from(idsMap.values());
    });

    const fetchSingerClassList = async () => {
      tabList.value = await getSingerClassList()
      handleChangeTab(tabList.value[0])
    }

    const fetchSingerList = async (callBack) => {
      if (isRequest) {
        return
      }
      isRequest = true
      
      let bussinessResponseData = {}
        bussinessResponseData = await getSingerList({
          p,
          k: curTab.value,
          version: version.latest
        })
      
      if (bussinessResponseData.data?.length !== 0) {
        if (p === 1 && bussinessResponseData.version) {
          version = bussinessResponseData.version
        }
        singerList.value = p === 1 ? bussinessResponseData.data : singerList.value.concat(bussinessResponseData.data);
        p++
      }
      isRequest = false
      if (callBack) callBack()
    }

    const handleChangeTab = async (tab) => {
      curTab.value = tab

      await nextTick()
      const tabElement = singerTabRefs.value.querySelector('.tab.active');
      if (tabElement) {
        tabElement.scrollIntoView({ behavior: 'smooth', inline: 'center'});
      }
    }

    const handleTabScroll = (e) => {
      tabScrollLeft.value = e.target.scrollLeft
      // console.log(tabScrollLeft.value)
    }

    onMounted( () => {
      initOftenSingSinger()
      fetchSingerClassList()
    
      setTimeout(() => {
        if (singerTabRefs.value) singerTabRefs.value.addEventListener('scroll', handleTabScroll, false)
      }, 3000)
    })

    onBeforeUnmount(() => {
      if (singerTabRefs.value) singerTabRefs.value.removeEventListener('scroll', handleTabScroll)
    })

    watch(isLogin, (val) => {
      if (val) initOftenSingSinger()
    })

    watch(curTab, (tab) => {
      if (tab) {
        // singerList.value = []
        p = 1
        fetchSingerList(async() => {
          await nextTick(() => {
            // 在DOM更新后再访问$refs
            console.log(singerTabRefs.value, loadMoreRef.value)
            if (singerTabRefs.value) singerTabRefs.value.addEventListener('scroll', handleTabScroll, false)
          });
          singerHeaderIsfixed.value = false
          setTimeout(() => {
            if (singerTabRefs.value) singerTabRefs.value.scrollLeft = tabScrollLeft.value
          }, 400)
          if (loadMoreRef.value) loadMoreRef.value.root.scrollTop = 0
        })
      }
    })

    return {
      loadMoreRef,
      singerTabRefs,
      oftenSingSingerList,
      singerUnionList,
      tabList,
      curTab,
      isEmpty,
      fetchSingerList,
      handleClickSinger,
      handleChangeTab,
    }
  }
}
</script>

<style lang="stylus" scoped>
.singer-order-side
  width 800px
  height calc(100vh - 164px)
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 100%
    height calc(100% - 200px)
  ::-webkit-scrollbar
    display none
  &-main
    width 100%
    height 100%
    padding-left 0px
    padding-right 0px
    overflow hidden
    display flex
    flex-direction column
    @media screen and (max-width 1200px) and (min-height 1200px)
      padding-left 0px
      padding-right 0px
    
    .singer-tabs
      width 100%
      height 80px
      margin-bottom 0px
      display flex
      overflow-x scroll
      grid-column span 4
      position sticky
      top -2px
      background: transparent;
      padding 0 24px 0 48px
      .tab
        width fit-content
        height 80px
        line-height 80px
        min-width 160px
        padding 0px 24px
        border-radius 20px
        font-size 32px
        color #1D1D1F99
        margin 0 20px 0 0
        background #1D1D1F14
        flex-shrink 0
        text-align center
      .active
        background #A04AF0
        color #FFFFFF
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin-bottom 0
    .singer-list
      flex 1
      text-align center
      display flex
      flex-wrap wrap
      box-sizing border-box
      padding 40px 48px 0 !important
      display grid
      grid-template-columns repeat(4, 140px)
      justify-content space-between
      height 230px
      :deep(.singer-item)
        width 140px
        margin-bottom 40px
        margin-top 0px !important
        font-size 24px
        margin-right 0
        .singer-item-cover
          width 140px
          height 140px
          margin-bottom 20px
        p
          width 140px
      @media screen and (max-width 1200px) and (min-height 1200px)
        grid-template-columns repeat(4, 200px)
        padding 48px 92px 0 !important
        :deep(.singer-item)
          width 200px
          margin-bottom 60px
          font-size 22px
          .singer-item-cover
            width 200px
            height 200px
          p
            width 200px
    .diver-line
      width 100%
      height 2px
      margin 0 0 48px
      background rgba(255, 255, 255, 0.10)
      grid-column span 4
    .no-data
      font-size 28px
      color rgba(255, 255, 255, 0.5)
      text-align center
      width 100%
      height 32px
      clear both
    .hint
      text-align center
      color #555555
    .often-sing-singer
      width 100%
      height 274px
      grid-column span 4
      ::deep(.section-container-header)
        padding-bottom 30px
      ::deep(.section-container-header-title)
        color rgba(255, 255, 255, 0.40) !important
        font-size 28px !important
        font-weight 400
      &-list
        height 202px
        display flex
        width 100%
        overflow-x auto
        overflow-y hidden
        &-item
          width 110px
          height 154px
          margin-right 32px
          flex-shrink 0
          color rgba(255, 255, 255, 0.50)
          font-size 24px
          text-align center
          ::deep(img)
            width 110px
            height 110px
            border-radius 50%
            margin-bottom 16px
          ::deep(p)
            height 28px
            line-height 28px
    .empty
      display flex
      flex-direction column
      justify-content center
      align-items center
      margin-top 30px
      font-size 28px
      color rgba(255, 255, 255, 0.40)
      text-align center
      img
        width 80px
        height 80px
        margin-bottom 40px
      p
        height 32px
        line-height 32px
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin-top 14vh
</style>