<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div class="login-modal-content">
      <div @click="handleCloseLogin" class="close"></div>
      <div class="qrcode">
        <img v-if="!needReload" :src="qrCodeURL" />
        <div class="net-error" v-else>
          <img
            :class="[isRequest && 'rotate-img']"
            @click="getLoginQrcode"
            src="https://qncweb.ktvsky.com/20231208/vadd/4feef052469144b1a2af4e0ed30d8433.png"
            alt=""
          />
          <p>网络异常</p>
          <p>点击刷新二维码</p>
        </div>
      </div>
    </div>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import useRetainModal from '@/composables/useRetainModal' // 引入 useRetainModal
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import { payLogFrom } from '@/log/pay'
import { getCarplayInfo } from '@/service/carplay-info'
import store from '@/store'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { computed, onBeforeMount, ref, toRefs } from 'vue'

export default {
  name: 'LoginModalQRcode',
  components: {
    CommonModal,
  },
  // props: {
  //   from: {
  //     default: '',
  //     type: String
  //   }
  // },
  props: {
    songid: {
      type: Number,
      default: 0, // 当通过点歌打开弹框时增加二维码增加songid参数，埋点使用
    },
    log: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { songid, log } = toRefs(props)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )
    let needReload = ref(false)
    let isRequest = ref(false)
    const { showRetainModal, getRetainModalTag, setRetainModalTag } =
      useRetainModal()

    const userType = computed(() => store.state.userInfo.userType)

    const getLoginQrcode = async () => {
      if (isRequest.value) return
      isRequest.value = true
      const { data } = await getCarplayInfo()
      isRequest.value = false
      if (get(data, 'pay_qr', '')) {
        // const loginUrl = props.from === 'loginSendVipActivity' ? `${data.login_qr}&fr=1` : data.login_qr
        // const qrCodeData = await getQRCodeURL(loginUrl)
        needReload.value = false

        const logName = log.value ? log.value : '其他'
        let logEvent = null
        if (payLogFrom.has(logName)) {
          logEvent = payLogFrom.get(logName).m
        } else if (vipLogFrom.has(logName)) {
          logEvent = vipLogFrom.get(logName)
        }
        console.log('PAY LOG:', logEvent, log.value)
        const qrCodeData = await getQRCodeURL(
          `${data.pay_qr}&songid=${
            songid.value ? songid.value : ''
          }&log=${logEvent}&ut=${userType.value}`
        )
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        if (songid.value) {
          console.log('点歌登录')
          sendLog({
            event_type: '10000~50000',
            event_name: 30170,
            event_data: {
              str1: '未登录点vip歌',
              str2: '弹窗',
              str3: '二维码展示',
              str4: 'show',
            },
          })
        }
        return
      } else {
        // 未获取到的情况
        needReload.value = true
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseLogin = () => {
      root.value.hide()
      console.log('handleCloseLogin 1')
      if (!getRetainModalTag()) {
        showRetainModal()
        setRetainModalTag()
      }
      sendLog({
        event_type: '10000~50000',
        event_name: 10098,
        event_data: {
          str1: '任意页',
          str2: '登录弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
    }

    const handleCancel = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10098,
        event_data: {
          str1: '任意页',
          str2: '登录弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
    }

    onBeforeMount(getLoginQrcode)

    return {
      qrCodeURL,
      root,
      isRequest,
      needReload,
      getLoginQrcode,
      handleCancel,
      handleCloseLogin,
    }
  },
}
</script>
<style lang="stylus" scoped>
.login-modal
  &-content
    position relative
    width 1200px
    height 730px
    background #1E1F21
    border-radius 20px
    display flex
    flex-direction column
    align-items center
    background url('https://qncweb.ktvsky.com/20240415/other/707fbaa6ca3fc3ff7a87c43322e42623.png') no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px) and (min-height 1200px)
      zoom 0.8
    .close
      position absolute
      top 30px
      right 30px
      left unset!important
      width 45px
      height 45px
    .qrcode
      position absolute
      bottom 141px
      left 762px
      width 250px
      height 250px
      display flex
      justify-content center
      align-items center
      background none
      border-radius 20px
      img
        width 225px
        height 225px
    .net-error
      width 214px
      height 214px
      display flex
      flex-direction column
      justify-content center
      align-items center
      border-radius 12px
      background rgba(0, 0, 0, 0.10)
      img
        width 90px
        height 90px
        // transition-property all
        // transition-duration 0.5s
        // transition-timing-function linear
      .rotate-img
        // transform rotate(360deg)
        animation rotate .5s infinite linear
      p
        height 28px
        line-height 28px
        font-size 24px
        color rgba(0, 0, 0, 0.40)
        &:nth-child(2)
          margin 20px 0 8px
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
</style>
