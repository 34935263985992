<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div
      class="lower-vip-modal-content"
      :style="{ backgroundImage: `url(${currBg})` }"
    >
      <div @click="handleCloseModal" class="close"></div>
      <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
      <div class="pkg">
        <div class="pkg-item" @click="handleClickPackage(0)"></div>
        <div class="pkg-item" @click="handleClickPackage(1)"></div>
      </div>
    </div>
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
// import useRetainModal from '@/composables/useRetainModal'
import { sendLog } from '@/directives/v-log/log'
import { clearVipQueryPolling, getVipPkgQr, getVipQuery } from '@/service/vip'
import store from '@/store'
import get from 'lodash/get'
import { computed, onBeforeMount, ref, toRefs } from 'vue'

export default {
  name: 'lowerVipModal',
  components: {
    CommonModal,
  },
  props: {
    closeCallback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { closeCallback } = toRefs(props)
    const unionid = computed(() => store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const currSelected = ref(0)
    const pkg = [
      {
        id: 20,
        value: '1年',
        img: 'https://qncweb.ktvsky.com/20241126/vadd/b6f43ccccc50a9c433df0a516ae0a3c1.png',
      },
      {
        id: 21,
        value: '2年',
        img: 'https://qncweb.ktvsky.com/20241126/vadd/941cf129355596bc611e65267cee9d14.png',
      },
    ]

    const currBg = computed(() => pkg[currSelected.value].img)

    const handleClickPackage = (index) => {
      console.log(index)
      currSelected.value = index
      getVipQrcode()
    }

    const getZdVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: pkg[currSelected.value].id,
          fr: 1870,
        })
        const qr = get(data, 'qr', '')
        if (qr) {
          qrCodeURL.value = await getQRCodeURL(qr)
          await getVipQuery(data.order_id)
          store.dispatch('getCarplayInfo')
          root.value.hide()
          clearVipQueryPolling()
        }
      } catch (error) {
        clearVipQueryPolling()
      }
    }

    const getVipQrcode = async () => {
      sendLog({
        event_type: '30000～35000',
        event_name: 30230,
        event_data: {
          str1: '通用',
          str2: '挽留弹窗2',
          str3: '展示',
          str4: 'show',
        },
      })

      getZdVipQrcode()
    }
    const handleCloseModal = () => {
      clearVipQueryPolling()
      store.dispatch('getCarplayInfo')
      root.value.hide()

      closeCallback.value && closeCallback.value()
    }
    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')

      closeCallback.value && closeCallback.value()
    }
    onBeforeMount(getVipQrcode)
    return {
      qrCodeURL,
      root,
      currBg,
      handleCancel,
      handleCloseModal,
      handleClickPackage,
    }
  },
}
</script>

<style lang="stylus" scoped>
.lower-vip-modal
  &-content
    position relative
    width 800px
    height 636px
    padding-top 0 !important
    background url() no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px) and (min-height 1200px)
      zoom 0.8
    .pkg
      position absolute
      bottom 65px
      left 63px
      width 420px
      height 234px
      display flex
      .pkg-item
        width 210px
        height 234px
    .close
      position absolute
      top 136px
      right 30px
      width 32px
      height 32px
    .qrcode
      position absolute
      bottom 69px
      right 66px
      width 146px
      height 146px
      display flex
      justify-content center
      align-items center
      background #FFFFFF
      border-radius 10px
      img
        width 146px
        height 146px
        border-radius 5px
</style>
