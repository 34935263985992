<template>
  <div class="order-song-item" :class="{ playing: isPlaying }">
    <div class="info" @click="handleOrder">
      <p>
        <span class="name">{{ songItem.music_name }}</span>
        <img v-if="isPlaying && !videoPaused" src="https://qncweb.ktvsky.com/20241206/other/a47ec08b7bf1cbe60cea52770281dbc0.webp" />
      </p>
      <p>
        <template v-if="songItem.singer">
          <span
            class="singer"
            :class="singerCanClick && 'clickable'"
            @click="handleClickSinger"
          >{{ songItem.singer }}</span>
        </template>
        <img v-if="songItem.is_vip" class="song-block-vip" src="https://qncweb.ktvsky.com/20241206/other/5589fc8a7694d35d4808e90e206b56d0.png" alt="">
      </p>
    </div>
    <div class="control">
      <svg v-if="index > 0" @click.stop="handlePlay('order')" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
        <mask id="mask0_332_5507" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="44" height="44">
        <rect x="10" y="10" width="44" height="44" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_332_5507)">
        <circle cx="38.3066" cy="26.5" r="11" stroke="#5500C3" stroke-width="4"/>
        <path d="M27.0889 29.1146L16.7306 42.9377C15.7482 44.2487 15.8789 46.0826 17.0374 47.2411V47.2411C18.1959 48.3996 20.0298 48.5303 21.3408 47.5479L35.1639 37.1896" stroke="#5500C3" stroke-width="4"/>
        </g>
        </g>
      </svg>
      <svg v-if="index > 1" @click="handleStickSongToTop" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
        <mask id="mask0_332_5513" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="44" height="44">
        <rect x="10" y="10" width="44" height="44" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_332_5513)">
        </g>
        <g opacity="0.9">
        <path d="M43.7002 35.0293L33.4144 24.7435C32.6334 23.9625 31.367 23.9625 30.586 24.7435L20.3002 35.0293" stroke="#5500C3" stroke-width="4" stroke-linecap="round"/>
        <path d="M32 24C33.1046 24 34 24.8954 34 26L34 46C34 47.1046 33.1046 48 32 48C30.8954 48 30 47.1046 30 46L30 26C30 24.8954 30.8954 24 32 24Z" fill="#5500C3"/>
        <rect x="19" y="15" width="26" height="4" rx="2" fill="#5500C3"/>
        </g>
        </g>
      </svg>
      <svg v-if="!isPlaying" @click="handleDelete" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
        <g opacity="0.9">
        <path d="M27 16C27 14.8954 27.8954 14 29 14H35C36.1046 14 37 14.8954 37 16C37 17.1046 36.1046 18 35 18H29C27.8954 18 27 17.1046 27 16Z" fill="#5500C3"/>
        <path d="M17 22C17 20.8954 17.8954 20 19 20H45C46.1046 20 47 20.8954 47 22C47 23.1046 46.1046 24 45 24H19C17.8954 24 17 23.1046 17 22Z" fill="#5500C3"/>
        <path d="M22.4655 24.3586C22.3775 23.6983 22.3299 23.3287 22.3173 23.0609C22.3168 23.0492 22.3163 23.0381 22.316 23.0277C22.3264 23.0266 22.3374 23.0256 22.3491 23.0246C22.6162 23.0017 22.9889 23 23.655 23H40.345C41.0111 23 41.3838 23.0017 41.6509 23.0246C41.6626 23.0256 41.6736 23.0266 41.684 23.0277C41.6837 23.0381 41.6832 23.0492 41.6827 23.0609C41.6701 23.3287 41.6225 23.6983 41.5345 24.3586L38.7878 44.9586C38.7182 45.481 38.6795 45.7609 38.6371 45.9605C38.6355 45.9678 38.634 45.9747 38.6326 45.9813C38.6259 45.9818 38.6188 45.9824 38.6114 45.983C38.4079 45.9986 38.1253 46 37.5983 46H26.4017C25.8747 46 25.5921 45.9986 25.3886 45.983C25.3812 45.9824 25.3741 45.9818 25.3674 45.9813C25.366 45.9747 25.3645 45.9678 25.3629 45.9605C25.3205 45.7609 25.2818 45.4809 25.2122 44.9586L22.4655 24.3586Z" stroke="#5500C3" stroke-width="4"/>
        </g>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
import { toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { sendLog } from '@/directives/v-log/log'
import split from 'lodash/split'
import useOrder from "@/composables/useOrder";

export default {
  name: 'OrderedSongItem',
  props: {
    index: Number,
    songItem: {
      type: Object,
      default() {
        return {
          acc: '1',
          org: '2',
          flag: [],
          m3u8: {
            480: '',
            720: '',
            1080: '',
          },
          music_name: '',
          played: 0,
          singer: '',
          songid: 0,
        }
      }
    },
    isLandscape: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const { songItem, index, isLandscape } = toRefs(props)

    const store = useStore()
    const singerCanClick = computed(() => (!store.state.mvIsHide && isLandscape.value) || store.state.mvIsHide)
    const videoPaused = computed(() => store.state.videoPaused)
    const { orderSong: immediatePlay } = useOrder()
    const isPlaying = computed(() => index.value === 0)

    const handleDelete = () => {
      emit('delete', index.value)
    }

    const handleStickSongToTop = () => {
      emit('stick-top', index.value)
    }

    const handleOrder = () => {
      emit('order', songItem.value, index.value)
    }

    const handleClickSinger = (e) => {
      if (!singerCanClick.value) return
      e.stopPropagation();
      sendLog({
        event_type: '10000~50000',
        event_name: 10109,
        event_data: {
          str1: '任意点歌页',
          str2: '歌曲列表',
          str3: '点击任意歌手',
          str4: 'click',
        },
      })
      emit('singer-click', {
        singer: split(songItem.value.singer, ',')[0],
        singerhead: songItem.value.singer_head,
        singerid: songItem.value.singerid,
      })
    }

    const handlePlay = async (type) => {
      if (type === 'order') {
        sendLog({
          event_type: '10000~50000',
          event_name: 30239,
          event_data: {
            str1: '通用',
            str2: '立即演唱',
            str3: '点击',
            str4: 'click',
          },
        })
        immediatePlay(songItem.value, index.value)
      }
    }

    return {
      isPlaying,
      singerCanClick,
      handleDelete,
      handleStickSongToTop,
      handleOrder,
      handleClickSinger,
      videoPaused,
      handlePlay,
    }
  },
}
</script>
<style lang="stylus" scoped>
.order-song-item
  width 100%
  height 146px
  display flex
  justify-content space-between
  align-items center
  border-bottom 1px solid rgba(0, 0, 0, 0.1)
  padding 0px
  @media screen and (max-width 1200px) and (min-height 1200px)
    height 135px
    padding 30px 20px
  .info
    display flex
    flex-direction column
    color #1D1D1F80
    font-size 28px
    flex 1
    max-width 60%
    .name
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
      max-width 690px
      color #1D1D1FCC
      margin-bottom 0 !important
      font-size var(--font-size-large)
      @media screen and (max-width: 1200px)
        max-width 620px
    .vip
      display flex
      justify-content center
      align-items center
      width 64px
      height 36px
      font-size 22px
      border 3px solid #F0D290
      color #F0D290
      border-radius 8px
      margin-left 16px
    .divide
      width 2px
      height 30px
      margin 0 16px
      background rgba(255, 255, 255, .2)
    p
      &:first-child
        height 38px
        line-height 38px
        display flex
        align-items center
        font-size 32px
        margin-bottom 10px
        img
          width 38px
          height 38px
          margin-left 8px
      &:last-child
        height 28px
        line-height 38px
        display flex
        align-items center
    .clickable
      position relative
      padding-right 26px
      &::after
        content ""
        position absolute
        right 0
        top 50%
        margin-top -10px
        width 22px
        height 22px
        background url('https://qncweb.ktvsky.com/20231212/vadd/70dbe52816b882ae1f6871b3a509f375.png') no-repeat
        background-size 100% 100%
  .song-block-vip
     width 50px
     height 26px
     margin-left 13px
  .control
    display flex
    align-items center
    svg
      width 64px
      height 64px
      margin-left 40px
      @media screen and (max-width: 1200px)
        width 64px
        height 64px
        margin-left 40px
      &:first-child
        margin-left 0px
</style>