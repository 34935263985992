import { computed } from 'vue'
// import { useStore } from 'vuex'
import store from '@/store'
import useSongItem from './useSongItem'
import useM3u8 from './useM3u8'
import { sendLog } from '@/directives/v-log/log'
import { get } from 'lodash'
import { setOrderedList, setAlreadyList } from '@/utils/historyCache'

export default function useOrder() {
  // const store = useStore()
  const { orderSong: normalOrderSong } = useSongItem()
  const { addCurrSongM3u8 } = useM3u8()

  const orderedList = computed(() => store.state.orderedList)
  const orderedListNumber = computed(() => orderedList.value.length)
  const orderedSongIdMap = computed(() => store.state.orderedSongIdMap)
  const currPlayingSong = computed(() => store.state.videoPlayerHistory.songItem)

  const isSingStatus = computed(() => store.state.isSingStatus)

  // 检查已点列表是否为 “空” 逻辑：length为0 或 仅包括自己
  const playNext = (playingSong, notHasCallback, type = 1) => {
    const playingSongIndex = orderedList.value.findIndex(item => playingSong.songid === item.songid)
    const nextSong = orderedList.value[playingSongIndex + 1]
    if (nextSong) {
      sendLog({
        event_type: 'click',
        event_name: 105,
        event_data: {
          type,
          song_id: nextSong.songid,
          song_name: nextSong.music_name,
          singer: nextSong.singer
        }
      })
      // 播下一首
      store.commit('SAVE_VIDEO_PLAYER_HISTORY_SONG_ITEM', nextSong)
      store.commit('SHIFT_SONG_ORDERED_LIST')
      //需更新下一首歌曲orderedSongIdMap 后续可考虑数据转换Map
      const isAddSongIdMap = (nextSong.songid === orderedList.value[0].songid) || !Object.prototype.hasOwnProperty.call(orderedSongIdMap.value, nextSong.songid)
      if (isAddSongIdMap) {
        store.commit('SAVE_ORDERED_SONGIDMAP', nextSong.songid)
      }
      /**
       * 现在歌曲与歌曲资源数据是分开的，歌曲资源通过全局检测当前播放歌曲id的变化去更新
       * 有一个特殊场景：当前播放歌曲与下一首歌曲相同时需在这里做额外获取歌曲资源处理
       */
      if (nextSong.songid === playingSong.songid) {
        addCurrSongM3u8(playingSong)
      }
    } else {
      // TODO 这个位置不能调
      store.commit('SHIFT_SONG_ORDERED_LIST')
      if (typeof notHasCallback === 'function') {
        notHasCallback.call()
      }
    }
    setOrderedList()
    setAlreadyList()
  }

  // 已点列表中点歌
  const orderSong = (songItem, index, option = {}) => {
    // 已点列表切歌时更新【已点列表切歌】状态标识，供歌曲播放上报使用 (点正播放歌曲不进行上报)
    if(index !== 0) store.dispatch('songLog/updateCutSongTag')

    let posData = {}
    //点击当前播放歌曲继续播放，点击其他从零播放
    if(index === 0) posData = { position: 'recovery' }
    if (!isSingStatus.value) posData.useFirstSongAutoPlay = true
    // 播放
    normalOrderSong(songItem, {
      ...option,
      isPushOrdered: false,
      enabledMvShow: true,
      isNotSingImmediately: false,
      beforeImmediate: () => {
        if (index > 0) {
          if (index > 1) {
            // 先置顶(放到第二位)
            stickSongToTop(index)
          }
          const shiftSong = get(orderedList, 'value.0', null)
          if (shiftSong) {
            store.commit('PUSH_SONG_TO_ALREADY_LIST', shiftSong)
          }
          // 去掉第一首
          store.commit('SHIFT_SONG_ORDERED_LIST')
          setOrderedList()
          setAlreadyList()
        }
      },
      afterOrder: () => {
        // 这里加一步容错处理，预防无资源的情况
        const hasHlsResource = !currPlayingSong.value.hls && currPlayingSong.value.songid
        if (hasHlsResource) addCurrSongM3u8(currPlayingSong.value)

        // 首次进入mv页 更新演唱状态
        if (!isSingStatus.value) {
          store.commit('UPDATE_IS_SING_STATUS', true)
          store.dispatch('searchTips/updateIsShowSingTips', false)
        }
      },
      ...posData,
    })
  }

  // 置顶
  const stickSongToTop = (index) => {
    const _index = typeof index === 'undefined'
      ? orderedList.value.length - 1
      : index
    store.commit('STICK_SONG_TO_TOP_ORDERED_LIST', _index)
    setOrderedList()
  }

  // 删除
  const deleteSong = (index) => {
    store.commit('DELETE_SONG_ORDER_LIST', index)
    setOrderedList()
  }

  const addSong = (songItem, option) => {
    store.commit('PUSH_SONG_TO_ORDERED_LIST', { song: songItem, from: get(option, 'from', {}) })
  }

  return {
    playNext,
    addSong,
    orderSong,
    stickSongToTop,
    deleteSong,
    orderedList,
    orderedListNumber,
    normalOrderSong,
  }
}