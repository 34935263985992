<template>
  <div class="mv-core">
    <MvMini v-if="isShowMini && isShowMiniByRouteName" />
    <MvPageComponent v-show="!isShowMini" ref="mvPageComponentRef" />
  </div>
</template>

<script>
import MvPageComponent from '@/components/mv/index.vue'
import MvMini from '@/components/mv/mini.vue'
import eventBus from '@/utils/event-bus'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'MvCore',
  components: {
    MvMini,
    MvPageComponent,
  },
  props: {
    isShowMiniByRouteName: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore()
    const isShowMini = ref(true)
    const orderedList = computed(() => store.state.orderedList)
    const videoPaused = computed(() => store.state.videoPaused)
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const mvIsHide = computed(() => store.state.mvIsHide)
    const freeVipNumber = computed(() => store.state.freeVipNumber)
    const mvPageComponentRef = ref(null)

    watch(
      [mvIsHide, orderedList, isVip],
      () => {
        if (!videoPaused.value && orderedList.value.length) {
          isShowMini.value = false
          return
        }

        if (document.querySelector('.vip-modal-content')) {
          return
        }

        const shouldShowMini =
          mvIsHide.value &&
          (orderedList.value.length === 0 ||
            (orderedList.value[0].is_vip &&
              !isVip.value &&
              !freeVipNumber.value))

        isShowMini.value = shouldShowMini

        if (isShowMini.value) {
          eventBus.emit('handle-video-pause')
        }
      },
      {
        deep: true,
      }
    )

    const onCloseVipQrcode = () => {
      isShowMini.value = true
    }

    onMounted(() => {
      eventBus.on('handle-close-vip-qrcode', onCloseVipQrcode)
    })

    onUnmounted(() => {
      eventBus.off('handle-close-vip-qrcode', onCloseVipQrcode)
    })

    return {
      isShowMini,
      mvPageComponentRef,
    }
  },
}
</script>

<style lang="stylus" scoped>
.mv-core
  position: fixed;
  top: 162px;
  right: 80px;
  z-index: 2;
  @media screen and (max-width 1200px) and (min-height 1200px)
    top 162px
    right: 54px;
</style>
