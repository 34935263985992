<template>
  <transition ref="root" name="slide-transition">
    <div class="mv-side" :style="posStyle">
      <slot name="default"></slot>
    </div>
  </transition>
</template>

<script>
import { computed, toRefs, watch, onMounted, nextTick, ref } from 'vue'
import { useShareBrowserSize } from '@/composables/sharedComposable'

export default {
  name: 'MvSideBar',
  props: {
    pos: {
      type: Number,
      default: 0, // 0 不显示 1 右侧显示 2 左侧显示
    }
  },
  setup(props) {
    const { browserType } = useShareBrowserSize()
    const { pos } = toRefs(props)
    let posMaps = {
      0: { 'width': 0 },
      1: { 'width': '800px', 'right': 0 },
      2: { 'width': '800px', 'left': 0 },
      3: { 'height': '56vh' }
    }
    const posStyle = ref({ 'width': 0 })

    onMounted(() => {
      nextTick(() => {
        if (browserType.value !== 'landscape') {
          posMaps = {
            ...posMaps,
            0: { 'width': '100vw', 'height': 0 }
          }
          posStyle.value = posMaps[pos.value]
        }
      })
    })

    watch(browserType, val => {
      posMaps = {
        ...posMaps,
        0: val !== 'landscape' ? { 'width': '100vw', 'height': 0 } : { 'width': 0, 'height': '100vh' }
      }
      posStyle.value = posMaps[pos.value]
    })

    // 监听侧边栏的展示位置并标记 使其能在关闭时动画效果位置正常
    watch(pos, val => {
      if (val !== 0) {
        posMaps = {
          ...posMaps,
          0: browserType.value === 'landscape' ? {
            'width': 0,
            [val === 1 ? 'right' : 'left']: 0
          } : {
            'height': 0,
          },
        }
      }
      posStyle.value = posMaps[pos.value]
    })

    return {
      posStyle,
    }
  }
}
</script>

<style lang="stylus" scoped>
.mv-side
  // width 800px
  height 100vh
  background #E8EAEE
  display flex
  flex-direction column
  align-items center
  overflow hidden
  position absolute
  top 0
  z-index 12
  backdrop-filter: blur(20px)
  transition-property all
  transition-duration .5s
  transition-timing-function linear
  @media screen and (min-width 1900px)
    ::v-deep .song-item
      padding 0px
      margin 0 48px

      .right
        margin-right 0px
        svg
          width 64px
          height 64px
          &:first-child
            margin-right 64px
  ::v-deep .side-title
    display flex
    justify-content space-between
    align-items center
    margin 22px 0 60px
    @media screen and (max-width 1200px) and (min-height 1200px)
      border-bottom: 2px solid #0000001A
      padding 0 28px 0 60px
    .left
      display flex
      align-items center
      height 120px
      color: #1D1D1FCC
      font-size: 32px;
      @media screen and (max-width 1200px) and (min-height 1200px)
        height 130px
    .close
      width 32px
      height 32px
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 36px
        height 36px
  @media screen and (max-width 1200px) and (min-height 1200px)
    // height 56vh
    width 100vw
    top auto
    bottom 0
    left 0
</style>
