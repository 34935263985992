<template>
  <div
    class="song-list"
    v-if="alreadyList.length"
  >
    <SongItem
      v-for="(songItem, index) in alreadyList"
      :key="index"
      :index="index"
      :songItem="songItem"
      :isLandscape="isLandscape"
      @order="handleOrder"
      @stickTop="handleStickTop"
      @delete="handleDelete"
      @singer-click="handleSingerClick"
    ></SongItem>
  </div>
  <div class="empty" v-else>
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4" clip-path="url(#clip0_346_155436)">
      <circle cx="62.1964" cy="33.4815" r="18.5557" stroke="#1D1D1F" stroke-width="4"/>
      <path d="M45.0664 38.9043L21.6423 68.7885L28.1023 75.2485L57.9865 51.8244" stroke="#1D1D1F" stroke-width="4"/>
      <path d="M18.8096 77.4078L20.1455 78.8963L20.1455 78.8963L18.8096 77.4078ZM9.2533 68.1959L10.7898 69.4763L9.2533 68.1959ZM15.6039 60.5749L17.1404 61.8552L17.1404 61.8552L15.6039 60.5749ZM12.9925 54.9995L12.9925 52.9995L12.9925 52.9995L12.9925 54.9995ZM12.75 52.9995C11.6454 52.9995 10.75 53.8949 10.75 54.9995C10.75 56.1041 11.6454 56.9995 12.75 56.9995L12.75 52.9995ZM9.79607 77.293L11.1694 75.8391L11.1694 75.8391L9.79607 77.293ZM15.2047 55.8179L13.9031 57.3364L15.2047 55.8179ZM24.6137 69.5115L17.4738 75.9194L20.1455 78.8963L27.2854 72.4884L24.6137 69.5115ZM10.7898 69.4763L17.1404 61.8552L14.0675 59.2945L7.71683 66.9156L10.7898 69.4763ZM12.9925 52.9995L12.75 52.9995L12.75 56.9995L12.9925 56.9995L12.9925 52.9995ZM11.1694 75.8391C9.38241 74.1512 9.21616 71.3647 10.7898 69.4763L7.71683 66.9156C4.79078 70.427 5.09992 75.6084 8.42274 78.747L11.1694 75.8391ZM17.4738 75.9194C15.6715 77.5369 12.9299 77.502 11.1694 75.8391L8.42274 78.747C11.6964 81.8391 16.7942 81.904 20.1455 78.8963L17.4738 75.9194ZM16.5063 54.2993C15.5277 53.4606 14.2814 52.9995 12.9925 52.9995L12.9925 56.9995C13.3265 56.9995 13.6495 57.119 13.9031 57.3364L16.5063 54.2993ZM17.1404 61.8552C19.0306 59.5869 18.7481 56.2209 16.5063 54.2993L13.9031 57.3364C14.4841 57.8344 14.5573 58.7067 14.0675 59.2945L17.1404 61.8552Z" fill="#1D1D1F"/>
      </g>
      <defs>
      <clipPath id="clip0_346_155436">
      <rect width="90" height="90" fill="white"/>
      </clipPath>
      </defs>
    </svg>
    <p>暂无歌曲，请点歌~</p>
  </div>
</template>
<script setup>
import { onMounted, ref, defineEmits, computed } from 'vue'
import SongItem from './item.vue'
import useAlready from '@/composables/useAlready'
import { sendLog } from '@/directives/v-log/log'
import { checkLandscapeOrPortrait } from '@/utils/device'
import { get } from 'lodash'
import { getAiMvUserIsexpire } from '@/service/user'
import Toast from '@/utils/toast'

const emit = defineEmits(['singer-click'])
let isLandscape = ref(false)
const { alreadyList, orderSong, stickSongToTop, deleteSong } = useAlready()
// const instance = getCurrentInstance()

const handleOrder = async (songItem, index, {
  isNotSingImmediately
}) => {
  if (songItem.isAIMV) {
    if (!songItem.unionid) {
      Toast({
        message: '您的换脸VIP已过期，暂无法在车机播放',
        position: 'center',
        className: 'toast-zoom-max'
      })
      return
    }
    const res = await getAiMvUserIsexpire({
      unionid: songItem.unionid
    })
    if (!get(res, 'data.hasVip', false)) {
      Toast({
        message: '您的换脸VIP已过期，暂无法在车机播放',
        position: 'center',
        className: 'toast-zoom-max'
      })
      return
    }
  }
  orderSong(songItem, ({ immediate }) => {
    if (immediate) {
      // instance.parent.exposed.close() 暂时这样弄
    }
  }, {
    isNotSingImmediately,
  })
  sendLog({
    event_type: '10000~50000',
    event_name: 10062,
    event_data: {
      str1: '已点',
      str2: '已唱',
      str3: '点歌',
      str4: 'click',
    },
  })
}

const handleStickTop = async (songItem, index) => {
  if (songItem.isAIMV) {
    if (!songItem.unionid) {
      Toast({
        message: '您的换脸VIP已过期，暂无法在车机播放',
        position: 'center',
        className: 'toast-zoom-max'
      })
      return
    }
    const res = await getAiMvUserIsexpire({
      unionid: songItem.unionid
    })
    if (!get(res, 'data.hasVip', false)) {
      Toast({
        message: '您的换脸VIP已过期，暂无法在车机播放',
        position: 'center',
        className: 'toast-zoom-max'
      })
      return
    }
  }
  stickSongToTop(songItem, index)
  sendLog({
    event_type: '10000~50000',
    event_name: 10063,
    event_data: {
      str1: '已点',
      str2: '已唱',
      str3: '置顶点歌',
      str4: 'click',
    },
  })
}

const handleDelete = (index) => {
  deleteSong(index)
  sendLog({
    event_type: '10000~50000',
    event_name: 10064,
    event_data: {
      str1: '已点',
      str2: '已唱',
      str3: '删除',
      str4: 'click',
    },
  })
}

const handleSingerClick = (v) => {
  emit('singer-click', v)
}

onMounted (() => {
  isLandscape.value = checkLandscapeOrPortrait() === 'landscape'
})
</script>
<style lang="stylus" scoped>
.song-list
  height 660px
  padding 0 48px
  overflow-x hidden
  overflow-y scroll
  &::-webkit-scrollbar
    display none
  &::-webkit-scrollbar-thumb
    display none
  &::-webkit-scrollbar-track
    display none
  &.has-banner
    // height 580px
    height 522px
.empty
  display flex
  flex-direction column
  justify-content center
  align-items center
  font-size 28px
  color #1D1D1F80
  text-align center
  padding-top 184px
  @media screen and (max-width: 1200px)
    padding-top 238px
  svg
    width 90px
    height 90px
    margin-bottom 40px
  p
    height 32px
    line-height 32px
</style>