<template>
  <CommonModal ref="root">
    <div class="activity-modal-mic">
      <div @click="handleCloseModal" class="activity-modal-mic-close">
        <img
          src="https://qncweb.ktvsky.com/20241212/vadd/242f3490a33a44eb3155d1ebcbb67e37.png"
          alt=""
        />
      </div>
      <p class="title">购买雷石官方麦克风</p>
      <div class="activity-modal-mic-qr-code">
        <img :src="qrCodeURL" alt="" />
      </div>
      <p class="desc">扫码购买官方正品麦克风</p>
    </div>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import IMAGE_URLS from '@/constants/images/index'
// import { getCarplayInfo } from '@/service/carplay-info'
// import get from 'lodash/get'
import { onBeforeMount, ref } from 'vue'

export default {
  name: 'ActivityMicModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const root = ref(null)
    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref(IMAGE_URLS.VIP_MODAL.DEFAULT_QR_CODE)

    const getVipQrcode = async () => {
      // const { data } = await getCarplayInfo()
      // if (get(data, 'pay_qr', '')) {
      //   const qrCodeData = await getQRCodeURL(`${data.pay_qr}`)
      //   if (qrCodeData) {
      //     qrCodeURL.value = qrCodeData
      //   }
      //   return
      // }

      // 暂时前台配置
      const codeUrl =
        'https://shop.tesla.cn/product/teslamic?utm_source=vehicle?source=car'
      const qrCodeData = await getQRCodeURL(`${codeUrl}`)
      if (qrCodeData) {
        qrCodeURL.value = qrCodeData
      }
      // Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      props.closeEvent.call()

      root.value.hide()
    }

    onBeforeMount(getVipQrcode)

    return {
      root,
      qrCodeURL,
      handleCloseModal,
    }
  },
}
</script>
<style lang="stylus" scoped>
.activity-modal-mic
  padding 0
  position relative
  width 820px
  height 568px
  border-radius 32px
  color rgba(255, 255, 255, .8)
  background rgba(232, 234, 238, 1)
  display flex
  flex-direction column
  align-items center
  &-close
    width 40px
    height 40px
    position absolute
    top 30px
    right 30px
    img
      width 100%
      height 100%
  .title
    color rgba(29, 29, 31, 0.8)
    font-size 40px
    font-weight 400
    margin-top 60px
    margin-bottom 50px
    height 60px
    line-height 60px
  .activity-modal-mic-qr-code
    width 250px
    height 250px
    margin-bottom 50px
    background #fff
    display flex
    justify-content center
    align-items center
    img
      width 236px
      height 236px
  .desc
    color rgba(29, 29, 31, 0.5)
    font-size 32px
    font-weight 400
</style>
