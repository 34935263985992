<template>
  <ControlPopup ref="root">
    <template #control-popup-plugin>
      <div class="climax-modal">
        <img
          class="climax-modal-close"
          @click="handleClose(1)"
          src="https://qncweb.ktvsky.com/20240603/vadd/a1fd5054da8624a2b337cb47f2e0d781.png"
        />
        <img
          class="climax-modal-price"
          src="https://qncweb.ktvsky.com/20240603/vadd/8e08b5a1870536c8d224472ed55f86a3.png"
        />
        <img
          class="climax-modal-title"
          src="https://qncweb.ktvsky.com/20240603/vadd/0d425ff8620ba687b2e47b7d568402e5.png"
        />
        <img
          class="climax-modal-power"
          src="https://qncweb.ktvsky.com/20240603/vadd/c6f57f6517fb4d099ba425254a1c5334.png"
        />
        <span class="climax-modal-preferential">
          <span class="climax-modal-preferential-tit">限时特惠：</span>
          <van-count-down
            :time="countdownTime"
            class="climax-modal-preferential-countdown"
          />
        </span>
        <div class="vip-packages">
          <!-- <PerfectScrollbar> -->
          <div
            class="vip-packages-item"
            v-for="item in packages"
            :key="item.id"
            :class="item.id === selectItem.id && 'active'"
            @click="handleClickItem(item)"
          >
            <div
              v-if="item.tips"
              class="tips"
              :class="item.isOrange && 'orange'"
            >
              {{ item.tips }}
            </div>
            <div class="days">{{ item.title }}</div>
            <div class="day-price">
              ¥<span>{{ formatValue(item.day_fee) }}</span
              >元/天
            </div>
            <div class="price">
              <span>¥{{ formatValue(item.fee) }}</span>
              <span v-if="item.old_fee !== item.fee" class="origin"
                >¥{{ formatValue(item.old_fee) }}</span
              >
            </div>
          </div>
          <!-- </PerfectScrollbar> -->
        </div>
        <div class="pay-info">
          <div class="left">
            <div class="price" v-html="formatPayTitle"></div>
            <h3>微信扫码支付</h3>
            <p>有效期至-{{ expirationDate }}</p>
          </div>
          <div class="code">
            <img :src="qrCodeURL" alt="" />
          </div>
        </div>
      </div>
    </template>
  </ControlPopup>
</template>
<script>
import {
  ref,
  onMounted,
  onBeforeMount,
  computed,
  toRefs,
  watch,
  nextTick,
} from "vue";
import { Store, useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import eventBus from "@/utils/event-bus";
import eventEmitter from "@/utils/event-bus";
import ControlPopup from "@/components/control-popup/index.vue";
import { CountDown } from "vant";
import {
  getVipPkg,
  getVipPkgQr,
  getVipQuery,
  clearVipQueryPolling,
} from "@/service/vip";
import get from "lodash/get";
import useQRCode from "@/composables/useQRCode";
import { addDays, format } from "date-fns";
// import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
export default {
  name: "ClimaxModal",
  components: {
    ControlPopup,
    [CountDown.name]: CountDown,
    // PerfectScrollbar
  },
  props: {
    from: {
      type: String,
      default: "mine",
    },
    fr: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { from, fr } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const root = ref(null);
    const timeData = ref({});
    const countdownTime = ref(5 * 60 * 1000);
    const unionid = computed(() => store.state.userInfo.unionid);
    const packages = ref([]);
    const selectItem = ref({});
    const { getQRCodeURL } = useQRCode();
    const isVip = computed(() => !!vipInfo.value.end_time);
    const vipInfo = computed(() => store.state.vipInfo);

    let qrCodeURL = ref(
      "https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png"
    );

    const handleShow = () => {
      setTimeout(() => {
        root.value?.show();
        eventBus.emit("handle-video-pause");
      }, 2 * 1000);
    };
    const formatValue = (value) => {
      if (value === undefined || isNaN(value)) {
        return "N/A";
      }
      return value / 100;
    };
    const expirationDate = computed(() => {
      if (!selectItem.value.days) return "";
      const currentDate = isVip.value
        ? new Date(vipInfo.value.end_time)
        : new Date();
      const expirationDate = addDays(currentDate, selectItem.value.days);

      return format(expirationDate, "yyyy.MM.dd");
    });
    const handleClose = (val) => {
      if (val == 1) {
        root.value.close();
      }
      router.push({
        name: "home",
      });
    };
    const fetchConfig = async () => {
      if (!unionid.value) return;

      const res = await getVipPkg(unionid.value);
      packages.value = res.data;
      console.log(packages.value, res.data, "packages.value115");
      if (packages.value.length === 3) {
        const index = packages.value.findIndex((item) => !!item.tips);
        packages.value[index].isOrange = true;
      } else {
        const reversedIndex = packages.value
          .slice()
          .reverse()
          .findIndex((item) => !!item.tips);
        const index = packages.value.length - 1 - reversedIndex;
        packages.value[index].isOrange = true;
      }

      const active =
        packages.value.find((item) => item.id === res.recommend_pkg) ||
        packages.value[0];
      selectItem.value = active;
      getVipQrcode();
    };
    const formatPayTitle = computed(() => {
      const regex = /(\d+)(年|天)/g;
      if (!selectItem.value.title) return "";

      const matches = selectItem.value.title.match(regex);
      if (!matches) return "";

      const formatted = matches
        .map((match) => {
          const [_, number, unit] = match.match(/(\d+)(年|天)/);
          return `<span class="user-vip-year-num">${number}</span>${unit}`;
        })
        .join(" ");

      return formatted;
    });
    watch(unionid, (val) => {
      if (val) {
        fetchConfig();
      }
    });

    const isFinish = computed(() => store.state.climax.freeSing);
    watch(isFinish, (val) => {
      if (val && !isVip.value) {
        setTimeout(() => {
          root.value?.show();
          eventBus.emit("handle-video-pause");
        }, 2 * 1000);
      }
    });

    const getVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: selectItem.value.id,
          fr: fr.value || (from.value !== "mv" ? 1759 : 1834),
        });

        const qr = get(data, "qr", "");
        if (qr) {
          const qrCodeData = await getQRCodeURL(qr);
          if (qrCodeData) {
            qrCodeURL.value = qrCodeData;
            // store.dispatch('startVipInfoStatus');
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      eventBus.on("handle-time-finish", handleShow);
      eventEmitter.on("handle-close-popup", handleClose);
    });
    onBeforeMount(() => {
      eventBus.off("handle-time-finish", handleShow);
      eventEmitter.off("handle-close-popup", handleClose);
      fetchConfig();
    });

    const handleClickItem = (item) => {
      qrCodeURL.value =
        "https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png";
      selectItem.value = item;
      getVipQrcode();
    };
    return {
      root,
      handleClose,
      countdownTime,
      timeData,
      packages,
      selectItem,
      formatValue,
      handleClickItem,
      formatPayTitle,
      qrCodeURL,
      expirationDate,
    };
  },
};
</script>
<style lang="stylus" scoped>
.climax-modal
  width: 1260px;
  height: 750px;
  background: url('https://qncweb.ktvsky.com/20240603/vadd/048b0343e4385fa1016aeaed5ec889fa.png') no-repeat; // qncweb.ktvsky.com/20240603/vadd/048b0343e4385fa1016aeaed5ec889fa.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  position: relative;
  @media screen and (max-width: 1200px)
    zoom 0.8
  &-close
    position: absolute;
    top: 50px;
    right: 30px;
  &-price
    position: absolute;
    top: 15px;
    right: 120px;
  &-title
    position: absolute;
    top: 60px;
    left: 135px;
  &-power
    position: absolute;
    top: 130px;
    left: 380px;
  &-preferential
    width: 500px;
    height: 52px;
    font-size: 20px;
    color: #ffffff;
    display: inline-block;
    position: absolute;
    top: 180px;
    left: 380px;
    background: url('https://qncweb.ktvsky.com/20240603/vadd/6b3b1964a5147f4dc82e3c81c362a8a0.png') no-repeat; // qncweb.ktvsky.com/20240603/vadd/6b3b1964a5147f4dc82e3c81c362a8a0.png) no-repeat;
    background-size: 100% 100%;
    position relative
    &-tit
      position absolute
      top 11px
      left 160px
    &-countdown
      color: #ffffff;
      position absolute
      top 17px
      left 275px
      font-size 22px
  .vip-packages
    min-width 640px
    margin 210px 0 0 195px
    &-item
      width: 200px;
      height: 220px;
      border-radius: 10px;
      background linear-gradient(217deg, rgba(255, 237, 219, 0.12) 0%, rgba(255, 255, 255, 0.02) 100%), rgba(255, 255, 255, 0.04);
      backdrop-filter: blur(50px);
      position relative
      text-align center
      margin-right 20px
      flex-shrink 0;
      float left
      &.active
        background: linear-gradient(322deg, #FFE093 5.61%, #FFEBB5 34.88%, #FFECB9 96.2%);
        .days
          background: linear-gradient(270deg, #625F66 0%, #222124 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        .day-price
          color: red;
        .price
          color rgba(172, 98, 0, 1)
          .origin
            color rgba(172, 98, 0, 0.5)
      .tips
        padding 0 10px
        height 40px
        line-height 40px
        font-size 22px
        color #fff
        position absolute
        left 0
        top -20px
        background linear-gradient(90deg, #FF3D6B 0%, #8C1AFF 100%)
        border-radius 10px 10px 10px 0
        &.orange
          background linear-gradient(90deg, #ff3d3d 0%, #ff881a 100%)
      .days
        color: #FFD7AE;
        font-size: 28px;
        font-weight: 300;
        margin-top 30px
      .day-price
        color: #FFD7AE
        font-size: 24px;
        margin 2px 0 2px
        span
          font-size: 52px;
      .price
        font-size: 24px;
        color rgba(255, 255, 255, 1)
        display flex
        justify-content center
        font-weight: 300;
        .origin
          color: rgba(255, 255, 255, 0.5)
          font-size: 24px;
          text-decoration line-through
          margin-left 8px
  .pay-info
    width 372px
    .left
      width 220px
      text-align center
      position absolute
      bottom 40px
      right 660px
      ::v-deep .price
        color #FFD67D
        font-size 28px
        margin-bottom 20px
        min-height 80px
        span
          font-size 80px
          line-height 85px
      h3
        color rgba(255, 255, 255, 0.8)
        font-size 28px
        // margin-bottom 6px
      p
        color rgba(255, 255, 255, 0.6)
        font-size 22px
    .code
      width 180px
      height 180px
      background #fff
      overflow hidden
      position absolute
      bottom 40px
      right 400px
      img
        width 170px
        height 170px
        margin 5px
</style>
