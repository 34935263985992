<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div class="vip-modal-content" :class="fromType">
      <div @click="handleCloseModal" class="close"></div>
      <div v-if="!isLogin" class="qrcode">
        <!-- 登录时显示二维码 -->
        <img :src="qrCodeURL" />
      </div>
      <div v-else class="button-area">
        <!-- 未登录时显示按钮 -->
        <img :src="enjoyImg" class="enjoy-img" @click="handleEnjoyClick" />
        <img :src="refuseImg" class="refuse-img" @click="handleRefuseClick" />
      </div>
    </div>
  </CommonModal>
</template>

<script>
import { ref, computed, toRefs, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import CommonModal from '@/components/modal/common/component.vue'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import useRetainModal from "@/composables/useRetainModal";
import { getCarplayInfo } from "@/service/carplay-info";
import get from "lodash/get";
import { vipLogFrom } from "@/constants";
import Toast from "@/utils/toast";
import store from "@/store";
import useQRCode from "@/composables/useQRCode";
import eventBus from '@/utils/event-bus'

export default {
  name: 'RetainModal',
  components: {
    CommonModal,
  },
  props: {
    songid: {
      type: Number,
      default: 0 // 当通过点歌打开弹框时增加二维码增加songid参数，埋点使用
    },
    log: {
      type: String,
      default: ''
    },
    fromType: {
      type:  String,
      default: ''
    },
    logInfo: {
      type: Object,
      default: () => {}
    },
    closeCallback: {
      type: Function,
      default: null
    }
  },

  setup(props) {
    const { songid, log, closeCallback } = toRefs(props)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')
    const enjoyImg = ref('https://qncweb.ktvsky.com/20240704/vadd/c19468c07ade356668d7c1e0a0d190cd.png')
    const refuseImg = ref('https://qncweb.ktvsky.com/20240704/vadd/84b6c83f971eafc63989c345170f7d64.png')

    const userType = computed(() => store.state.userInfo.userType)

    const getVipQrcode = async () => {
      console.log('getVipQrcode:', songid.value)
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(`${data.pay_qr}&songid=${songid.value ? songid.value : ''}&log=${vipLogFrom.get('挽留弹窗1')}&ut=${userType.value}`)
         console.log('getVipQrcode qrCodeData:', qrCodeData.value)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        if (!isLogin.value) {
          sendLog({
            event_type: '10000~50000',
            event_name: 30229,
            event_data: {
              str1: '通用',
              str2: '挽留弹窗 1',
              str3: '展示',
              str4: 'show',
            },
          })
        } else {
          sendLog({
            event_type: '10000~50000',
            event_name: 30230,
            event_data: {
              str1: '通用',
              str2: '挽留弹窗 2',
              str3: '展示',
              str4: 'show',
            },
          })
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      // 手动关闭时统一做一次用户信息更新 处理用户在个人中心模块-前往开通时，关闭弹窗刷新用户会员信息
      store.dispatch('getCarplayInfo')
      root.value.hide()
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
      closeCallback.value && closeCallback.value()
    }

    const handleCancel = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
    }

    const handleEnjoyClick = () => {
      // 处理按钮1点击事件
      // 例如：跳转到享受 VIP 页面
      console.log('handleEnjoyClick')
      sendLog({
        event_type: '10000~50000',
        event_name: 30231,
        event_data: {
          str1: '通用',
          str2: '挽留弹窗 2',
          str3: '享受权益',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        }
      })
      root.value.hide()
      if (isLogin.value) {
        eventBus.emit('show-vip-qrcode', {
          log: '挽留弹窗2',
          isLogin: true,
          fr: vipLogFrom.get('挽留弹窗2'),
          isShowRetain: false
        })
      }
    }

    const handleRefuseClick = () => {
      // 处理按钮2点击事件
      // 例如：关闭模态框
      console.log('handleRefuseClick')
      handleCloseModal()
      sendLog({
        event_type: '10000~50000',
        event_name: 30231,
        event_data: {
          str1: '通用',
          str2: '挽留弹窗2',
          str3: '残忍离开',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        }
      })
    }

    const handleIsLoginChange = (val) => {
      console.log('handleIsLoginChange', val)
      root.value.hide()
    }

    watch(isLogin, handleIsLoginChange)

    onBeforeMount(getVipQrcode)

    return {
      qrCodeURL,
      root,
      isLogin,
      handleCancel,
      handleCloseModal,
      handleEnjoyClick,
      handleRefuseClick,
      enjoyImg,
      refuseImg,
    }
  }
}
</script>

<style lang="stylus" scoped>
.vip-modal
  &-content
    position relative
    width 1144px
    height 656px
    padding-top 0 !important
    background url(https://qncweb.ktvsky.com/20240704/vadd/2ba70fc1928da084e788a4fc2d0eef14.png) no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px) and (min-height 1200px)
      zoom 0.8
    .close
      position absolute
      top 120px
      right 20px
      left unset!important
      width 40px
      height 40px
    .vip-top-img
      width 242px
      height 138px
      position absolute
      top -70px
      left 379px
    .title
      margin 120px 0 47px
      width 1000px
      height 309px
      display flex
      justify-content center
      img
        width 700px
        height 309px
    .qrcode
      position absolute
      bottom 60px !important
      left 50%
      margin-left 256px
      width 200px
      height 200px
      display flex
      justify-content center
      align-items center
      background #FFFFFF
      border-radius 10px
      @media screen and (max-width 1200px) and (min-height 1200px)
        bottom 84px !important
      img
        width 181px
        height 181px
        border-radius 5px
      &-txt
        width 226px
        height 49px
        position absolute
        bottom -42px
        left -18px
        background linear-gradient(90deg, #FF3D3D 0%, #FF881A 100%)
        font-size 24px
        color #fff
        text-align center
        line-height 49px
        border-radius 30px
        padding-left 10px
    .tip
      font-size 32px
      text-align center
      display flex
      flex-direction column
      align-items center
      span
        display flex
        align-items center
      img
        width 45px
        height 45px
        margin-right 10px
    .enjoy-img
      width: 254px;
      height: 69px;
      margin: 10px 0;
      cursor: pointer;
    .refuse-img
      width: 254px;
      height: 69px;
      margin: 10px 0;
      cursor: pointer;
    .button-area
      position: absolute
      bottom: 110px !important
      left: 50%
      margin-left: 236px
      display: flex
      flex-direction: column
      align-items: center
      .enjoy-img .refuse-img
        margin: 10px 0;
        cursor: pointer;
</style>