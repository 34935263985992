<template>
  <CommonTeleportModal @cancel="close">
    <div class="mv-quality-modal" v-show="isShow">
      <div class="mv-quality-modal-content">
        <img
          class="mv-quality-modal-content-close"
          @click="close"
          src="https://qncweb.ktvsky.com/20230804/vadd/9f611b95ed1ac8122957f683c69e34bf.png"
        />
        <div class="mv-quality-modal-content-title">画质选择</div>
        <div class="mv-quality-modal-content-main">
          <div
            class="mv-quality-modal-content-main-item"
            :class="[
              playingMvQuality === quality.value &&
                'mv-quality-modal-content-main-item-active',
              !playingSongItemM3u8[quality.value] &&
                'mv-quality-modal-content-main-item-unactive',
            ]"
            v-for="(quality, index) in qualityList"
            :key="index"
            @click="handleChoose(quality)"
          >
            <p class="title">{{ quality.name }}</p>
            <p class="des">{{ quality.label }}</p>
            <img
              v-if="quality.value === '1080'"
              src="https://qncweb.ktvsky.com/20230830/vadd/c6f52232a92ac7d2273b244a5d6daf05.png"
            />
          </div>
        </div>
      </div>
    </div>
  </CommonTeleportModal>
</template>

<script>
import useQuality from '@/composables/useQuality'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import get from 'lodash/get'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import CommonTeleportModal from './../common/index.vue'

export default {
  name: 'MvQualityModal',
  components: {
    CommonTeleportModal,
  },
  props: {},
  setup(props, { emit }) {
    const store = useStore()
    const { showVipQrcode } = useVip()
    const { useMvQuality } = useQuality()
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const playingMvQuality = computed(() => store.state.playingMvQuality)
    const playingSongItemM3u8 = computed(() =>
      get(store.state.videoPlayerHistory, 'songItem.m3u8', {})
    )
    const videoPlayer = computed(() => store.state.videoPlayerHistory)

    // 这里因为有的hls资源缺失 所以不用全局的去同步选中状态 默认打开画质选择时，不显示选择状态
    // let checkedQuality = ref('')

    const qualityList = [
      {
        name: '480P',
        label: '标清',
        value: '480',
      },
      {
        name: '720P',
        label: '高清',
        value: '720',
      },
      {
        name: '1080P',
        label: '蓝光',
        value: '1080',
      },
    ]

    const handleChoose = (quality) => {
      if (quality.value === '1080' && (!isLogin.value || !isVip.value)) {
        showVipQrcode({
          mType: 'mv页画质vip弹窗',
          log: '画质切换-1080',
        })
        return
      }

      // checkedQuality.value = quality.value
      useMvQuality(quality.value)
      sendLog({
        event_type: 'custom',
        event_name: 1070,
        event_data: {
          str1: '歌曲清晰度埋点',
          str2: videoPlayer.value.songItem.songid,
          str3: videoPlayer.value.songItem.music_name,
          str4: videoPlayer.value.songItem.singer,
          str5: `${quality.value}P`, // 画质
          str6: '2', // 上报时机
        },
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 10053,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: '画质选择',
          str4: 'click',
          str5: quality.value,
          str6: 'click',
        },
      })
    }

    let isShow = ref(true)

    const show = () => {
      isShow.value = true
    }

    const close = () => {
      isShow.value = false
      emit('close')
    }

    return {
      qualityList,
      // setting,
      // checkedQuality,
      playingMvQuality,
      playingSongItemM3u8,
      isShow,
      show,
      close,
      handleChoose,
    }
  },
}
</script>

<style lang="stylus" scoped>
.mv-quality-modal
  z-index 110
  &-content
    width 1000px
    height 450px
    position relative
    overflow hidden
    border-radius 20px
    background rgba(30, 31, 33, 1)
    display flex
    flex-direction column
    align-items center
    &-close
      position absolute
      top 30px
      left 30px
      width 90px
      height 90px
    &-title
      color rgba(255, 255, 255, 0.8)
      font-size 40px
      text-align center
      margin-top 52px
      height 46px
      line-height 46px
      font-weight 400
    &-main
      width 876px
      height 200px
      margin-top 72px
      display flex
      align-items center
      justify-content center
      &-item
        width 260px
        height 200px
        display flex
        flex-direction column
        align-items center
        justify-content center
        border-radius 20px
        background rgba(56, 58, 62, 1)
        position relative
        .title
          color rgba(255, 255, 255, 0.8)
          font-size 36px
          font-weight 600
        .des
          color rgba(255, 255, 255, 0.5)
          font-size 28px
        img
          position absolute
          top 12px
          right 12px
          width 56px
          height 28px
        &-active
          border 4px solid #5659FF
          background rgba(86, 89, 255, 0.10)
        &-unactive
          display none
          // pointer-events none
          // background rgba(56, 58, 62, 0.40)
          .title
            color rgba(255, 255, 255, 0.10) !important
          .des
            color rgba(255, 255, 255, 0.10) !important
        &:nth-child(2)
          margin 0 48px
</style>
