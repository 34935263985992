import { createComponent } from '@/components/modal/utils/index.js';
import ActivityAifaceModal from "./aiface.vue";
import ActivityModalAlert from "./alert.vue";
import ActivityFreeSongNoTimeModal from "./freeSongNoTime.vue";
import ActivityModal from "./index.vue";
import ActivityKSongModal from "./ksong.vue";
import ActivityKuaiChangModal from "./kuaichang.vue";
import ActivityLowerVipModal from "./lowerVip.vue";
import ActivityMicModal from "./mic.vue";
import ActivityRecommendModal from "./recommendSong.vue";
import ActivitySignInModal from "./signIn.vue";
import ActivityVipModal from "./vip.vue";
import VipExpireModal from "./vipExpire.vue";
import ActivityZeroBuyVipModal from "./zerobuyvip.vue";

const creatCommonUseModal = (component, _forceProps = {}) => {

  return function (globalProps = {}, globalSlots = {}) {

    return {
      show(props = globalProps, slots = globalSlots) {
        const forceProps = {
          programmatic: true,
          lockScroll: true,
          isFullPage: true,
          active: true,
          canCancel: false,
          ..._forceProps,
        }
  
        const propsData = Object.assign({}, globalProps, props, forceProps);
        const container = document.body;
  
        const mergedSlots = Object.assign({}, globalSlots, slots);
        const instance = createComponent(component, propsData, container, mergedSlots);
        return {
          hide: () => {
            if (instance && instance.refs && instance.refs.root) {
              instance.refs.root.hide();
            } else {
              console.error('Unable to hide modal, instance or ref "root" is not defined.');
            }
          }
        };
      },
    }
  }
}

const useActivity = creatCommonUseModal(ActivityModal)

const useActivityAlert = creatCommonUseModal(ActivityModalAlert)

const useActivityMic = creatCommonUseModal(ActivityMicModal)

const useActivityVip = creatCommonUseModal(ActivityVipModal)

const useVipExpire = creatCommonUseModal(VipExpireModal, { canCancel: true })

const useActivityRecommendSong = creatCommonUseModal(ActivityRecommendModal)

const useActivitySignIn = creatCommonUseModal(ActivitySignInModal)

const useZeroBuyVip = creatCommonUseModal(ActivityZeroBuyVipModal)

const useActivityKSong = creatCommonUseModal(ActivityKSongModal)

const useActivityKuaiChang = creatCommonUseModal(ActivityKuaiChangModal)

const useActivityFreeSongNoTime = creatCommonUseModal(ActivityFreeSongNoTimeModal, { canCancel: true })

const useActivityAiface = creatCommonUseModal(ActivityAifaceModal)

const useActivityLowerVip = creatCommonUseModal(ActivityLowerVipModal)


export default useActivity

export {
  useActivityAiface, useActivityAlert, useActivityFreeSongNoTime, useActivityKSong,
  useActivityKuaiChang, useActivityLowerVip, useActivityMic, useActivityRecommendSong,
  useActivitySignIn, useActivityVip,
  useVipExpire, useZeroBuyVip
};

