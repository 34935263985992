const formatStr = "yyyy-MM-dd HH:mm:ss";

export const vipLogFrom = new Map([
  ["开屏弹窗", "1800"],
  ["开屏弹窗2", "1847"],
  ["首页-顶部运营位", "1733"],
  // ["我的入口-头像", "1740"],
  ["我的页面-续费", "1741"],
  ["歌单-歌单名称", "1841"],
  ["歌星-顶部运营位", "1743"],
  ["分类-顶部运营位", "1744"],
  ["常唱列表", "1745"],
  ["已唱列表", "1746"],
  // ["画质切换-1080", "1747"],
  ["其他", "1748"],
  ["搜索-会员歌曲", "1749"],
  ["歌星-会员歌曲", "1750"],
  ["分类-会员歌曲", "1751"],
  // ["过期会员", "1752"],
  ["歌名-会员歌曲", "1753"],
  // ['', '1754'],
  // ['手机会员兑换码', '1755'],
  ["强制登录", "1756"],
  ["首页-底部运营位", "1757"],
  ["首页-顶部运营位-车机微信支付码", "1758"],
  ["首页-底部运营位-车机微信支付码", "1759"],
  ["search-底部运营位", "1761"],
  ["singer-底部运营位", "1814"],
  ["search-顶部运营位", "1760"],
  ["singer-顶部运营位", "1762"],
  ["MV界面", "1833"],
  ["未登录-唱过的歌", "1832"],
  ["扫码登录-我的", "1801"],
  ["首页-画质歌单-画质权益弹窗", "1849"],
  ["首页-画质歌单-会员歌曲弹窗", "1850"],
  ["通用-已点运营位弹窗", "1851"],
  ["通用-已唱运营位弹窗", "1852"],
  ["搜索页-搜索栏-空词搜索弹窗", "1853"],
  ["搜索结果页-歌曲结果-列表运营位弹窗", "1854"],
  ["首页-新歌歌单-vip权益弹窗", "1857"],
  ["歌星详情页会员文字链", "1865"],
  ["songList-顶部运营位", "1816"],
  ["songList-底部运营位", "1818"],
  ["副歌页唱整首", "1858"],
  ["副歌页唱整首-车机", "1859"],
  ["副歌页试唱提示", "1860"],
  ["副歌页试唱提示-车机", "1861"],
  ["副歌页副歌VIP弹窗", "1862"],
  ["副歌页副歌VIP弹窗-车机", "1863"],
  ["挽留弹窗1", "1869"],
  ["挽留弹窗2", "1870"],
  ["特别定制", "1873"],
  ["就唱这首", "1875"],
  ["首页右上角小喇叭运营位", "1878"],
  ["新用户免费次数用尽", "1880"],
  ["首页-智能推荐", "1881"],
  ["欢唱页-智能推荐", "1883"],
  ["国庆K歌指南: 唱响中国梦", "1892"],
  ["搜索-智能推荐", "1893"],
  ["歌星-智能推荐", "1895"],
]);

export const homeBannerImages = [
  "https://qncweb.ktvsky.com/20240805/vadd/10192469b9e6021033d0bdd0d62117fd.png",
  "https://qncweb.ktvsky.com/20240914/vadd/a76c117fee6bc5f9184a99f745587598.png",
  "https://qncweb.ktvsky.com/20241031/vadd/3df2eec7efe9d67f2f02f7757e340e4b.png",
  "https://qncweb.ktvsky.com/20240805/vadd/395d56bf78d331eebc5bc3ac88f60637.png",
];

export default formatStr;
