import XifenQrcodeComponent from './index.vue'
import useXifenQrcode from './create.js';

const Plugin = (app, props = {}, slots = {}) => {
  const xifenQrcode = useXifenQrcode(props, slots);
  app.config.globalProperties.$xifenQrcode = xifenQrcode;
  app.provide('$xifenQrcode', xifenQrcode)
};

XifenQrcodeComponent.install = Plugin;

export default XifenQrcodeComponent;
