/**
 * vip pay latest compelete 2024-11-07
 * prd: https://m2miovoqda.feishu.cn/sheets/RwPzswCaDh25CatakaMcbq25nug?sheet=oeuGx9
 */
export const payLogFrom = new Map([
  // ok
  ["会员过期后弹窗", {
    zd: "",
    m: "1845",
  }],
  // ok
  ["首页-底部运营位", {
    zd: "",
    m: "1806",
  }],
  // ok
  ["首页歌单会员歌曲", {
    zd: "1803",
    m: "",
  }],
  // ok
  ["画质切换-1080", {
    zd: "",
    m: "1835",
  }],
  // ok
  ["我的入口-头像", {
    zd: "",
    m: "1801",
  }],
  // ok
  ["歌星详情页会员歌曲", {
    zd: "1802",
    m: "1836",
  }],
  // ok
  ["歌星列表页-底部横条", {
    zd: "",
    m: "1814",
  }],
  // ok
  ["歌星详情页-底部横条", {
    zd: "1819",
    m: "",
  }],
  // ok
  ["我的页-底部横条", {
    zd: "1825",
    m: "",
  }],
  // ok
  ["会员中心页-底部横条", {
    zd: "1827",
    m: "",
  }],
  // ok
  ["兑换vip页-底部横条", {
    zd: "1828",
    m: "",
  }],
  // ok
  ["我的页-车机端", {
    zd: "1830",
    m: "",
  }],
  // ok
  ["会员中心页-车机端", {
    zd: "1831",
    m: "",
  }],
  // ok
  ["搜索页会员歌曲", {
    zd: "",
    m: "1837",
  }],
  // ok
  ["已唱列表会员歌曲", {
    zd: "",
    m: "1842",
  }],
  // ok
  ["欢唱页快速点歌会员歌曲", {
    zd: "",
    m: "1843",
  }],
  ["欢唱页歌手点歌会员歌曲", {
    zd: "",
    m: "1844",
  }],
  // ok
  ["新歌榜", {
    zd: "",
    m: "1856",
  }],
  // ok
  ["权益顶部运营位", {
    zd: "1868",
    m: "1878",
  }],
  // ok
  ["特别定制模块", {
    zd: "1874",
    m: "",
  }],
  // ["换脸-首页", {
  //   zd: "",
  //   m: "1889",
  // }],
]);