<template>
  <div>
    <div v-show="!isPopupShow" class="control-popup-button" @click.stop="show">
      <slot name="control-popup-button"></slot>
    </div>
    <div v-show="isPopupShow" class="control-popup-plugin" @click.self="close">
      <slot name="control-popup-plugin"></slot>
    </div>
  </div>
</template>
<script setup>
import eventEmitter from "@/utils/event-bus";
import { ref, onMounted, onBeforeUnmount, watch, defineExpose } from "vue";
let isPopupShow = ref(false);

const stopViewScroll = () => {
  document.body.style.overflowY = "hidden";
};

const recoverViewScroll = () => {
  document.body.style.overflowY = "auto";
};

const show = () => {
  isPopupShow.value = true;
};

const close = () => {
  isPopupShow.value = false;
  // eventEmitter.emit('handle-close-popup')
};

watch(isPopupShow, (val) => {
  if (val) {
    stopViewScroll();
  } else {
    recoverViewScroll();
  }
});

onMounted(() => {
  eventEmitter.on("control-popup-show", show);
});

onBeforeUnmount(() => {
  eventEmitter.off("control-popup-show", show);
});

defineExpose({
  show,
  close,
});
</script>
<style lang="stylus" scoped>
.control-popup
  &-plugin
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba(0, 0, 0, 0.85)
    display: flex
    justify-content: center
    align-items: center
    z-index 103
</style>
