<template>
  <div
    class="mplayer"
    @click="$emit('on-video-click')"
    :class="{
      'is-size-inherit': isSizeInherit,
    }"
  >
    <video
      id="mplayer"
      :muted="muted"
      autoplay
      :poster="poster"
      :src="src"
      @play="$emit('play')"
      @pause="$emit('pause')"
      @canplay="handleCanPlay"
      @ended="handleVideoEnded"
      @timeupdate="handleTimeupdate"
    ></video>
    <div class="mv-bar-btn" @click="toggleMute">
      <img :src="muteIconSrc" class="icon" alt="静音图标" />
    </div>
  </div>
</template>
<script>
import { sendLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus'
import { computed, onMounted, onUnmounted, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    src: String,
    autoplay: {
      type: Boolean,
      default: true,
    },
    poster: String,
    startPosition: {
      type: Number,
      default: 0,
    },
    isSizeInherit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { src, autoplay, startPosition } = toRefs(props)
    const store = useStore()
    // video dom实例
    let video = null
    const onVideoEventsInitPtsFound = () => {
      console.log('INIT_PTS_FOUND')
      // emit('onInitPtsFound')
      if (video) {
        video.src = src.value
        video.currentTime = startPosition.value
        handleControlVideoPlay()
      }
    }
    // 错误处理
    const handleVideoError = (event, data) => {
      console.log('handleVideoError')
      emit('error', data)
    }
    const attachMedia = () => {
      console.log('attachMedia', src.value)
      video = document.getElementById('mplayer')
      onVideoEventsInitPtsFound()
      // video.addEventListener('loadedmetadata', onVideoEventsInitPtsFound);
      video.addEventListener('ended', handleVideoEnded)
      video.addEventListener('error', handleVideoError)
    }

    const handleDetachMedia = () => {
      if (video) {
        // video.removeEventListener('loadedmetadata', onVideoEventsInitPtsFound);
        video.removeEventListener('ended', handleVideoEnded)
        video.removeEventListener('error', handleVideoError)
      }
    }
    // 播放
    const handleControlVideoPlay = () => {
      if (video) {
        video.play()
        emit('onPlayingChange', true)
      }
    }
    // 暂停
    const handleControlVideoPause = () => {
      if (video) {
        video.pause()
        emit('onPlayingChange', false)
      }
    }
    // 重播
    const handleControlVideoReplay = () => {
      if (video) {
        video.currentTime = 0
        video.paused() && video.play()
        emit('onPlayingChange', true)
      }
    }
    // video canplay事件
    const handleCanPlay = () => {
      // //此设置需单独提出 放入下面的条件里会影响默认暂停播放的情况
      // video.muted = false
      if (video) {
        video.play()
        video.volume = 0.4
        emit('onPlayingChange', true)
      }
      // handleSwitchAudioTrack({ id: 0})
      console.log('canplay')
      emit('canplay')
    }
    // video ended事件
    const handleVideoEnded = () => {
      emit('ended', video)
    }
    // video timeupdate事件
    const handleTimeupdate = (t) => {
      emit('timeupdate', t)
    }

    const attachVideoPlayerEvents = () => {
      eventBus.on('mp4-control-play', handleControlVideoPlay)
      eventBus.on('mp4-control-pause', handleControlVideoPause)
      eventBus.on('mp4-control-replay', handleControlVideoReplay)
      eventBus.on('handle-video-muted', handleMuted)
    }
    const detachVideoPlayerEvents = () => {
      eventBus.off('mp4-control-play', handleControlVideoPlay)
      eventBus.off('mp4-control-pause', handleControlVideoPause)
      eventBus.off('mp4-control-replay', handleControlVideoReplay)
      eventBus.off('handle-video-muted', handleMuted)
    }
    onMounted(() => {
      console.log('onMounted: VideoPlayer')

      attachMedia()
      attachVideoPlayerEvents()
    })
    onUnmounted(() => {
      console.log('onUnmounted: VideoPlayer')
      handleDetachMedia()
      detachVideoPlayerEvents()
    })

    // const muted = ref(!store.state.isFirstSong);
    const muted = computed(() => store.state.miniMv.muted)
    // const handleMutedClick = ()=> {
    //   muted.value = false;
    // };
    const muteIconSrc = computed(() => {
      return muted.value
        ? 'https://qncweb.ktvsky.com/20241204/vadd/6ae067c50b9b2c0dfe4ad806fcd4f23b.png'
        : 'https://qncweb.ktvsky.com/20241204/vadd/4f91f82bb920316cf4b0602281925239.png'
    })

    const toggleMute = () => {
      // muted.value = !muted.value;
      store.dispatch('miniMv/setMuted', !muted.value)
      // console.log("muted", muted.value);

      sendLog({
        event_type: '10000~50000',
        event_name: 30279,
        event_data: {
          str1: '小屏mv',
          str2: muted.value ? '开启静音button' : '关闭静音button',
          str3: '点击',
          str4: 'click',
        },
      })
    }

    const handleMuted = () => {
      // muted.value = true;
      store.dispatch('miniMv/setMuted', true)
    }

    return {
      handleCanPlay,
      handleVideoEnded,
      handleTimeupdate,
      handleControlVideoPlay,
      handleControlVideoPause,
      muted,
      // handleMutedClick,
      muteIconSrc,
      toggleMute,
    }
  },
}
</script>
<style lang="stylus" scoped>
  .mplayer
    position relative
    display flex
    flex-direction column
    justify-content center
    align-items center
    width 100vw
    height 100vh
    .mv-bar-btn
      width 88px
      height 64px
      background rgba(31, 31, 32, 0.2)
      border-radius 20px
      border 1px solid rgba(255, 255, 255, 0.15)
      backdrop-filter blur(30px)
      display flex
      align-items center
      justify-content center
      position absolute
      top 24px
      left 24px
      z-index 99
      .icon
        width 44px
        height 44px
        object-fit scale-down

      @media screen and (max-width 1200px) and (min-height 1200px)
        width 72px
        height 51px
        .icon
          width 32px
          height 32px

    @media screen and (max-width 1200px) and (min-height 1200px)
      background url(https://qncweb.ktvsky.com/20231222/vadd/14bc0b0d4226287c2b2e3401d8c70052.png) no-repeat
      background-size 100% 100%
      background-position center
      &-bottom
        position absolute
        bottom 120px
        display flex !important
        flex-direction column
        justify-content center
        align-items center
        img
          // width 100px
          width 300px
        p
          color #999999
          font-size 30px
          margin-top 40px
    @media screen and (max-width 1180px) and (min-height 1200px)
      &-bottom
        bottom 50px !important
    &-bottom
      display none
    video
      width 100vw
      height 100vh
      z-index 5
      background black
      position absolute
      transition-property all
      transition-duration .5s
      transition-timing-function linear
      @media screen and (max-width 1200px) and (min-height 1421px)
        width 100vw !important
        height 675px !important
        top 172px !important
      @media screen and (max-width 1180px) and (min-height 1200px)
        width 100vw !important
        height 675px !important
        top 378px !important

      @media screen and (max-width 1200px) and (min-height 1200px)
        height 100% !important
        top 0px !important

    &-plugins
      position absolute
      top 0
      bottom 0
      right 0
      left 0
      width 100vw
      height 100vh
      z-index 10
    .full-video
      top 0
      // left 0
.is-size-inherit
  width 100%
  height 100%
  video
    width 100%
    height 100%
</style>
