<template>
  <div class="header">
    <div class="header-left">
      <template v-if="!isShowLogo">
        <div class="header-back">
          <img
            @click="handleBack"
            src="https://qncweb.ktvsky.com/20241127/vadd/ea34016b1905c909046c6626cb491275.png"
          />
          <span class="header-title">
            {{ title }}
          </span>
        </div>
      </template>
      <div class="header-logo" v-else>
        <img
          src="https://qncweb.ktvsky.com/20241127/vadd/890c03a6a899a7c1ccfd0055289997de.png"
          alt=""
        />
      </div>

      <PureSearch
        v-if="isSearch"
        class="search-bar-pure"
        :isSearch="true"
        placeholder=""
        ref="pureSearchRef"
        @search="handleSearch"
      />

      <div v-if="isShowTimesInfo" class="times-info" @click="handleClickTimes">
        <template v-if="!isLogin">
          <!-- <span class="light">登录</span>即可享千元特权，好礼送不停！ -->
          <span class="light">登录领取</span>VIP权益，好礼送不停！
          <img
            src="https://qncweb.ktvsky.com/20240925/vadd/08e914c55df65ba848439f6e12293197.png"
            alt=""
          />
        </template>
        <template v-else-if="isVip"
          >尊敬的VIP用户，拉满状态开启您狂欢时刻！</template
        >
        <template v-else-if="isExpire"
          >权益重磅升级，邀您回归！<span class="light"
            >续费低至¥0.2/天</span
          ></template
        >
        <template v-else>
          <p v-if="showFreeOrderInfo" class="free-order">
            VIP歌曲免费点唱次数:
            <span class="free-order-zero">{{ remainingFreeOrders }}/3</span>
          </p>
          <p v-if="isShowClimax" class="climax-free">
            快唱高潮限免时长
            <CountDown
              class="climax-free-countdown"
              ref="userTime"
              :time="preferentialTime"
              @finish="handleTimeFinish"
              :auto-start="false"
            >
              <template #default="timeData">
                <span class="block">00</span>
                <span class="colon">:</span>
                <span class="block">{{
                  formattedMinutes(timeData.minutes)
                }}</span>
                <span class="colon">:</span>
                <span class="block">{{
                  formattedSeconds(timeData.seconds)
                }}</span>
              </template>
            </CountDown>
          </p>
        </template>
      </div>
    </div>

    <div class="head-bar-right" v-if="isShowRight">
      <div v-if="isShowSearchImg" class="search-img" @click="handleToSearch">
        <img
          src="https://qncweb.ktvsky.com/20241127/vadd/12cd5320e6dc8167c5ec0b7da584c6ce.png"
          alt=""
        />
      </div>

      <div
        v-if="isShowSinging"
        class="head-bar-right-item"
        @click="handleGoSinging"
      >
        <img
          src="https://qncweb.ktvsky.com/20240924/vadd/170d8cce1e46486670aad034ef0ed16a.png"
        />
        <p>唱过的歌</p>
      </div>

      <!-- 已点 -->
      <div
        class="head-bar-right-item already-order"
        v-if="isShowOrder"
        @click="handleOpenOrderControl"
      >
        <span v-if="orderedSongNum && orderedSongNum < 100">{{
          orderedSongNum
        }}</span>
        <span class="already-order-span" v-if="orderedSongNum > 99">99+</span>
        <img
          src="https://qncweb.ktvsky.com/20241127/vadd/c7c6d9ff1acda07e356ab6c8ab468130.png"
        />
        <p>已点</p>
      </div>

      <div
        v-if="isShowSetting"
        class="head-bar-right-item"
        @click="handleSetting"
      >
        <img
          src="https://qncweb.ktvsky.com/20240924/vadd/f77daf60a7c5306e9874e3d4c0e5c35c.png"
        />
        <p>设置</p>
      </div>
    </div>
  </div>
</template>

<script>
import PureSearch from '@/components/pure-search/index.vue'
import useLoginValid from '@/composables/useLoginValid'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus'
import { CountDown } from 'vant'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'HeadBar',
  components: { CountDown, PureSearch },
  props: {
    title: {
      type: String,
      default: '',
    },
    isShowLogo: {
      type: Boolean,
      default: false,
    },
    isShowTimesInfo: {
      type: Boolean,
      default: false,
    },
    isShowRight: {
      type: Boolean,
      default: false,
    },
    isShowOrder: {
      type: Boolean,
      default: true,
    },
    isShowSinging: {
      type: Boolean,
      default: true,
    },
    isShowSetting: {
      type: Boolean,
      default: true,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isShowSearchImg: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const router = useRouter()
    const store = useStore()
    const { showVipQrcode } = useVip()
    const { showLoginQrcode, isLogin } = useLoginValid()

    const pureSearchRef = ref(null)

    const handleBack = () => {
      store.dispatch('getCarplayInfo') // 返回上一页时更新下用户状态
      router.back()
      emit('back')
    }

    const orderedSongNum = computed(() => store.state.orderedList.length)

    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const vipInfo = computed(() => store.state.vipInfo)
    const freeVipNumber = computed(() => Number(store.state.freeVipNumber1))
    const isExpire = computed(() => vipInfo.value.expire)
    const isClimaxFree = computed(() => !store.state.climax.freeSing)
    const isShowClimax = computed(() => !isVip.value && isClimaxFree.value)
    // const isLogin = computed(() => !!store.state.userInfo.unionid)

    const showFreeOrderInfo = computed(
      () => !isExpire.value && !isShowClimax.value && freeVipNumber.value < 3
    )
    const remainingFreeOrders = computed(() => 3 - freeVipNumber.value)

    const preferentialTime = ref(30 * 60 * 1000)
    const userTime = ref(null)

    const handleTimeFinish = () => {
      // store.dispatch('climax/updateFreeSing', true)
    }

    const handleClickTimes = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30256,
        event_data: {
          str1: '首页',
          str2: '右上角喇叭通知',
          str3: '点击',
          str4: 'click',
        },
      })
      if (!isVip.value) {
        if (isLogin.value) {
          showVipQrcode({
            // 这里可能需要定义 showVipQrcode 函数
            isLogin: isLogin.value,
            fr: 1868,
            log: '权益顶部运营位',
          })
        } else {
          showLoginQrcode({
            log: '权益顶部运营位',
          })
        }
      }
    }

    const formattedMinutes = (minutes) => {
      return minutes < 10 ? `0${minutes}` : minutes
    }

    const formattedSeconds = (seconds) => {
      return seconds < 10 ? `0${seconds}` : seconds
    }

    const handleGoSinging = () => {
      store.dispatch('vipAddSong/resetSong')
      if (!isLogin.value) {
        sendLog({
          event_type: '10000~50000',
          event_name: 30182,
          event_data: {
            str1: '首页',
            str2: '唱过的歌',
            str3: '未登录二维码展示',
            str4: 'show',
          },
        })
        showLoginQrcode({
          log: '未登录-唱过的歌',
          from: 'singing',
        })
        return
      }
      router.push({
        name: 'singing',
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 30176,
        event_data: {
          str1: '唱过的歌页',
          str2: '唱过的歌页',
          str3: '进入唱过的歌页',
          str4: 'show',
        },
      })
    }

    const handleSetting = () => {
      router.push({
        name: 'setting',
      })
    }

    const handleOpenOrderControl = () => {
      eventBus.emit('show-order-song-control-popup')
      sendLog({
        event_type: '10000~50000',
        event_name: 10005,
        event_data: {
          str1: '首页',
          str2: '已点',
          str3: '打开已点',
          str4: 'click',
        },
      })
    }

    const handleSearch = (e) => {
      emit('search', e)
    }

    const handleToSearch = () => {
      router.push({
        name: 'search',
      })
    }

    return {
      pureSearchRef,
      isLogin,
      isVip,
      isExpire,
      isShowClimax,
      orderedSongNum,
      showFreeOrderInfo,
      remainingFreeOrders,
      preferentialTime,
      userTime,
      formattedMinutes,
      formattedSeconds,
      handleTimeFinish,
      handleBack,
      handleClickTimes,
      handleGoSinging,
      handleSetting,
      handleOpenOrderControl,
      handleSearch,
      handleToSearch,
    }
  },
}
</script>

<style lang="stylus" scoped>
$white-color = #FFFFFF
$white-opacity-20 = rgba(255, 255, 255, 0.2)
$white-opacity-15 = rgba(255, 255, 255, 0.15)
$white-opacity-30 = rgba(255, 255, 255, 0.3)
$white-opacity-80 = rgba(255, 255, 255, 0.8)
$border-radius-24 = 24px
$border-radius-16 = 16px
$font-size-24 = 24px
$font-size-30 = 30px

.header
  width 100vw
  height 150px
  padding 0 64px 0 80px
  display flex
  align-items center
  justify-content space-between
  position fixed
  top 0
  left 0
  z-index 6
  // 添加背景图，背景图宽度100%，高度超出部分裁剪
  background-image url('https://qncweb.ktvsky.com/20241127/vadd/0d5dae30b8c221e16a2429919125c5db.png')
  background-size 100% 100%
  background-repeat no-repeat

  &-left
    display flex
    align-items center

  &-title
    color rgba(29, 29, 31, 1)
    font-size var(--font-size-large)

  &-logo
    width 225px
    height 50px
    img
      width 100% !important
      height 100% !important
  &-back
    width fit-content
    height 164px
    display flex
    align-items center
    justify-content center
    img
      width 40px
      height 40px
      margin 0 60px 0 0

  @media screen and (max-width 1200px) and (min-height 1200px)
    height 150px !important
    padding 0 40px
    &-back
      height 104px
      margin-left 0px
      img
        margin 0 48px 0 0
  .times-info
    display flex
    align-items center
    background-color rgba(255, 255, 255, 0.8)
    height 76px
    background-image url('https://qncweb.ktvsky.com/20240710/other/b8108ad12e7dcc159db670aa63f8daa6.png')
    background-size 40px auto
    background-repeat no-repeat
    background-position 34px center
    border-radius 4px
    color rgba(29, 29, 31, 0.9)
    font-size 20px
    padding-left 84px
    padding-right 34px
    margin-left 32px
    img
      width 34px
      height 34px
    @media screen and (max-width 1200px) and (min-height 1200px)
      height 60px
      margin-left 20px
      font-size 18px
      padding-left 58px
      padding-right 20px
      background-size 28px auto
      background-position 20px center
      border-radius $border-radius-16
      img
        width 26px
        height 26px
    .light
      color #E5B875
      font-size 20px

    p
      color rgba(29, 29, 31, 0.9)

    .free-order
      position relative
      padding-right 20px
      margin-right 20px
      &:after
        content ''
        position absolute
        top 50%
        margin-top -8px
        right 0
        width 2px
        height 20px
        background rgba(255, 255, 255, 0.2)
    .climax-free
      display flex

      &-countdown
        color rgba(0, 0, 0, 0.2)
        margin-left 10px
        .block
          display inline-block
          background rgba(0, 0, 0, 0.2)
          width 28px
          height 28px
          line-height 28px
          text-align center
          font-size 18px
          border-radius 4px
          color $white-opacity-80
        .colon
          margin 0 4px
  .head-bar-right
    // flex 1
    display flex
    align-items center
    .search-img
      width 400px
      height 84px
      img
        width 100%
        height 100%
    .already-order
      width auto
      height 84px
      background rgba(160, 74, 240, 1) !important
      border 1px solid rgba(255, 255, 255, 0.15)
      border-radius var(--border-radius-medium)
      display flex
      align-items center
      justify-content center
      position relative
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin 0
      span
        position absolute
        top -9px
        right -8px
        display flex
        justify-content center
        align-items center
        width auto
        height 36px
        border-radius 50%
        padding 0 12px
        color rgba(52, 38, 17, 1)
        background rgba(219, 174, 106, 1)
        font-size var(--font-size-small)
        font-weight 700
        z-index 1
      p
        min-width 60px
        height 84px
        line-height 84px
        font-weight 500
        font-size var(--font-size-medium)
        color rgba(255, 255, 255, .8)
        margin-left 0px
    .head-bar-right-item
      // max-width 250px
      width auto
      height 84px
      padding 0 36px
      border-radius var(--border-radius-medium)
      background rgba(255, 255, 255, 0.2)
      margin-left 24px
      display flex
      align-items center
      justify-content center
      font-size var(--font-size-small)
      color rgba(255, 255, 255, 1)
      font-weight 500
      img
        width 48px
        height 48px
        margin-right 8px
      &:nth-child(1)
        margin-left 0
</style>
