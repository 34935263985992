import http from '@/utils/http'
import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

let pollingTimer = null;
let pollingAttempts = 0;

export async function getVipQR({ mac_id, pkg_id, unionid, openid }) {
  const res = await http.get('/v2/stb/carplay/vip/qr', {
    params: {
      mac_id,
      pkg_id,
      unionid,
      openid
    }
  })
  return get(res, 'data', {})
}

export async function getPayPkg() {
  const res = await http.get('/stb/carplay/paypkg')
  return get(res, 'data', [])
}

export async function exchangeVip({ cdkey, mac_id }) {
  // '/stb/carplay/coupon'
  const res = await httpV2.get('/stb/v2/coupon', {
    params: {
      cdkey,
      mac_id
    }
  })
  // mac_id
  // 车机唯一标识
  // days
  // VIP有效时长
  // finish_time
  // 兑换完成时间
  // start_time
  // vip开始时间
  // end_time
  // vip到期时间
  return res
}

export async function getExchangeHistory(unionid, p) {
  // '/stb/carplay/coupon/history'
  const res = await httpV2.get('/stb/v2/coupon/history', {
    params: {
      unionid,
      p,
      size: '20'
    }
  })
  return get(res, 'data', [])
}

export async function checkOrderPayStatus(order_id) {
  const res = await http.get('/carplay/vip/query', {
    params: {
      order_id
    }
  })
  return res
}
export async function getOperationVipQR(unionid, pkg_id = 6) {
  const res = await httpV2.get('/stb/v2/vip/qr', {
    params: {
      unionid,
      pkg_id
    }
  })
  return res
}
export async function getVipPkg(unionid) {
  const res = await httpV2.get('/stb/v2/vip/pkg', {
    params: {
      unionid,
    }
  })
  return res
}
export async function getVipPkgQr({ unionid, pkg_id, fr }) {
  if (!unionid || !pkg_id || !fr) {
    throw new Error('必须传递 unionid、pkg_id 和 fr 参数');
  }
  const res = await httpV2.get('/stb/v2/vip/qr', {
    params: {
      unionid,
      pkg_id,
      fr
    }
  })
  return res.data
}

export function getVipQuery(order_id) {
  return new Promise((resolve, reject) => {
    const poll = async () => {
      clearTimeout(pollingTimer);

      try {
        const res = await httpV2.get('/stb/v2/vip/query', {
          params: {
            order_id
          }
        });

        if (res.errmsg === '支付成功') {
          resolve(res);
        } else {
          if (pollingAttempts < 30) {
            pollingAttempts++;
            pollingTimer = setTimeout(poll, 5000); // 设置轮询间隔，这里是5秒
          } else {
            reject(new Error('达到最大轮询次数'));
          }
        }
      } catch (error) {
        reject(error);
      }
    };

    poll();
  });
}
export function clearVipQueryPolling() {
  clearTimeout(pollingTimer);
  pollingAttempts = 0;
}

// 3天限时vip免费活动字段接口
export async function getVipFreeActivity(unionid) {
  const res = await httpV2.get('/stb/v2/vip/freev2', {
    params: {
      unionid
    }
  })
  return res
}