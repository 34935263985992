<template>
  <ControlPopup ref="root">
    <template #control-popup-plugin>
      <div class="order-song-control-panel">
        <div class="header">
          <div class="tab">
            <div
              class="tab-item"
              v-for="(tab, index) in tabList"
              :key="index"
              :class="{ actived: curTab.name === tab.name }"
              @click="handleChangeTab(tab)"
            >
              {{ tab.text }}
            </div>
          </div>
          <div class="close-box">
            <svg @click="handleClose" width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_263_56988)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M28.5637 27.1502L27.1495 28.5645L40.5851 42L27.1504 55.4347L28.5646 56.8489L41.9993 43.4142L55.4338 56.8487L56.848 55.4345L43.4135 42L56.8489 28.5646L55.4347 27.1504L41.9993 40.5858L28.5637 27.1502Z" fill="black" fill-opacity="0.8"/>
              </g>
              <defs>
              <clipPath id="clip0_263_56988">
              <rect x="22" y="22" width="40" height="40" rx="20" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <!-- <div
          v-if="shouldShowBanner"
          class="banner"
          @click="handleClickBanner"
          v-log="{
            event_type: 'show',
            event_name: 30213,
            event_data: {
              str1: '已点/已唱',
              str2: 'ai换脸横条',
              str3: '展示',
              str4: 'show',
              str5: isLogin ? '已登录' : '未登录',
            }
          }"
        ><span class="close" @click.stop="showBanner = false"></span></div> -->
        <OrderSongList
          v-show="curTab.name === 'ordered'"
          @singer-click="handleClickSinger"
        ></OrderSongList>
        <AlreadySongList
          v-show="curTab.name === 'already'"
          @singer-click="handleClickSinger"
        ></AlreadySongList>
      </div>
    </template>
  </ControlPopup>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import eventBus from '@/utils/event-bus'
import ControlPopup from '@/components/control-popup/index.vue'
import AlreadySongList from '@/components/song-list/already/index.vue'
import OrderSongList from '@/components/song-list/order/index.vue'
import { sendLog } from '@/directives/v-log/log'
import useAIModal from '@/components/modal/global/ai-modal/create'

const store = useStore()
const router = useRouter()
const $AIModal = useAIModal();

const orderedListNum = computed(() => store.state.orderedList.length)
const alreadyListNum = computed(() => store.state.alreadyList.length)
const isLogin = computed(() => !!store.state.userInfo.unionid)
const tabList = computed(() => {
  return [
    {
      name: 'ordered',
      text: `已点(${orderedListNum.value > 99 ? '99+' : orderedListNum.value})`,
    },
    {
      name: 'already',
      text: `已唱(${alreadyListNum.value > 99 ? '99+' : alreadyListNum.value})`,
    },
  ]
})

const shouldShowBanner = computed(() => {
  return showBanner.value;
});

let curTab = ref(tabList.value[0])
let showBanner = ref(true)

const handleChangeTab = (tab) => {
  curTab.value = tab
  showBanner.value = true
  if (tab.text === '已唱') {
    sendLog({
      event_type: '10000~50000',
      event_name: 10061,
      event_data: {
        str1: '已点',
        str2: '已唱',
        str3: '进入已唱',
        str4: 'click',
      },
    })
  }
  if (tab.name === 'already') {
    sendLog({
      event_type: '10000~50000',
      event_name: 30017,
      event_data: {
        str1: '通用',
        str2: '已点/已唱弹窗',
        str3: '进入已唱',
        str4: 'click',
      },
    })
  }
  commonLog()
}

const root = ref(null)

const commonLog = () => {
  if (!shouldShowBanner.value) return
  sendLog({
    event_type: 'show',
    event_name: curTab.value.name === 'already' ? 30189 : 30187,
    event_data: {
      str1: '通用',
      str2: '已点/已唱弹窗',
      str3: `${curTab.value.name === 'already' ? '已唱' : '已点'}运营位展示`,
      str4: 'show',
    },
  })
}

const handleShow = () => {
  root.value.show()
  commonLog()
}

const handleClose = () => {
  root.value.close()
  showBanner.value = true
}

const handleClickSinger = ({ singer, singerhead, singerid }) => {
  router.push({
    name: 'songList',
    query: {
      name: singer,
      image: singerhead,
      singerid: singerid,
    },
  })
  root.value.close()
}

// const handleClickBanner = () => {
//   sendLog({
//     event_type: 'click',
//     event_name: '30214',
//     event_data: {
//       str1: '已点/已唱',
//       str2: 'ai换脸横条',
//       str3: '点击',
//       str4: 'click',
//       str5: isLogin.value ? '已登录' : '未登录',
//     },
//   })
//   $AIModal.show({
//     fromType: 'tesla_playlist'
//   })
//   // showVipQrcode({
//   //   log: log,
//   //   isLogin: isLogin.value,
//   //   fr: Number(vipLogFrom.get(log))
//   // });
// }

onMounted(() => {
  eventBus.on('show-order-song-control-popup', handleShow)
  eventBus.on('close-order-song-control-popup', handleClose)
})

onBeforeUnmount(() => {
  eventBus.off('show-order-song-control-popup', handleShow)
  eventBus.off('close-order-song-control-popup', handleClose)
})
</script>
<style lang="stylus" scoped>
.order-song-control-panel
  padding 30px
  width 1000px
  height 800px
  background #fff
  border-radius 32px
  padding 0 !important
  .header
    width 100%
    height 130px
    position relative
    display flex
    align-items center
    justify-content center
    .close-box
      width 84px
      height 84px
      background none
      position absolute
      top 24px
      right 24px
      display flex
      align-items center
      justify-content center
    .tab
      background #E8EAEE
      width 506px
      height 100px
      display flex
      align-items center
      justify-content space-between
      border-radius 28px
      padding 0 8px
      &-item
        display flex
        justify-content center
        align-items center
        width 240px
        height 84px
        font-size 32px
        color #1D1D1FB2
        &.actived
          color #FFFFFF
          background #A04AF0
          border-radius 20px
  // .banner
  //   background url('https://qncweb.ktvsky.com/20240429/other/0bd753d8b8894a24d248b000e170bafd.png') no-repeat
  //   width 904px
  //   height 114px
  //   background-size 904px 114px
  //   margin 0px auto
  //   position relative
  //   span
  //     position absolute
  //     width 50px
  //     height 50px
  //     right 10px
  //     top 50px
</style>
