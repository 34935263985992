<template>
  <div
    :class="[
      mvIsHide && 'mv-mini',
      !mvIsHide && 'mv-page bg_black',
      mvIsHide && !isShowMiniByRouteName && 'mv-mini-hidden',
    ]"
  >
    <VideoPlayer
      v-show="checkMvOrIrc"
      v-if="videoPlayer.songItem.hls"
      :autoplay="initAutoPlay"
      :startPosition="startPosition"
      :poster="require('./poster.png')"
      :src="videoPlayer.songItem.hls"
      :token="videoPlayer.songItem.token"
      :tokenExp="videoPlayer.songItem.tokenExp"
      :useFullScreen="isShowMvSideBar"
      @onManifestParsed="handleOnManifestParsed"
      @onManifestLoaded="handleOnManifestLoaded"
      @onInitPtsFound="handleOnInitPtsFound"
      @onAudioTracksSwitched="handleOnAudioTracksSwitched"
      @onFragLoading="handleOnFragLoading"
      @onFragLoaded="handleOnFragLoaded"
      @onFragLoadEmergencyAborted="handleOnFragLoadEmergencyAborted"
      @play="handleVideoPlay"
      @pause="handleVideoPause"
      @timeupdate="handleTimeupdate"
      @ended="handleVideoEnded"
      @error="handleVideoError"
      @unrecover="handleUnrecover"
      @retryToken="handleRetryToken"
      @init="handleVideoPlayerInit"
      @canplay="handleCanPlay"
      @onVideoClick="handleVideoClick"
    ></VideoPlayer>
    <div
      v-show="!mvIsHide"
      class="mv-page mv"
      @click.self="handleControlVideoPlayerTopMenu"
    >
      <MvSideBar
        :pos="isShowMvSideBar"
        :style="{ opacity: isShowMvSideBar > 0 ? 1 : 0 }"
      >
        <OrderList
          v-if="mvSlideBarItem === '已点'"
          @close="handleCloseMvSideBar"
        />
        <ChooseQuality
          v-if="mvSlideBarItem === '画质'"
          @close="handleCloseMvSideBar"
        />
        <AdjVolume
          v-if="mvSlideBarItem === '调音'"
          @close="handleCloseMvSideBar"
        />
        <FastOrderPage
          v-if="mvSlideBarItem === '快速点歌'"
          @close="handleCloseMvSideBar"
        />
        <UserVip
          v-if="mvSlideBarItem === '我的特权'"
          @close="handleCloseMvSideBar"
        />
      </MvSideBar>
      <VideoControlLayer
        v-show="isShowVideoPlayerPlugins"
        @click.self="handleControlVideoPlayerTopMenu"
      >
        <!-- <MvBackBtn @back="handleCloseMvPage" /> -->
        <MvBackHome />
        <VideoControl
          :paused="videoPaused"
          :shortMode="isShowMvSideBar"
          :enabledAudioTrackId="videoPlayer.enabledAudioTrack.id"
          :audioTrackMap="videoPlayer.audioTrackMap"
          @switchAudioTrack="handleSwitchAudioTrack"
          @videoControlPlay="handleVideoControlPlay"
          @videoControlPause="handleVideoControlPause"
          @videoControlReplay="handleVideoControlReplay"
          @videoControlNext="handleVideoControlNext"
        ></VideoControl>
        <VideoTopMenu
          v-if="!isShowMvSideBar"
          :headerimg="avatar"
          :enabledAudioTrackId="videoPlayer.enabledAudioTrack.id"
          :audioTrackMap="videoPlayer.audioTrackMap"
          @switchAudioTrack="handleSwitchAudioTrack"
          @show-side-bar="handleOpenSideBar"
        ></VideoTopMenu>
        <VideoBothSidesMenu
          v-if="!isShowMvSideBar"
          :headerimg="avatar"
          :enabledAudioTrackId="videoPlayer.enabledAudioTrack.id"
          :audioTrackMap="videoPlayer.audioTrackMap"
          :showMvControlGuide="showMvControlGuide"
          @switchAudioTrack="handleSwitchAudioTrack"
          @show-side-bar="handleOpenSideBar"
          @click.self="handleControlVideoPlayerTopMenu"
        />
      </VideoControlLayer>
      <!-- 先禁掉 -->
      <!-- <IrcList
        @onIrcClick="handleControlVideoPlayerTopMenu"
        v-show="!checkMvOrIrc"
        :ircData="ircListData"
        :currT="currIrcIndex"
        :paused="videoPaused"
      /> -->
      <SongInfoBar />
      <MvControlGuide
        v-show="!isShowMvSideBar && showMvControlGuide"
        @closeControlGuide="handleCloseControlGuide"
        @show-side-bar="handleOpenSideBar"
      />
    </div>
    <MobileOrderModal
      v-if="!mvIsHide && !isShowVideoPlayerPlugins && !isShowMvSideBar"
    />
    <MvQualitySettingModal
      v-if="isShowSettingQualityModal"
      @close="handleCloseMvQualitySettingModal"
    />
    <FloatingLayer
      v-if="!mvIsHide && isShowoperLayer && (!isLogin || !isVipUser)"
      @open="handleOpenoperlayer"
      @close="handleCloseLayer"
    />
    <FloatingAI
      v-show="isShowVideoPlayerPlugins && !mvIsHide"
      from="mv"
      :songItem="videoPlayer.songItem"
    />
    <div v-if="mvIsHide" class="mv-bar">
      <span>
        <div @click.stop="handleVideoControlNext" class="mv-bar-btn">
          <img
            src="https://qncweb.ktvsky.com/20241204/vadd/4c3b8555703eee19df691e29d48667c3.png"
            class="icon"
          />
        </div>
        <div
          v-if="videoPaused"
          @click.stop="handleVideoControlPlay"
          class="mv-bar-btn"
        >
          <img
            src="https://qncweb.ktvsky.com/20241204/vadd/aa049b2a5a33950c58d554aab90f002c.png"
            class="icon"
          />
        </div>
        <div v-else @click.stop="handleVideoControlPause" class="mv-bar-btn">
          <img
            src="https://qncweb.ktvsky.com/20241204/vadd/a36c628aa9f4d6ad07034de03612848f.png"
            class="icon"
          />
        </div>
      </span>
      <div @click.stop="handleMvIsHidden" class="mv-bar-btn">
        <img
          src="https://qncweb.ktvsky.com/20241204/vadd/d805813757caf95b750aa829d8166d19.png"
          class="icon"
        />
      </div>
    </div>

    <MvChat
      v-if="isShowMvChat && !mvIsHide && !mvSlideBarItem"
      :singerid="currSongSingerid"
      :singername="currSongSinger"
      @time-end="handleCloseMvChat"
      @close="handleCloseMvChat"
    />
  </div>
</template>

<script>
import VideoPlayer from '@/components/video-player/index.vue'
import VideoControl from '@/components/video-player/plugins/control.vue'
import VideoControlLayer from '@/components/video-player/plugins/index.vue'
import VideoTopMenu from '@/components/video-player/plugins/menu.vue'
import {
  computed,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import MvBackBtn from '@/components/btn/mv-back.vue'
import FloatingLayer from '@/components/ai/floatingLayer.vue'
import MvQualitySettingModal from '@/components/teleport/mv-quality/index.vue'
import VideoBothSidesMenu from '@/components/video-player/plugins/both-side.vue'
import useAlready from '@/composables/useAlready'
import useUserCollect from '@/composables/useCollect'
import useLoading from '@/composables/useLoading'
import useLoginValid from '@/composables/useLoginValid'
import useM3u8 from '@/composables/useM3u8'
import { NatsCommandRouteMap } from '@/composables/useNats'
import useOrder from '@/composables/useOrder'
import useVip from '@/composables/useVip'
import { sendHLSReport, sendLog, sendSongLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus'
import Toast from '@/utils/toast'
import { format } from 'date-fns'
import get from 'lodash/get'
import store2 from 'store2'
import { Dialog } from 'vant'
import AdjVolume from './components/adj-volume/index.vue'
import MvBackHome from './components/back-home/index.vue'
import ChooseQuality from './components/choose-quality/index.vue'
import MvControlGuide from './components/control-guide/index.vue'
import FastOrderPage from './components/fast-order/index.vue'
import MobileOrderModal from './components/mobile-order/index.vue'
import OrderList from './components/order-list/index.vue'
import MvChat from './components/recommend-chat/index.vue'
import MvSideBar from './components/side-bar/index.vue'
import SongInfoBar from './components/songinfo-bar/index.vue'
import UserVip from './components/vip/index.vue'
// import IrcList from '@/components/irc-list/index.vue'
// import ircList from '@/components/irc-list/data.js'
import useActivity from '@/composables/useActivity'
import { getRecommendSongList } from '@/service/recommendSong'
// import { updateSingTaskTime, getSingTaskVip } from '@/service/singTask'
// import { nanoid } from 'nanoid'
import FloatingAI from '@/components/ai/floating.vue'
import formatStr from '@/constants/index'
import { checkLandscapeOrPortrait } from '@/utils/device'
import {
  getOperationSecondSong,
  getReduceMvRecommendSetting,
} from '@/utils/historyCache.js'
import getComponentLrcData from './utils'
// import MvBackBtn from '@/components/btn/mv-back';
export default {
  name: 'mv',
  components: {
    // MvBackBtn,
    MvBackHome,
    VideoPlayer,
    VideoControlLayer,
    VideoControl,
    VideoTopMenu,
    MobileOrderModal,
    MvSideBar,
    // IrcList,
    MvQualitySettingModal,
    VideoBothSidesMenu,
    OrderList,
    ChooseQuality,
    AdjVolume,
    FastOrderPage,
    UserVip,
    SongInfoBar,
    MvControlGuide,
    FloatingLayer,
    FloatingAI,
    MvChat,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { addSong: addOrderSong, playNext, orderedListNumber } = useOrder()
    const { addSong: addAlreadySong, alreadyList } = useAlready()
    const { addCurrSongM3u8, setCurrSongToken } = useM3u8()
    const { showLoading, hideLoading } = useLoading()
    const { isLogin, checkUserLoginStatus, showLoginQrcode } = useLoginValid()
    const { showVipQrcode, isVipUser } = useVip()
    const { showActivityRecommendSong } = useActivity()
    const $effectAdjustModal = inject('$effectAdjustModal')
    const $mobileOrderQrcodeModal = inject('$mobileOrderQrcodeModal')
    const { showUserCollectModal } = useUserCollect()
    const isSingStatus = computed(() => store.state.isSingStatus)
    const playingMvQuality = computed(() => store.state.playingMvQuality)
    let isShowSettingQualityModal = ref(false)
    const showMvControlGuide = ref(false)

    let mobileOrderQrcodeModal = null
    let videoPlayerHlsInstance = null
    let isShowVideoPlayerPlugins = ref(true)
    let isShowVideoPlayerPluginsTimer = ref(0)
    let videoPaused = computed(() => store.state.videoPaused)
    let isCanPlay = ref(false)
    let startPosition = ref(0)
    let initAutoPlay = ref(true)

    let checkMvOrIrc = ref(true) // 显示mv或者歌词列表
    const currIrcIndex = computed(() => store.state.currIrcIndex)
    // let ircListData = ircList
    const ircListData = computed(() => {
      return getComponentLrcData(videoPlayer.value.songItemLrc)
    })
    let songStart = format(Date.now(), formatStr)

    let isLandscape = ref(true)

    let isShowMvSideBar = ref(0) // 是否显示mv侧边栏 0：不显示 1：右侧显示 2：左侧显示 3: 底部显示
    let mvSlideBarItem = ref('') // 侧边栏组件名称

    let isShowMvChat = ref(false)

    const limitRouteName = [
      'home',
      // 'search',
      // 'singer',
      // 'songList',
      // 'mine',
      // 'profile',
    ]
    const isShowMiniByRouteName = computed(() => {
      // console.log('route.name', route.name)
      return limitRouteName.includes(route.name)
    })

    const handleChangeMvSideBar = (val) => {
      isShowMvSideBar.value = val ? val : 0
    }

    const isShowoperLayer = ref(false)
    const userType = computed(() => store.state.userInfo.userType)
    const freeVipNumber = computed(() => Number(store.state.freeVipNumber))
    const handleOpenoperlayer = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30195,
        event_data: {
          str1: '欢唱页',
          str2: '画面区',
          str3: '顶部运营位点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        },
      })
      handleOpenSideBar({ name: '我的特权', pos: 'left' })
    }

    const handleCloseLayer = () => {
      isShowoperLayer.value = false
    }

    /**
     * name - 侧边栏显示的组件 - 快速点歌/调音/气氛/已点/画质
     * pos - 显示的侧边栏位置 - left or right
     */
    const handleOpenSideBar = ({ name, pos }) => {
      // if (name === '快速点歌') {
      //   Toast('此功能还在开发中')
      //   return
      // }
      if (isShowMvChat.value) handleCloseMvChat()
      console.log({ name, pos })
      const posData = {
        left: 2,
        right: 1,
        bottom: 3,
      }
      handleChangeMvSideBar(posData[pos])
      mvSlideBarItem.value = name
      isShowVideoPlayerPlugins.value = false
    }

    const handleCloseMvSideBar = () => {
      if (mvSlideBarItem.value === '快速点歌') {
        sendLog({
          event_type: '10000~50000',
          event_name: 10089,
          event_data: {
            str1: '搜索',
            str2: '关闭',
            str3: '点击关闭',
            str4: 'click',
          },
        })
      }
      handleChangeMvSideBar(0)
      mvSlideBarItem.value = ''
    }

    const orderPosition = computed(() => route.params.position) //在当前mv页选择已选列表时不会触发
    const vedioPlayerRecovery = computed(
      () => store.state.videoPlayerHistory.recovery
    )
    const videoPlayer = computed(() => store.state.videoPlayerHistory)
    // 临时demo入口
    const currSongName = computed(
      () => store.state.orderedList[0]?.music_name || false
    )
    const currSongid = computed(() => store.state.orderedList[0]?.songid || '')
    const currSongSingerid = computed(
      () => store.state.orderedList[0]?.singerid || ''
    )
    const currSongSinger = computed(
      () => store.state.orderedList[0]?.singer || ''
    )
    const currSongIsVip = computed(
      () => store.state.orderedList[0]?.is_vip || false
    )
    const nextSongIsVip = computed(
      () => store.state.orderedList[1]?.is_vip || false
    )
    const nextSongIsSame = computed(
      () =>
        store.state.videoPlayerHistory.songItem.songid ===
        store.state.orderedList[1]?.songid
    )
    const nextSongIsAIMV = computed(
      () => store.state.orderedList[1]?.isAIMV || false
    )
    const videoVolume = computed(() => store.state.videoVolume)
    const setting = computed(() => store.state.setting)

    // const isShowSingTaskEnter = computed(() => store.state.singTime.isShowSingTaskEnter)
    // const singTaskTime = computed(() => store.state.singTime.singTaskTime)
    const unionid = computed(() => store.state.userInfo.unionid)

    const avatar = computed(() => store.state.userInfo.avatar)

    const orderListCutSongTag = computed(
      () => store.state.songLog.orderListCutSongTag
    )

    const mvIsHide = computed(() => store.state.mvIsHide)

    // 0元欢唱活动 - 当日是否可签到
    const isCanZerobuyTaskSignin = computed(() => {
      let isJoinZerobuyTask = store.state.zerobuy.zerobuyStatus == 1
      // 未参与0元购活动不签到
      if (!isJoinZerobuyTask) return false
      let remaining_days = Number(store.state.zerobuy.remaining_days)
      // 超出30天的打卡了不签到
      if (remaining_days < 0) return false
      let signinCalendar = store.state.zerobuy.signinCalendar
      // 当天签到状态
      let zerobuyTaskIsSign =
        get(signinCalendar, `${30 - remaining_days - 1}.status`) === '3'
      return zerobuyTaskIsSign
    })

    // onBeforeRouteLeave 位置要靠前
    const recordLastSongCurrentTime = () => {
      if (videoPlayerHlsInstance && videoPlayerHlsInstance.media) {
        console.log(
          'record last song currentTime:',
          videoPlayerHlsInstance.media.currentTime
        )
        store.commit(
          'SAVE_VIDEO_PLAYER_HISTORY_CURRENT_TIME',
          videoPlayerHlsInstance.media.currentTime
        )
      }
    }

    const handleBack = () => {
      recordLastSongCurrentTime()
    }

    const onEffectAdjustTunerVolumeChange = (val) => {
      if (videoPlayerHlsInstance && videoPlayerHlsInstance.media) {
        videoPlayerHlsInstance.media.volume = (val / 100).toFixed(1)
      }
    }

    const handleNatsPlayerControl = (payload) => {
      store.commit('UPDATE_CONTROL_FROM_TYPE', 3)
      mobileOrderQrcodeModal && mobileOrderQrcodeModal.hide()
      switch (payload.name) {
        case NatsCommandRouteMap.ORDER_NEXT:
          handleVideoControlNext()
          break
        case NatsCommandRouteMap.ORDER_REPLAY:
          handleVideoControlReplay(3)
          break
        case NatsCommandRouteMap.ORDER_PLAY_TOGGLE:
          if (videoPaused.value) {
            handleVideoControlPlay(3)
          } else {
            handleVideoControlPause(3)
          }
          break
        case NatsCommandRouteMap.ORDER_AUDIO_TRACK_TOGGLE:
          handleSwitchAudioTrack()
          break
        default:
          break
      }
    }

    onBeforeRouteLeave(() => {
      console.log('mv back')
      handleBack()
      hideLoading()
      eventBus.off(
        'effect-adjust-tuner-volume-change',
        onEffectAdjustTunerVolumeChange
      )
      eventBus.off('nats-player-control', handleNatsPlayerControl)
    })
    // onBeforeRouteLeave 位置要靠前

    onMounted(() => {
      eventBus.on('nats-player-control', handleNatsPlayerControl)
      eventBus.on(
        'effect-adjust-tuner-volume-change',
        onEffectAdjustTunerVolumeChange
      )
      if (!videoPlayer.value.songItem.songid) {
        store.commit('UPDATE_MV_ISHIDE', true)
        store.commit('UPDATE_MV_VIDEO_PAUSE', true)
        return
      }
      //used：挂载时先判断是否可播放
      //current：初始化无需播放 这块可以暂时注释掉
      // if ((!isLogin.value || !isVipUser.value) && currSongIsVip.value) {
      //   initAutoPlay.value = false
      //   store.commit('UPDATE_MV_VIDEO_PAUSE', true)
      // }
    })

    onMounted(() => {
      if (orderPosition.value === 'recovery') {
        startPosition.value = videoPlayer.value.currentTime
      }
    })

    const handleInitIrcIndex = () => {
      store.commit('UPDATE_CURR_IYRIC_INDEX', -1)
    }

    const checkAccIsFirst = (accAudioTrackOrder) => {
      // TODO 后端那边可能返回数字类型或字符串类型，历史原因，暂时兼容下
      if (accAudioTrackOrder != 1) {
        console.log('audio tracks adapter revese: switched')
        // acc非第一轨情况 需要切下轨道 到伴唱轨道
        store.commit(
          'SAVE_VIDEO_PLAYER_HISTORY_DEFAULT_ACC_TRACK_ORDER',
          accAudioTrackOrder
        )
      }
    }

    // const showVipQrcodeModal = () => {
    //   if(freeVipNumber.value == 0){
    //     showVipQrcode()
    //   }
    // }

    // 以接口为准
    // org=1 表示 第一轨是原唱 acc=2 表示 第二轨是伴唱
    const audioTracksAdapter = (accAudioTrackOrder, audioTracks) => {
      console.log('audio tracks adapter acc order: ', accAudioTrackOrder)
      console.log('audio tracks adapter before: ', audioTracks)
      const audioTrackMap = {
        acc: {},
        org: {},
      }

      //  audio error default org
      if (audioTracks.length === 0) {
        store.commit('SAVE_VIDEO_PLAYER_HISTORY_ENABLED_AUDIO_TRACK', {
          id: 0,
        })
        return {
          acc: {
            id: 1,
          },
          org: {
            id: 0,
          },
        }
      }

      const tempAudioTracks = [...audioTracks]
      audioTrackMap.acc = tempAudioTracks.splice(accAudioTrackOrder - 1, 1)[0]
      audioTrackMap.org = tempAudioTracks[0]
      console.log('audio tracks adapter after: ', audioTrackMap)
      return audioTrackMap
    }

    const handleOnManifestParsed = () => {
      isCanPlay.value = false
    }
    const handleOnManifestLoaded = ({ audioTracks }) => {
      store.commit(
        'SAVE_VIDEO_PLAYER_HISTORY_AUDIO_TRACKS',
        audioTracksAdapter(videoPlayer.value.songItem.acc || 2, audioTracks)
      )
      checkAccIsFirst(videoPlayer.value.songItem.acc)
    }

    const handleOnInitPtsFound = () => {
      // handleControlVideoPlayerTopMenu()
    }
    const handleOnAudioTracksSwitched = (audioTrackId) => {
      if (!isCanPlay.value) return
      store.commit('SAVE_VIDEO_PLAYER_HISTORY_ENABLED_AUDIO_TRACK', {
        id: audioTrackId,
      })
    }

    const handleOnFragLoading = (data) => {
      sendLog({
        event_type: 'custom',
        event_name: 251,
        event_data: {
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
          key_words: get(data, 'frag.url', ''),
        },
      })
    }

    const handleOnFragLoaded = (data) => {
      const cost_time = parseFloat(
        get(data, 'frag.stats.loading.end', 0) -
          get(data, 'frag.stats.loading.start', 0)
      ).toFixed(3)
      sendLog({
        event_type: 'custom',
        event_name: 252,
        event_data: {
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
          key_words: get(data, 'frag.url', ''),
          cost_time,
        },
      })
    }

    const handleOnFragLoadEmergencyAborted = (data) => {
      sendLog({
        event_type: 'custom',
        event_name: 253,
        event_data: {
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
          key_words: get(data, 'frag.url', ''),
        },
      })
    }

    const handleCanPlay = async () => {
      isCanPlay.value = true
      //根据历史当前歌曲原伴唱加载
      let currAudioTrackType =
        videoPlayer.value.enabledAudioTrack.id === 1 ? 'acc' : 'org'

      handleSwitchAudioTrack(
        videoPlayer.value.audioTrackMap[currAudioTrackType]
      )
      // isShowVideoPlayerPlugins.value = true
      //初始化设置默认历史音量
      if (videoVolume.value) {
        onEffectAdjustTunerVolumeChange(videoVolume.value)
      }
    }

    const handleSwitchAudioTrack = (audioTrack) => {
      let audioTrackPayload = audioTrack
      if (!audioTrackPayload) {
        const currAudioTrackType =
          videoPlayer.value.enabledAudioTrack.id === 1 ? 'org' : 'acc'
        audioTrackPayload = videoPlayer.value.audioTrackMap[currAudioTrackType]
      }
      eventBus.emit('switch-audio-track', audioTrackPayload)
      sendLog({
        event_type: '10000~50000',
        event_name: 10049,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: '原伴唱',
          str4: 'click',
        },
      })
    }
    // "点歌类型：1：点歌屏操作，2：按键操作，3：扫描点歌, 4: 首页会员优化(特斯拉)
    // TODO 临时先这么处理type 不太准确: 状态类型的上报比如 自然的播放结束 拿不到真实type
    const handleVideoControlPlay = async (type = 1) => {
      if (!validSongVip()) {
        return
      }
      // 重置免费唱次数
      setTimeout(() => {
        freeVip()
      }, 2000)

      console.log('play')
      sendLog({
        event_type: 'click',
        event_name: 102,
        event_data: {
          type,
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
        },
      })
      // //重播和播放时检查当前登录状态
      // if ((!isLogin.value || !isVipUser.value) && currSongIsVip.value && !freeVipNumber.value) {
      //   showVipQrcodeModal()
      //   return
      // }
      eventBus.emit('video-control-play')
    }
    const handleVideoControlPause = (type = 1) => {
      sendLog({
        event_type: 'click',
        event_name: 103,
        event_data: {
          type,
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
        },
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 10056,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: '暂停播放',
          str4: 'click',
        },
      })
      console.log('pause')
      eventBus.emit('video-control-pause')
    }
    const freeVip = async () => {
      if (isLogin.value && !isVipUser.value) {
        await store.dispatch('fetchFreeVip')
      } else {
        store.commit('SAVE_FREE_VIP_COUNT', 0)
      }
    }
    const handleVideoControlReplay = async (type = 1) => {
      sendLog({
        event_type: 'click',
        event_name: 104,
        event_data: {
          type,
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
        },
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 10055,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: '重唱',
          str4: 'click',
        },
      })
      console.log('replay')
      //重播和播放时检查当前登录状态
      // if ((!isLogin.value || !isVipUser.value) && currSongIsVip.value && !freeVipNumber.value) {
      //   showVipQrcodeModal()
      //   return
      // }
      eventBus.emit('video-control-replay')
      eventBus.emit('irc-control-replay')
      handleInitIrcIndex()

      // 重置免费唱次数
      setTimeout(() => {
        freeVip()
      }, 1500)
    }
    const handleVideoPlay = () => {
      if (!validSongVip()) {
        return
      }
      store.commit('UPDATE_MV_VIDEO_PAUSE', false)
      // 埋点107
      sendLog({
        event_type: 'custom',
        event_name: 107,
        event_data: {
          type: 1,
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
        },
      })
      sendLog({
        event_type: 'custom',
        event_name: 1070,
        event_data: {
          str1: '歌曲清晰度埋点',
          str2: videoPlayer.value.songItem.songid,
          str3: videoPlayer.value.songItem.music_name,
          str4: videoPlayer.value.songItem.singer,
          str5: `${playingMvQuality.value}P`, // 画质
          str6: '1', // 上报时机
        },
      })
      // 参与0元购（并且当天还未签到）演唱自动签到
      if (isLogin.value && isCanZerobuyTaskSignin.value) {
        store.dispatch('zerobuy/zerobuySignIn', unionid.value)
      }
    }
    const handleVideoPause = () => {
      store.commit('UPDATE_MV_VIDEO_PAUSE', true)
    }
    const handleControlVideoPlayerTopMenu = () => {
      console.log('handleControlVideoPlayerTopMenu')
      sendLog({
        event_type: '10000~50000',
        event_name: isShowVideoPlayerPlugins.value ? 10048 : 10047,
        event_data: {
          str1: 'MV',
          str2: '画面区',
          str3: isShowVideoPlayerPlugins.value ? '收回播控' : '唤起播控',
          str4: 'click',
        },
      })
      isShowVideoPlayerPlugins.value = !isShowVideoPlayerPlugins.value
    }

    const openRecommendSongModal = async () => {
      //已点无歌
      if (orderedListNumber.value > 1) return false
      // 每日一次 可配置7天不显示
      const isFirst = get(store2('recommendSongRule'), 'showTime', '')
      const currDate = format(Date.now(), 'yyyy-MM-dd')
      if (isFirst === currDate) return false
      const dateNow = new Date()
      const noShowRule = new Date(
        get(store2('recommendSongRule'), 'noShowTime', '2023-06-28')
      )
      if (dateNow < noShowRule) return false

      // 无开屏歌单时不显示
      let songList = await getRecommendSongList()
      if (!songList.length) return false

      sendLog({
        event_type: 'show',
        event_name: 3001,
        event_data: {
          str1: '2',
        },
      })
      showActivityRecommendSong({ songList })
      return true
    }

    const showoperLayerLogic = () => {
      if (isShowoperLayer.value) {
        isShowoperLayer.value = false
      } else {
        if (getOperationSecondSong()) {
          isShowoperLayer.value = true
          if (!isLogin.value || !isVipUser.value) {
            sendLog({
              event_type: '10000~50000',
              event_name: 30194,
              event_data: {
                str1: '欢唱页',
                str2: '画面区',
                str3: '顶部运营位展示',
                str4: 'show',
              },
            })
          }
        }
      }
    }

    const handleVideoControlNext = async (type = 1) => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10057,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: '下一首',
          str4: 'click',
        },
      })

      if (videoPlayerHlsInstance && videoPlayerHlsInstance.media) {
        sendSongLog({
          end_type: 2,
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
          start_time: songStart, // 格式："2020-07-27 14:02:20"
          end_time: format(Date.now(), formatStr),
          play_time: Math.round(videoPlayerHlsInstance.media.currentTime),
        })
        songStart = format(Date.now(), formatStr)
      }

      // TODO 这个位置设计的不好
      // 切歌前校验 没有播放vip权限依然切歌到下一首但不播放
      if (
        (!isLogin.value || !isVipUser.value) &&
        nextSongIsVip.value &&
        !freeVipNumber.value
      ) {
        initAutoPlay.value = false
        store.commit('UPDATE_MV_VIDEO_PAUSE', true)
        showVipQrcode({
          fromType: 'vip-expired',
          fr: 1873,
        })
      } else {
        if (!initAutoPlay.value) {
          initAutoPlay.value = true
          store.commit('UPDATE_MV_VIDEO_PAUSE', false)
        }
      }
      // VEC-872-begin
      store.commit('RESET_VIDEO_PLAYER_HLS')
      // 切歌时歌词内容先置空再操作
      store.commit('RESET_CURR_SONG_LRC')
      startPosition.value = 0
      await nextTick()
      // VEC-872-end
      addAlreadySong(videoPlayer.value.songItem)
      playNext(
        videoPlayer.value.songItem,
        () => {
          store.commit('RESET_VIDEO_PLAYER')
          Toast('暂无更多已点歌曲')
          // 切歌无歌后 重置初入mv页标识
          // store.commit('UPDATE_MV_INIT_AUTOPLAY', 0)
          setTimeout(() => {
            store.commit('UPDATE_MV_ISHIDE', true)
            store.commit('UPDATE_MV_VIDEO_PAUSE', true)
          }, 2000)
        },
        type
      )
      handleInitIrcIndex()
      // 重置免费唱次数
      setTimeout(() => {
        freeVip()
      }, 1500)
    }
    const handleVideoVolume = () => {
      // console.log(context);
      Toast('此功能暂未完成移植')
    }
    const handleEffectAdjust = (name) => {
      $effectAdjustModal.show({ name })
      sendLog({
        event_type: '10000~50000',
        event_name: name === '调音' ? 10050 : 10051,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: name === '调音' ? '打开调音' : '进入气氛',
          str4: 'click',
        },
      })
    }
    const handleShowOrdered = () => {
      eventBus.emit('show-order-song-control-popup')
      sendLog({
        event_type: '10000~50000',
        event_name: 10052,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: '进入已点',
          str4: 'click',
        },
      })
    }
    const handleShowMobileOrderQrcode = () => {
      // if (checkUserLoginStatus()) {
      mobileOrderQrcodeModal = $mobileOrderQrcodeModal.show()
      setTimeout(() => {
        mobileOrderQrcodeModal && mobileOrderQrcodeModal.hide()
      }, 8 * 1000)
      // }
      sendLog({
        event_type: '10000~50000',
        event_name: 10054,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: '手机',
          str4: 'click',
        },
      })
    }
    const handleVideoEnded = async () => {
      if (!validNextSong()) return
      showoperLayerLogic()
      sendLog({
        event_type: 'custom',
        event_name: 101,
        event_data: {
          type: 1,
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
        },
      })

      if (videoPlayerHlsInstance && videoPlayerHlsInstance.media) {
        sendSongLog({
          end_type: 1,
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
          start_time: songStart, // 格式："2020-07-27 14:02:20"
          end_time: format(Date.now(), formatStr),
          play_time: Math.round(videoPlayerHlsInstance.media.duration),
        })
        songStart = format(Date.now(), formatStr)
      }

      // 去掉这个需求
      // if (orderedListNumber.value === 1) {
      //   store.commit('UPDATE_MV_VIDEO_PAUSE', true)
      //   const isOpen = await openRecommendSongModal()
      //   if (isOpen) return
      // }

      // TODO 这个位置设计的不好
      // 播放结束校验 没有播放vip权限依然切歌到下一首但不播放
      if (
        (!isLogin.value || !isVipUser.value) &&
        nextSongIsVip.value &&
        !freeVipNumber.value
      ) {
        initAutoPlay.value = false
        store.commit('UPDATE_MV_VIDEO_PAUSE', true)
        // return
      } else {
        if (!initAutoPlay.value) {
          initAutoPlay.value = true
          store.commit('UPDATE_MV_VIDEO_PAUSE', false)
        }
      }
      // VEC-874
      if (orderedListNumber.value !== 1) {
        if (nextSongIsSame.value || nextSongIsAIMV.value) {
          store.commit('RESET_VIDEO_PLAYER_HLS')
          // 切歌时歌词内容先置空再操作
          store.commit('RESET_CURR_SONG_LRC')
          await nextTick()
        }
        addAlreadySong(videoPlayer.value.songItem)
        handleInitIrcIndex()
      }
      playNext(videoPlayer.value.songItem, () => {
        addOrderSong(videoPlayer.value.songItem)
        handleVideoControlReplay()
      })

      // 参与0元购（并且当天还未签到）演唱自动签到
      console.log('ended - 参与0元购（并且当天还未签到）演唱自动签到')
      if (isLogin.value && isCanZerobuyTaskSignin.value) {
        store.dispatch('zerobuy/zerobuySignIn', unionid.value)
      }
    }

    /**
     * error 失败详情
     * type 失败类型
     */
    const handleVideoError = (error, type = '') => {
      console.log(error)
      let errorType = get(error, 'type', 'unknown')
      let extraEventData = {}
      if (type) {
        errorType = `${type}_${errorType}`
        extraEventData = {
          song_id: videoPlayer.value.songItem.songid,
          song_name: videoPlayer.value.songItem.music_name,
          singer: videoPlayer.value.songItem.singer,
          route_page: router.currentRoute.value.href,
          route_name: router.currentRoute.value.name,
          msg: get(error, 'details', ''),
          key_words: videoPlayer.value.songItem.token, // token
          status: '403',
          event_name: 254,
        }
      }
      // 播放错误埋点
      sendHLSReport({
        sid: videoPlayer.value.songItem.songid,
        scale: setting.value.quality,
        error_msg: get(error, 'details', 'unknown'),
        error_type: errorType,
      })
      sendLog({
        event_type: 'custom',
        event_name: 253,
        event_data: {
          key_words: get(error, 'frag.url', ''),
          event_val: `${get(error, 'type', '')}-${get(error, 'details', '')}`,
          ...extraEventData,
        },
      })
    }

    const handleUnrecover = (error) => {
      hideLoading()
      Dialog.confirm({
        className: 'global-force-login',
        confirmButtonText: '我知道了',
        showCancelButton: false,
        message: '视频加载失败，请退出后重试', // 歌曲下载出现了点问题，请重新加载
      }).then(() => {
        store.commit('UPDATE_MV_ISHIDE', true)
        store.commit('UPDATE_MV_VIDEO_PAUSE', true)
      })
      // 播放错误埋点
      sendHLSReport({
        sid: videoPlayer.value.songItem.songid,
        scale: setting.value.quality || '-',
        error_msg: get(error, 'details', '-'),
        error_type: `Unrecover_${get(error, 'type', '-')}`,
      })
    }

    const handleRetryToken = (error) => {
      console.log('retry token')
      const ts = setCurrSongToken()
      // ts > 0 时上报
      if (ts) {
        handleVideoError(error, '403')
      }
    }

    const handleVideoPlayerInit = (hls) => {
      videoPlayerHlsInstance = hls
    }

    const handleCheckMvOrIrc = () => {
      // 可播放后查看是显示mv还是irc
      // 目前demo入口判断依据 - 当前播放歌曲为稻香

      // return currSongName.value !== '稻香'

      // 暂时去掉歌词demo
      return true
    }

    let currentPlayTime = ref(-1)
    let modeSwitchClickTag = false // 模式切换副作用标识

    /**
     * @description  比较两数值绝对值误差
     * @param n1,n2  比较的数值
     * @param p      精确到的小数点位数(整数传1 小数点后一位传10 后两位传100 ...)
     * @param max    可允许的最大误差值
     * @return bool
     */
    const absCompare = (n1, n2, p, max) => {
      return Math.abs(Math.floor(n1 * p) - Math.floor(n2 * p)) <= max * p
    }
    const easyCompare = (n1, n2, p) => {
      return Math.floor(n1 * p) < Math.floor(n2 * p)
    }
    // mv、歌词播放时间同步
    const ircTimeCompare = (t, func) => {
      // 释放歌曲首次时间同步
      if (t < 0.3 || t <= ircListData.value[0].t) {
        currentPlayTime.value = t
      }
      // 更新歌词位置状态
      ircListData.value.find((v, i, arr) => {
        let compareRes = false
        if (typeof func === 'function') {
          compareRes = func(v.t, t, 10, 0.2) // 临近值实时对比
        } else {
          compareRes =
            easyCompare(v.t, t, 10) && easyCompare(t, arr[i + 1].t, 10) // 区间模糊对比
        }
        if (compareRes) {
          store.commit('UPDATE_CURR_IYRIC_INDEX', i)
          return true
        }
        return false
      })
    }

    const handleTimeupdate = (t) => {
      if (modeSwitchClickTag && ircListData.value.length) {
        // 标识为true时，即时同步歌词状态
        modeSwitchClickTag = false
        ircTimeCompare(t)
        return
      }
      if (!ircListData.value.length) return // isMvMode.value
      ircTimeCompare(t, absCompare)
    }

    watch(isShowVideoPlayerPlugins, (val) => {
      // 开启状态
      if (val) {
        if (isShowVideoPlayerPluginsTimer.value) {
          clearTimeout(isShowVideoPlayerPluginsTimer.value)
        }
        isShowVideoPlayerPluginsTimer.value = setTimeout(() => {
          isShowVideoPlayerPlugins.value = false
        }, 6 * 1000)
      }
    })

    watch(mvIsHide, (val) => {
      if (!val) {
        if (isShowVideoPlayerPlugins.value) {
          if (isShowVideoPlayerPluginsTimer.value) {
            clearTimeout(isShowVideoPlayerPluginsTimer.value)
          }
          isShowVideoPlayerPluginsTimer.value = setTimeout(() => {
            isShowVideoPlayerPlugins.value = false
          }, 6 * 1000)
        }
      }
    })

    watch(
      isCanPlay,
      (val) => {
        if (val) {
          hideLoading()
        } else {
          if (!store.state.mvIsHide) {
            showLoading()
            //处理loading组件引起的黄边问题
            const vueLoadingDom =
              document.getElementsByClassName('vld-overlay')[0]
            if (vueLoadingDom) vueLoadingDom.removeAttribute('tabindex')
          }
        }
        checkMvOrIrc.value = handleCheckMvOrIrc()
      },
      {
        immediate: true,
      }
    )

    watch(vedioPlayerRecovery, (val) => {
      if (val) {
        store.commit('UPDATE_VIDEO_PLAYER_RECOVERY', false)
        if (isSingStatus.value) handleVideoControlReplay()
      }
    })

    // 歌曲播放日志上报 时长统计
    watch(videoPaused, (val) => {
      if (!val) {
        songStart = format(Date.now(), formatStr)
      }
    })

    watch(orderListCutSongTag, (val) => {
      if (val && videoPlayerHlsInstance && videoPlayerHlsInstance.media) {
        // 这里模糊处理 打个时间差 此时时间还是记录的时间 但歌曲已加入已唱
        sendSongLog({
          end_type: 2,
          song_id: get(alreadyList.value, '[0].songid', ''),
          song_name: get(alreadyList.value, '[0].music_name', ''),
          singer: get(alreadyList.value, '[0].singer', ''),
          start_time: songStart,
          end_time: format(Date.now(), formatStr),
          play_time: Math.round(videoPlayerHlsInstance.media.currentTime),
        })
        songStart = format(Date.now(), formatStr)
      }
    })

    // 累计时长统计
    // 断网情况无需处理
    // 未登录情况
    let singTaskRecordTimer = null
    let singTaskReportTimer = null
    // const singNotify = inject('$singNotify')

    // 退出登录取消时长累计
    watch(isLogin, (val) => {
      if (!val) {
        if (singTaskRecordTimer) clearInterval(singTaskRecordTimer)
        if (singTaskReportTimer) clearInterval(singTaskReportTimer)
        singTaskRecordTimer = null
        singTaskReportTimer = null
      } else {
        // 参与0元购（并且当天还未签到）演唱自动签到
        if (!videoPaused.value && isCanZerobuyTaskSignin.value) {
          store.dispatch('zerobuy/zerobuySignIn', unionid.value)
        }
      }
      handleVideoControlPlay()
    })

    // const stopSingTaskTimer = () => {
    //   if (singTaskRecordTimer) clearInterval(singTaskRecordTimer)
    //   if (singTaskReportTimer) clearInterval(singTaskReportTimer)
    //   singTaskRecordTimer = null
    //   singTaskReportTimer = null
    //   updateSingTaskTime({
    //     unionid: unionid.value,
    //     song_time: singTaskTime.value
    //   })
    // }

    // 演唱活动 - 目前活动已下线
    // watch(videoPaused, (val) => {
    //   console.log('bofa:', val, unionid.value)
    //   if (!unionid.value || !isShowSingTaskEnter.value) return
    //   if (val) {
    //     console.log('停止更新')
    //     stopSingTaskTimer()
    //   } else {
    //     // 演唱任务逻辑
    //     // 超过60不再上报
    //     if (singTaskTime.value > 62 * 60) return

    //     // 每1s累计一次
    //     singTaskRecordTimer = setInterval(() => {
    //       console.log('更新')
    //       store.dispatch('singTime/addSingTaskTime')
    //     }, 1 * 1000)
    //     // 每30s上报一次
    //     singTaskReportTimer = setInterval(() => {
    //       console.log('更新')
    //       if (singTaskTime.value > 62 * 60) {
    //         stopSingTaskTimer()
    //         return
    //       }
    //       updateSingTaskTime({
    //         unionid: unionid.value,
    //         song_time: singTaskTime.value
    //       })
    //     }, 30 * 1000)
    //   }
    // })

    // // 演唱活动消息提醒逻辑 - 目前活动已下线
    // watch(singTaskTime, (val) => {
    //   if (!isShowSingTaskEnter.value) return
    //   const canGetSingTaskVip = {
    //     [10 * 60]: 1,
    //     [20 * 60]: 3,
    //     [30 * 60]: 5,
    //     [40 * 60]: 7,
    //     [50 * 60]: 9,
    //     [60 * 60]: 11,
    //   } // 10分钟 ~ 60分钟
    //   if (Object.keys(canGetSingTaskVip).includes(String(val))) {
    //     const reward = canGetSingTaskVip[val]
    //     // 检测到本地时长达到领取条件时，延时32s通知
    //     setTimeout(() => {
    //       singNotify.show({
    //         title: `您已经演唱${Number(val) / 60}分钟，可领取${reward}h VIP`,
    //         buttonText: '去领取',
    //         duration: 30 * 1000,
    //         onSubmit: async () => {
    //           // 需求调整为前往演唱任务活动页
    //           router.push({
    //             name: 'singTask'
    //           })
    //           store.commit('UPDATE_MV_ISHIDE', true)
    //         }
    //       })
    //     }, 32 * 1000)
    //   }
    // })

    const handleShowUserCollect = () => {
      showUserCollectModal()
    }

    const handleShowSettingQuality = () => {
      isShowSettingQualityModal.value = true
      sendLog({
        event_type: '10000~50000',
        event_name: 10053,
        event_data: {
          str1: 'MV',
          str2: '播控',
          str3: '画质选择',
          str4: 'click',
        },
      })
    }

    const handleCloseMvQualitySettingModal = () => {
      isShowSettingQualityModal.value = false
    }

    const handleCloseMvPage = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10058,
        event_data: {
          str1: 'MV',
          str2: '返回',
          str3: '点击返回',
          str4: 'click',
        },
      })
    }

    // 横板屏幕显示新版mv功能界面，老版屏幕还显示旧版
    const getBrowserSize = () => {
      isLandscape.value = checkLandscapeOrPortrait() === 'landscape'
      if (!isLandscape.value) {
        handleCloseMvSideBar()
      }
    }

    const handleCloseControlGuide = () => {
      showMvControlGuide.value = false
      store2.set('showMvControlGuide', 1)
    }

    onMounted(() => {
      eventBus.on('handle-video-play', handleVideoControlPlay)
      eventBus.on('handle-video-pause', handleVideoControlPause)
      eventBus.on('handle-video-next', handleVideoControlNext)
      eventBus.on('handle-video-operlayer', showoperLayerLogic)
      if (window) window.addEventListener('resize', getBrowserSize)
      getBrowserSize()
      showMvControlGuide.value = !store2('showMvControlGuide')
    })

    onUnmounted(() => {
      eventBus.off('handle-video-play', handleVideoControlPlay)
      eventBus.on('handle-video-pause', handleVideoControlPause)
      eventBus.on('handle-video-next', handleVideoControlNext)
      if (window) window.removeEventListener('resize', getBrowserSize)
    })

    const handleMvIsHidden = () => {
      store.commit('UPDATE_MV_ISHIDE', !store.state.mvIsHide)
      // console.log("handleMvIsHidden")
      sendLog({
        event_type: '10000~50000',
        event_name: 30240,
        event_data: {
          str1: '首页',
          str2: '全屏',
          str3: '点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
        },
      })
    }

    //验证是否vip会员
    const validSongVip = () => {
      if (videoPlayer.value.songItem.is_vip) {
        if (isLogin.value) {
          if (!isVipUser.value && !freeVipNumber.value) {
            handleVideoControlPause()
            showVipQrcode({
              fromType: 'vip-expired',
              isLogin: true,
              fr: 1873,
            })
            return false
          } else {
            return true
          }
        } else {
          // handleVideoControlPause();
          // showLoginQrcode();
          return true
        }
      } else {
        return true
      }
    }
    //验证下一首是否vip
    const validNextSong = () => {
      const orderedList = store.state.orderedList,
        playingSong = store.state.videoPlayerHistory.songItem
      const playingSongIndex = orderedList.findIndex(
        (item) => playingSong.songid === item.songid
      )
      const nextSong = orderedList[playingSongIndex + 1]
      if (nextSong) {
        if (nextSong.is_vip && !freeVipNumber.value && !isVipUser.value) {
          console.log('validNextSong==', orderedList, nextSong)
          showVipQrcode({
            onHide: () => {
              if (isLogin.value && isVipUser.value) {
                handleVideoControlNext()
              } else {
                store.commit('SAVE_VIDEO_PLAYER_HISTORY_SONG_ITEM', nextSong)
                store.commit('SHIFT_SONG_ORDERED_LIST')
                handleVideoControlNext()
              }
            },
          })
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }

    let delayShowMvChatTimer = null
    const handleCloseMvChat = () => {
      isShowMvChat.value = false
    }

    const handleShowMvChat = () => {
      isShowMvChat.value = true
    }

    const delayShowMvChat = () => {
      if (delayShowMvChatTimer) {
        clearTimeout(delayShowMvChatTimer)
      }
      delayShowMvChatTimer = setTimeout(() => {
        handleShowMvChat()
      }, 30 * 1000) // 120 * 1000
    }

    // 逻辑变更
    // let differentSingerNumber = 0;

    // watch(currSongSingerid, (val) => {
    //   if (val) {
    //     const recommendSetting = getReduceMvRecommendSetting();
    //     console.log('currSongSingerid recommendSetting', recommendSetting);
    //     if (recommendSetting == 1) {
    //       // delayShowMvChat();
    //     } else if (recommendSetting == 2) {
    //       ++differentSingerNumber;
    //       if (differentSingerNumber % 3 === 0) {
    //         delayShowMvChat();
    //       }
    //     } else {
    //       if (delayShowMvChatTimer) {
    //         clearTimeout(delayShowMvChatTimer);
    //       }
    //     }
    //   }
    // });

    // watch(currSongid, (val) => {
    //   console.log('recommendSetting', val);
    //   if (val) {
    //     const recommendSetting = getReduceMvRecommendSetting();
    //     console.log('currSongid recommendSetting', recommendSetting);
    //     const isSameSinger = currSongSingerid.value === alreadyList.value[0].singerid;
    //     if (recommendSetting == 1 && isSameSinger) {
    //       delayShowMvChat();
    //     }
    //   }
    // });

    let differentSongNumber = 0

    watch(currSongid, (val) => {
      console.log('recommendSetting', val)
      if (val) {
        const recommendSetting = getReduceMvRecommendSetting()
        console.log('currSongid recommendSetting', recommendSetting)
        // const isSameSinger = currSongSingerid.value === alreadyList.value[0].singerid;
        if (recommendSetting == 1) {
          delayShowMvChat()
        } else if (recommendSetting == 2) {
          ++differentSongNumber
          if (differentSongNumber % 2 === 0) {
            delayShowMvChat()
          }
        } else {
          if (delayShowMvChatTimer) {
            clearTimeout(delayShowMvChatTimer)
          }
        }
      }
    })

    const handleVideoClick = () => {
      if (mvIsHide.value) {
        // sendLog({
        //   event_type: "10000~50000",
        //   event_name: 30280,
        //   event_data: {
        //     str1: "小屏mv",
        //     str2: "小屏mv",
        //     str3: "点击",
        //     str4: "click",
        //   },
        // });
        handleMvIsHidden()
      } else {
        handleControlVideoPlayerTopMenu()
      }
    }

    return {
      avatar,
      isLogin,
      isVipUser,
      isShowoperLayer,
      currSongSingerid,
      currSongSinger,
      handleOpenoperlayer,
      handleCloseLayer,
      ircListData,
      currIrcIndex,
      checkMvOrIrc,
      isShowMvSideBar,
      mvSlideBarItem,
      isShowVideoPlayerPluginsTimer,
      isShowVideoPlayerPlugins,
      isShowSettingQualityModal,
      initAutoPlay,
      videoPaused,
      videoPlayer,
      startPosition,
      currSongIsVip,
      nextSongIsVip,
      isShowMvChat,
      isShowMiniByRouteName,
      handleOnManifestParsed,
      handleOnManifestLoaded,
      handleSwitchAudioTrack,
      handleOnAudioTracksSwitched,
      handleVideoControlPlay,
      handleVideoControlPause,
      handleVideoPlay,
      handleVideoPause,
      handleTimeupdate,
      handleVideoControlReplay,
      handleControlVideoPlayerTopMenu,
      handleVideoControlNext,
      handleVideoVolume,
      handleShowOrdered,
      handleShowMobileOrderQrcode,
      handleVideoEnded,
      handleOnInitPtsFound,
      handleVideoError,
      handleRetryToken,
      handleVideoPlayerInit,
      handleCanPlay,
      handleUnrecover,
      handleEffectAdjust,
      handleOnFragLoading,
      handleOnFragLoaded,
      handleOnFragLoadEmergencyAborted,
      handleShowUserCollect,
      handleShowSettingQuality,
      handleCloseMvQualitySettingModal,
      handleCloseMvPage,
      handleChangeMvSideBar,
      handleOpenSideBar,
      handleCloseMvSideBar,
      showMvControlGuide,
      handleCloseControlGuide,
      mvIsHide,
      handleMvIsHidden,
      handleCloseMvChat,
      handleVideoClick,
    }
  },
}
</script>

<style lang="stylus" scoped>
.mv-mini
  width 715px
  height 420px
  position relative
  border-radius var(--border-radius-large)
  margin-bottom 31px
  overflow hidden
  z-index auto
  background #000000

  @media screen and (max-width 1200px) and (min-height 1200px)
    width 644px !important
    height 378px !important
    margin-bottom 0px
    ::v-deep .video-player
      video
        width 100% !important
        height 100% !important
        top 0px !important

.mv-page
  background transparent
  position fixed
  height 100vh
  width 100vw
  display flex
  padding 0 !important
  z-index 100
  top 0
  left 0
  &.page
    background transparent
.mv-bar
  position absolute
  display flex
  top 0
  left 0
  padding 24px
  justify-content space-between
  width 100%
  z-index 6
  span
    display flex
  .mv-bar-btn
    width 88px
    height 64px
    background rgba(31, 31, 32, 0.2)
    border-radius 20px
    border 1px solid rgba(255, 255, 255, 0.15)
    backdrop-filter blur(30px)
    display flex
    align-items center
    justify-content center

    @media screen and (max-width 1200px) and (min-height 1200px)
      width 72px
      height 51px

    &+.mv-bar-btn
      margin-left 12px
    .icon
      width 36px
      height 36px
      object-fit scale-down

      @media screen and (max-width 1200px) and (min-height 1200px)
        width 32px
        height 32px

.mv-mini-hidden
  display none
</style>
