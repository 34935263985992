<template>
  <div class="fast-search" :class="[isSearch && 'pos-ab']">
    <div class="fast-search-main" :class="[isSearch && 'width_480']" @click="handleToSearch">
      <div class="icon">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.5" d="M31.5225 29.2442L25.154 22.8733C27.0634 20.3851 27.9549 17.2638 27.6476 14.1425C27.3402 11.0212 25.8571 8.13367 23.4989 6.06566C21.1408 3.99766 18.0843 2.90402 14.9494 3.00662C11.8146 3.10921 8.83613 4.40034 6.61827 6.6181C4.40041 8.83586 3.10921 11.8142 3.00662 14.9489C2.90402 18.0836 3.9977 21.14 6.0658 23.498C8.1339 25.856 11.0216 27.3391 14.143 27.6465C17.2644 27.9538 20.3859 27.0623 22.8742 25.1529L29.2481 31.5279C29.3978 31.6776 29.5755 31.7963 29.7711 31.8773C29.9667 31.9583 30.1763 32 30.388 32C30.5997 32 30.8093 31.9583 31.0049 31.8773C31.2005 31.7963 31.3782 31.6776 31.5279 31.5279C31.6775 31.3782 31.7963 31.2005 31.8773 31.0049C31.9583 30.8093 32 30.5997 32 30.3881C32 30.1764 31.9583 29.9668 31.8773 29.7712C31.7963 29.5756 31.6775 29.3979 31.5279 29.2482L31.5225 29.2442ZM6.24552 15.3639C6.24552 13.5604 6.78033 11.7975 7.78234 10.2979C8.78434 8.79838 10.2085 7.62963 11.8748 6.93947C13.5411 6.24931 15.3746 6.06873 17.1435 6.42058C18.9124 6.77242 20.5373 7.64087 21.8126 8.91612C23.0879 10.1914 23.9564 11.8161 24.3082 13.585C24.6601 15.3538 24.4795 17.1872 23.7893 18.8534C23.0991 20.5196 21.9303 21.9437 20.4307 22.9457C18.9311 23.9477 17.168 24.4825 15.3645 24.4825C12.9467 24.48 10.6287 23.5185 8.91914 21.8089C7.20954 20.0994 6.248 17.7815 6.24552 15.3639Z" fill="#1D1D1F"/>
        </svg>
      </div>

      <!-- 、歌手 -->
      <input
        type="text"
        placeholder="搜索歌曲、歌手"
        v-model="keyword"
        class="fast-search-input"
        :disabled="!isSearch"
        ref="searchInput"
        @keydown="handleSearchKeydown($event)"
      >
      <div v-show="!isSearch" class="fast-search-mask"></div>

      <div
        class="icon delete"
        :style="{
          opacity: keyword ? 1 : 0
        }"
        @click="keyword = ''"
      >
        <svg  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.4">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74219 26.3708L5.62781 28.2564L16 17.8842L26.3724 28.2566L28.258 26.371L17.8856 15.9986L28.2552 5.62903L26.3696 3.74341L16 14.113L5.6306 3.74361L3.74498 5.62922L14.1144 15.9986L3.74219 26.3708Z" fill="#1D1D1F"/>
          </g>
        </svg>
      </div>
    </div>
    <div v-if="isSearch" class="fast-search-btn" @click="handleSearch">
      搜索
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue'

export default {
  name: 'SearchBar',
  props: {
    isSearch: Boolean,
  },
  setup(props, { emit }) {
    let keyword = ref('')
    const searchInput = ref(null)
    const { isSearch } = toRefs(props)

    const handleToSearch = () => {
      if (isSearch.value) return

      emit('go-search')
      setTimeout(() => {
        searchInput.value.focus()
      }, 0)
    }

    const handleSearch = () => {
      if (!keyword.value || !keyword.value.trim()) return
      emit('search', keyword.value)
    }

    const handleSearchKeydown = (e) => {
      if (e.keyCode == 13) {
        handleSearch()
      }
    }

    const changeInput = (e) => {
      keyword.value = e
      handleSearch()
    }
    
    return {
      keyword,
      searchInput,
      handleToSearch,
      handleSearch,
      handleSearchKeydown,
      changeInput,
    }
  }
}
</script>

<style lang="stylus" scoped>
.fast-search
  display flex
  align-items center
  justify-content space-between
  width 704px
  margin-left 48px
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 100% !important
    padding 0 64px
  &-main
    display flex
    align-items center
    position relative
    width 100% !important
    height 88px
    border: 2px solid #1D1D1F33
    border-radius 20px
  .icon
    position absolute
    left 30px
    top 28px
    width 32px
    height 32px
  .delete
    left unset !important
    right 28px
  svg
    width 32px
    height 32px
    margin 0px
  &-input
    width 95% !important
    height 100%
    padding-left 80px
    display flex
    align-items center
    font-size 32px
    color #1D1D1FCC
  &-input::placeholder
    color rgba(29, 29, 31, 0.4)
  &-mask
    position absolute
    top 0
    right 0
    left 0
    bottom 0
    width 100%
    height 100%
  &-btn
    width 200px
    height 88px
    line-height 88px
    border-radius 20px
    backdrop-filter blur(200px)
    background: #A04AF0
    color: #FFFFFFCC
    text-align center
    font-size 32px
    font-weight 400
    margin-left 24px
  .width_480
    width 480px

.pos-ab
  @media screen and (max-width 1200px) and (min-height 1200px)
    margin 0px
    .fast-search-main
      width auto !important
      flex 1
</style>