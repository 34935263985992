<template>
  <div class="adj-volume">
    <div class="adj-volume-title side-title">
      <div class="left">调音</div>

      <svg class="close" @click="$emit('close')" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74219 26.3708L5.62781 28.2564L16 17.8842L26.3724 28.2566L28.258 26.371L17.8856 15.9986L28.2552 5.629L26.3696 3.74338L16 14.113L5.6306 3.74358L3.74498 5.6292L14.1144 15.9986L3.74219 26.3708Z" fill="#1D1D1F"/>
        </g>
      </svg>
    </div>
    <div class="adj-volume-main">
      <EffectSlider
        :defaultValue="videoVolume"
        @update="onVolumeChange"
      >
        <div class="adj-volume-main-item">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
            <circle cx="10" cy="31" r="5" stroke="#1D1D1F" stroke-width="2"/>
            <circle cx="27" cy="26" r="5" stroke="#1D1D1F" stroke-width="2"/>
            <path d="M15 30V10L32 5V25.5" stroke="#1D1D1F" stroke-width="2" stroke-linecap="square"/>
            </g>
          </svg>

          伴奏
        </div>
      </EffectSlider>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import store from '@/store'
import EffectSlider from './slider.vue'
import eventBus from '@/utils/event-bus'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'AdjVolume',
  components: {
    EffectSlider,
  },
  setup() {
    const videoVolume = computed(() => store.state.videoVolume)
    const onVolumeChange = (val) => {
      store.commit('UPDATE_MV_VIDEO_VOLUME', val)
      eventBus.emit('effect-adjust-tuner-volume-change', val)
      sendLog({
        event_type: '10000~50000',
        event_name: 10093,
        event_data: {
          str1: '调音',
          str2: '伴奏',
          str3: '调节伴奏',
          str4: 'click',
        },
      })
    }

    return {
      videoVolume,
      onVolumeChange
    }
  },
}
</script>

<style lang="stylus" scoped>
.adj-volume
  width 100%
  height 100vh
  padding 0 48px
  position relative
  &-main
    width 100%
    height auto
    &-hint
      font-size 28px
      color rgba(255, 255, 255, .4)
      margin-bottom 8px
    &-item
      padding-top 0px
      color #1D1D1F99
      font-size 26px
      svg
        width 40px
        height 40px
        margin 0 auto
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding 0px
    :deep(.v-slider)
      width 976px !important
      .name
        margin 0 48px 0 28px
  //   &-title
  //     margin 0
  //     .left
  //       font-size 32px
  //   &-main
  //     margin-top 55px
  //     &-item
  //       font-size 20px
  //       background-size 32px 32px
</style>