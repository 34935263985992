<template>
  <div class="top-menu">
    <div class="menu">
      <!-- 快速点歌 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('快速点歌', 'bottom', 10070)"
      >
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M45.0788 26.8036C50.1502 31.875 50.1502 40.0974 45.0788 45.1688C44.9235 45.3241 44.7653 45.4746 44.6043 45.6204C44.4458 45.6655 44.2963 45.7504 44.1716 45.8751C44.0876 45.9591 44.0217 46.0543 43.9738 46.156C38.8758 50.2156 31.4314 49.8865 26.7137 45.1688C21.6423 40.0974 21.6423 31.875 26.7137 26.8036C31.7851 21.7322 40.0074 21.7322 45.0788 26.8036ZM44.8719 47.9896C39.002 52.3904 30.6379 51.9215 25.2995 46.583C19.447 40.7306 19.447 31.2418 25.2995 25.3894C31.1519 19.5369 40.6406 19.5369 46.493 25.3894C52.3455 31.2418 52.3455 40.7306 46.493 46.583C46.4598 46.6163 46.4264 46.6493 46.3929 46.6822L54.7684 55.0577C55.1589 55.4483 55.1589 56.0814 54.7684 56.4719C54.3779 56.8625 53.7447 56.8625 53.3542 56.4719L44.8719 47.9896Z" fill="white"/>
        </svg>
        <div class="menu-item-text">快速点歌</div>
      </div>
      <!-- 调音 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('调音', 'bottom', 10072)"
      >
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0001 22H27.0001V31.1001C26.677 31.0346 26.3425 31.0001 26.0001 31.0001C25.6576 31.0001 25.3232 31.0346 25.0001 31.1001V22ZM26.0001 44.0001C26.3425 44.0001 26.677 43.9657 27.0001 43.9001V57.9998H25.0001V43.9001C25.3232 43.9657 25.6576 44.0001 26.0001 44.0001ZM26 31.2858C23.7909 31.2858 22 33.0766 22 35.2858V39.5714C22 41.7806 23.7909 43.5714 26 43.5714C28.2091 43.5714 30 41.7806 30 39.5714V35.2858C30 33.0766 28.2091 31.2858 26 31.2858ZM24 35.2858C24 34.1812 24.8954 33.2858 26 33.2858C27.1046 33.2858 28 34.1812 28 35.2858V39.5714C28 40.676 27.1046 41.5714 26 41.5714C24.8954 41.5714 24 40.676 24 39.5714V35.2858Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0001 22H41.0001V40.1001C40.677 40.0346 40.3425 40.0001 40.0001 40.0001C39.6576 40.0001 39.3232 40.0346 39.0001 40.1001V22ZM40.0001 53.0001C40.3425 53.0001 40.677 52.9657 41.0001 52.9001V57.9998H39.0001V52.9001C39.3232 52.9657 39.6576 53.0001 40.0001 53.0001ZM40 40.2858C37.7909 40.2858 36 42.0766 36 44.2858V48.5714C36 50.7806 37.7909 52.5714 40 52.5714C42.2091 52.5714 44 50.7806 44 48.5714V44.2858C44 42.0766 42.2091 40.2858 40 40.2858ZM38 44.2858C38 43.1812 38.8954 42.2858 40 42.2858C41.1046 42.2858 42 43.1812 42 44.2858V48.5714C42 49.676 41.1046 50.5714 40 50.5714C38.8954 50.5714 38 49.676 38 48.5714V44.2858Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M53.0001 22H55.0001V31.1001C54.677 31.0346 54.3425 31.0001 54.0001 31.0001C53.6576 31.0001 53.3232 31.0346 53.0001 31.1001V22ZM54.0001 44.0001C54.3425 44.0001 54.677 43.9657 55.0001 43.9001V57.9998H53.0001V43.9001C53.3232 43.9657 53.6576 44.0001 54.0001 44.0001ZM54 31.2858C51.7909 31.2858 50 33.0766 50 35.2858V39.5714C50 41.7806 51.7909 43.5714 54 43.5714C56.2091 43.5714 58 41.7806 58 39.5714V35.2858C58 33.0766 56.2091 31.2858 54 31.2858ZM52 35.2858C52 34.1812 52.8954 33.2858 54 33.2858C55.1046 33.2858 56 34.1812 56 35.2858V39.5714C56 40.676 55.1046 41.5714 54 41.5714C52.8954 41.5714 52 40.676 52 39.5714V35.2858Z" fill="white"/>
        </svg>
        <div class="menu-item-text">调音</div>
      </div>
      <!-- 画质 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('画质', 'bottom', 10076)"
      >
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 24H18V25V55V56H19H61H62V55V25V24H61H19Z" stroke="white" stroke-width="2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M38.571 32.7319H36.569V38.9579H29.023V32.7319H27.043V47.9999H29.023V40.6079H36.569V47.9999H38.571V32.7319ZM54.3891 40.3659C54.3891 34.0079 51.3971 32.7319 47.0191 32.7319H42.6191V47.9999H47.0191C51.3971 47.9999 54.3891 46.2619 54.3891 40.3659ZM52.3431 40.3659C52.3431 44.9639 50.1651 46.2619 46.9751 46.2619H44.5991V34.4479H46.9751C50.0771 34.4479 52.3431 35.4819 52.3431 40.3659Z" fill="white"/>
        </svg>
        <div class="menu-item-text">画质</div>
      </div>
      <!-- 已点 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('已点', 'bottom', 10078)"
      >
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 24L42 24V26L22 26V24Z" fill="white"/>
          <path d="M22 38H37V40H22V38Z" fill="white"/>
          <path d="M22 52H32V54H22V52Z" fill="white"/>
          <circle cx="46.5" cy="47.5" r="7.5" stroke="white" stroke-width="2"/>
          <path d="M54 46V24L58 27.5652" stroke="white" stroke-width="2" stroke-linecap="square"/>
        </svg>
        <div class="menu-item-text">已点</div>
      </div>
      <!-- 个人中心 -->
      <div
        class="menu-item"
        @click.stop="handleShowSideBar('我的特权', 'bottom')"
      >
        <img class="menu-item-header" v-if="headerimg" :src="headerimg" />
        <svg v-else width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_b_533_32383)">
          <g clip-path="url(#clip0_533_32383)">
          <rect width="80" height="80" rx="40" fill="#383A3E" fill-opacity="0.8"/>
          <ellipse opacity="0.4" cx="40.001" cy="30.9677" rx="9.03226" ry="11.6129" fill="white"/>
          <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M65.8559 63.3516C59.4805 70.406 50.2586 74.8387 40.0013 74.8387C29.7429 74.8387 20.52 70.4049 14.1445 63.3491C20.8629 58.4767 29.97 55.4839 39.9985 55.4839C50.0287 55.4839 59.1371 58.4777 65.8559 63.3516Z" fill="white"/>
          </g>
          </g>
          <defs>
          <filter id="filter0_b_533_32383" x="-38.7097" y="-38.7097" width="157.419" height="157.419" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="19.3548"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_533_32383"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_533_32383" result="shape"/>
          </filter>
          <clipPath id="clip0_533_32383">
          <rect width="80" height="80" rx="40" fill="white"/>
          </clipPath>
          </defs>
        </svg>
        <div class="menu-item-text">我的</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'VideoPlayerTopMenu',
  props: {
    headerimg: {
      type: String,
      default: '',
    },
    audioTrackMap: {
      type: Object,
      default() {
        return {
          acc: {},
          org: {},
        }
      },
    },
    enabledAudioTrackId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const handleSwitchAudioTrack = (audioTrack, pos) => {
      emit('switch-audio-track', audioTrack)

      // sendLog({
      //   event_type: '10000~50000',
      //   event_name: pos === 'left' ? 10074 : 10075,
      //   event_data: {
      //     str1: 'MV',
      //     str2: '播控',
      //     str3: `原唱${pos === 'left' ? '左' : '右'}`,
      //     str4: 'click',
      //   },
      // })
    }

    const handleShowSideBar = (name, pos, logname) => {
      emit('show-side-bar', { name, pos })

      // sendLog({
      //   event_type: '10000~50000',
      //   event_name: pos === 'left' ? logname : logname + 1,
      //   event_data: {
      //     str1: 'MV',
      //     str2: '播控',
      //     str3: `${name}${pos === 'left' ? '左' : '右'}`,
      //     str4: 'click',
      //   },
      // })
    }

    return {
      handleShowSideBar,
      handleSwitchAudioTrack,
    }
  },
}
</script>

<style lang="stylus" scoped>
.top-menu
  position absolute
  top 35px
  width 100%
  display flex
  align-items center
  justify-content center
  color #ffffff
  font-size 24px
  z-index 11
  display none
  height fit-content
  @media screen and (max-width 1200px) and (min-height 1200px)
    top auto
    bottom 100px
    display flex
  .menu
    width 850px
    height auto
    padding 30px 0px 16px
    display flex
    align-items center
    justify-content space-between
    &-item
      width 130px
      height 120px
      margin-bottom 10px
      display flex
      flex-direction column
      justify-content center
      align-items center
      &-icon
        width 80px
        height 80px
      &-header
        width 48px
        height 48px
        border-radius 50%
        margin-top 16px
        margin-bottom 16px
      &-text
        font-size 22px
        color rgba(255, 255, 255, 0.50)
        height 25px
        line-height 25px
</style>

