<template>
  <div class="mv-user-vip">
    <div class="mv-user-vip-back side-title">
      <div class="left">
        我的特权
      </div>
      <svg class="close" @click="$emit('close')" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74219 26.3708L5.62781 28.2564L16 17.8842L26.3724 28.2566L28.258 26.371L17.8856 15.9986L28.2552 5.629L26.3696 3.74338L16 14.113L5.6306 3.74358L3.74498 5.6292L14.1144 15.9986L3.74219 26.3708Z" fill="#1D1D1F"/>
        </g>
      </svg>
    </div>
    <template v-if="isLogin">
      <MineVip v-if="browserType === 'landscape'" from="mv" />
      <MineVipC v-else from="mv" />
    </template>
    <div v-else class="mv-user-vip-unlogin">
      <h3>登录查看特权</h3>
      <p>扫码登录，记录唱过的歌</p>
       <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onBeforeMount, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import { Toast } from 'vant'
import get from 'lodash/get'
import MineVip from '@/components/vip/index.vue'
import MineVipC from '@/pages/mine/components/vipc.vue'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import { useShareBrowserSize } from '@/composables/sharedComposable'

export default {
  name: 'Profile',
  components: {
    MineVip,
    MineVipC,
  },
  setup() {
    const store = useStore()
    const { browserType } = useShareBrowserSize()
    const isLogin= computed(() => !!store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()

    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')
    const userType = computed(() => store.state.userInfo.userType)
    
    const getLoginQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'login_qr', '')) {
        const loginUrl = `${data.pay_qr}&log=${vipLogFrom.get('MV界面')}&ut=${userType.value}`
        const qrCodeData = await getQRCodeURL(loginUrl)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }
    
    onBeforeMount(getLoginQrcode)

    onMounted(() => {
      sendLog({
        event_type: '10000~50000',
        event_name: isLogin.value ? 30196 : 30105,
        event_data: {
          str1: '欢唱页',
          str2: '我的特权',
          str3: isLogin.value ? '支付码' : '登录码',
          str4: 'show',
        },
      })
    })

    return {
      isLogin,
      qrCodeURL,
      browserType,
    }
  }
}
</script>

<style lang="stylus" scoped>
.mv-user-vip
  width 100%
  @media screen and (max-width 1200px) and (min-height 1200px)
    .user-vip
      padding 0 64px
  &-back
    padding 0 48px
  &-unlogin
    display flex
    flex-direction column
    align-items center
    padding-top 171px
    h3
      color: #1D1D1FCC
      font-size: 44px;
      font-weight: 700;
    p
      color: #1D1D1F66
      font-size: 24px;
    .qrcode
      width: 266px;
      height: 266px;
      border-radius 10px
      background #fff
      margin-top 48px
      img
        width 246px
        height 246px
        margin 10px
</style>
