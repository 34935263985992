import { setAlreadyList, setOrderedList } from '@/utils/historyCache'
import { computed } from 'vue'
import { useStore } from 'vuex'
import useOrder from './useOrder'
import useSongItem from './useSongItem'

export default function useAlready() {
  const store = useStore()
  const { orderSong: normalOrderSong } = useSongItem()
  const { stickSongToTop: stickSongToOrderedTop, orderedListNumber } = useOrder()

  const alreadyList = computed(() => store.state.alreadyList)

  const addSong = (songItem) => {
    store.commit('PUSH_SONG_TO_ALREADY_LIST', songItem)
  }

  // 已唱列表点歌
  const orderSong = (songItem, afterCallback, option) => {
    let isImmediate = false
    if (orderedListNumber.value === 0) {
      isImmediate = true
    }
    // console.log('option', option)
    normalOrderSong(songItem, {
      immediate: isImmediate,
      isNotSingImmediately: option.isNotSingImmediately,
      from: {
        song_list_source: 4,
        fr2: '已唱列表会员歌曲'
      },
      afterOrder: () => {
        console.log("already afterOrder")
        if (typeof afterCallback === 'function') {
          afterCallback.call(null, {
            orderedListNumber: orderedListNumber.value,
            immediate: isImmediate
          })
        }
      }
    })
    setOrderedList()
    // 已唱列表中删除该歌曲 VEC-869
    // deleteSong(index)
  }

  // 置顶
  const stickSongToTop = (songItem) => {
    // 添加到已点
    orderSong(songItem, null, {
      isNotSingImmediately: false
    })
    // 已点列表中置顶该歌曲 不传索引 默认为已点列表最后一个
    stickSongToOrderedTop()
  }

  // 删除
  const deleteSong = (index) => {
    store.commit('DELETE_SONG_ALREADY_LIST', index)
    setAlreadyList()
  }

  return {
    orderSong,
    stickSongToTop,
    deleteSong,
    alreadyList,
    addSong,
  }
}