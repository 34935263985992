<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div
      class="vip-loggedin-modal-content"
      :class="[fromType, isExpired && 'expired']"
    >
      <div class="close" @click="handleCloseModal"></div>
      <div class="vip-packages">
        <div
          class="vip-packages-item"
          v-for="item in packages"
          :key="item.id"
          :class="item.id === selectItem.id && 'active'"
          @click="handleClickItem(item)"
        >
          <div v-if="item.tips" class="tips" :class="item.isOrange && 'orange'">
            {{ item.tips }}
          </div>
          <div class="days">{{ item.title }}</div>
          <div class="day-price">
            ¥&nbsp;&nbsp;<span>{{ formatValue(item.day_fee) }}</span
            >/天
          </div>
          <div class="price">
            <span>¥{{ formatValue(item.fee) }}</span>
            <span v-if="item.old_fee !== item.fee" class="origin"
              >¥{{ formatValue(item.old_fee) }}</span
            >
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <!-- 分割线 -->
      <div class="pay-info">
        <div class="left">
          <div v-if="packages.length == 3" class="price">
            <span>{{ formatValue(selectItem.fee) }}</span
            >元
          </div>
          <div v-else class="price" v-html="formatPayTitle"></div>
          <h3>微信扫码支付</h3>
          <p>有效期至-{{ expirationDate }}</p>
        </div>
        <div class="code">
          <img :src="qrCodeURL" alt="" />
        </div>
      </div>
      <!--      </div>-->
    </div>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
// import useRetainModal from '@/composables/useRetainModal'
import useActivity from '@/composables/useActivity'
import { sendLog } from '@/directives/v-log/log'
import {
  clearVipQueryPolling,
  getVipPkg,
  getVipPkgQr,
  getVipQuery,
} from '@/service/vip'
import store from '@/store'
import { addDays, format } from 'date-fns'
import get from 'lodash/get'
import { computed, onBeforeMount, ref, toRefs } from 'vue'

export default {
  name: 'VipModalQRcode',
  components: {
    CommonModal,
  },
  props: {
    fr: {
      type: Number,
      default: 0, // 支付来源fr
    },
    logInfo: {
      type: Object,
      default: () => {},
    },
    fromType: {
      type: String,
      default: '',
    },
    closeCallback: {
      type: Function,
      default: null,
    },
    isShowRetain: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { fr, logInfo, closeCallback, fromType } = toRefs(props) // , isShowRetain
    const { getQRCodeURL } = useQRCode()
    const { showActivityLowerVip } = useActivity()

    const vipInfo = computed(() => store.state.vipInfo)
    const isVip = computed(() => !!vipInfo.value.end_time)
    const unionid = computed(() => store.state.userInfo.unionid)
    const lowerVipShowTag = computed(() => store.state.lowerVipShowTag)
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )
    // const { showRetainModal, getRetainModalTag, setRetainModalTag } =
    //   useRetainModal()

    const packages = ref([])
    const selectItem = ref({})

    const isExpired = computed(() => {
      // return true;
      return !isVip.value && vipInfo.value.expire
    })

    const expirationDate = computed(() => {
      if (!selectItem.value.days) return ''
      const currentDate = isVip.value
        ? new Date(vipInfo.value.end_time)
        : new Date()
      const expirationDate = addDays(currentDate, selectItem.value.days)

      return format(expirationDate, 'yyyy.MM.dd')
    })

    const formatPayTitle = computed(() => {
      const regex = /(\d+)(年|天)/g
      if (!selectItem.value.title) return ''
      const matches = selectItem.value.title.match(regex)
      let formatted = ''
      if (matches) {
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i].match(/(\d+)(年|天)/)
          const number = match[1]
          const unit = match[2]
          formatted += '<span>' + number + '</span>' + unit
          if (i < matches.length - 1) {
            formatted += ' '
          }
        }
      }
      return formatted
    })

    const formatValue = (value) => {
      if (value === undefined || isNaN(value)) {
        return 'N/A'
      }
      return value / 100
    }

    const getVipQrcode = async () => {
      try {
        let frStr = fr.value
        if (fr.value == 1873) frStr = 1874
        if (fr.value == 1875) frStr = 1876
        if (fr.value == 1878) frStr = 1879

        // 如果是过期会员
        // if (isExpired.value) {
        //   frStr = 1891;
        // }

        if (fr.value == 1896) frStr = 1896
        if (fr.value == 1894) frStr = 1894

        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: selectItem.value.id,
          fr: frStr,
        })

        const qr = get(data, 'qr', '')
        if (qr) {
          const qrCodeData = await getQRCodeURL(qr)
          if (qrCodeData) {
            qrCodeURL.value = qrCodeData
            await getVipQuery(data.order_id)
            store.dispatch('getCarplayInfo')
            root.value.hide()
            clearVipQueryPolling()
          }
        }
      } catch (error) {
        // Toast('发生错误，请重试');
        root.value.hide()
        clearVipQueryPolling()
      }
    }

    const handleCloseModal = async () => {
      // 手动关闭时统一做一次用户信息更新 处理用户在个人中心模块-前往开通时，关闭弹窗刷新用户会员信息
      await store.dispatch('getCarplayInfo')
      root.value.hide()
      clearVipQueryPolling()
      console.log('handleCloseLogin 1')

      // 关闭后如果用户是vip不再进行弹框
      // 每隔一次显示 fromType === 'vip-expired'
      if (fromType.value === 'vip-expired' && !isVip.value) {
        if (lowerVipShowTag.value % 2 === 0) {
          showActivityLowerVip()
        }
        store.commit('UPDATE_LOWERVIP_SHOWTAG')
      }

      // if (isShowRetain.value && !getRetainModalTag()) {
      //   showRetainModal()
      //   setRetainModalTag()
      // }
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
      if (isExpired.value) {
        sendLog({
          event_type: '10000~50000',
          event_name: 30299,
          event_data: {
            str1: '通用',
            str2: '续费弹窗-关闭',
            str3: '点击',
            str4: 'click',
          },
        })
      }
      closeCallback.value && closeCallback.value()
    }

    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
      if (isExpired.value) {
        sendLog({
          event_type: '10000~50000',
          event_name: 30299,
          event_data: {
            str1: '通用',
            str2: '续费弹窗-关闭',
            str3: '点击',
            str4: 'click',
          },
        })
      }
      closeCallback.value && closeCallback.value()
    }

    const handleClickItem = (item) => {
      qrCodeURL.value =
        'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
      selectItem.value = item
      getVipQrcode()
    }

    onBeforeMount(async () => {
      const res = await getVipPkg(unionid.value)
      packages.value = res.data // 原始数据赋值给packages.value

      if (packages.value.length === 3) {
        const index = packages.value.findIndex((item) => !!item.tips)
        packages.value[index].isOrange = true
      } else {
        const reversedIndex = packages.value
          .slice()
          .reverse()
          .findIndex((item) => !!item.tips)
        const index = packages.value.length - 1 - reversedIndex
        packages.value[index].isOrange = true
      }

      const active =
        packages.value.find((item) => item.id === res.recommend_pkg) ||
        packages.value[0]
      selectItem.value = active
      getVipQrcode()

      if (logInfo.value?.event_name) {
        sendLog({
          event_type: '10000~50000',
          event_name: logInfo.value.event_name,
          event_data: logInfo.value.event_data,
        })
      }

      if (isExpired.value) {
        sendLog({
          event_type: '10000~50000',
          event_name: 30298,
          event_data: {
            str1: '通用',
            str2: '续费弹窗',
            str3: '展示',
            str4: 'show',
          },
        })
      }
    })

    return {
      handleCloseModal,
      handleClickItem,
      handleCancel,
      isVip,
      packages,
      selectItem,
      expirationDate,
      qrCodeURL,
      root,
      formatValue,
      formatPayTitle,
      isExpired,
    }
  },
}
</script>
<style lang="stylus" scoped>
.vip-loggedin-modal
  &-content
    width 1100px
    height 746px
    border-radius 20px
    //background-color rgba(25, 25, 25, 1)
    background-image url(https://qncweb.ktvsky.com/20240914/vadd/c087a34a2dd1e75008e991d088f5aeff.png)
    background-size 100% auto
    background-repeat no-repeat
    position relative
    display flex /* 将 .vip-loggedin-modal-content 设置为 flex 容器 */
    flex-direction row /* 将子元素排列方向改为水平 */
    justify-content space-around /*  平均分配子元素 */
    align-items center /*  垂直居中 */
    &.expired
      height 730px
      background-image url(https://qncweb.ktvsky.com/20240920/vadd/9dbdc9e9c74b268fe89565b20ab0760f.png)
      background-size 100% auto
      background-repeat no-repeat
      .close
        position absolute
        top 30px
        right 30px
        width 32px
        height 32px
        background-image url(https://qncweb.ktvsky.com/20240920/vadd/7925b36187770ef6540603dae04851d9.png)
        background-size 100% auto
        background-repeat no-repeat
      .vip-packages
        bottom 244px
      .pay-info
        bottom 244px
        right 51px
    @media screen and (max-width 1200px) and (min-height 1200px)
      zoom 0.8
    .close
      position absolute
      top 60px
      right 25px
      left unset!important
      width 40px
      height 40px
    .vip-packages
      display flex
      justify-content center
      //flex-wrap wrap
      width 774px
      height 252px
      position absolute /* 设置为绝对定位 */
      left 36px /* 距离左侧 51px */
      bottom 206px /* 距离底部 246px */
      &-item
        width: 180px;
        height: 234px;
        border-radius: 20px;
        background #fff
        position relative
        text-align center
        margin 0 8px
        border 1.6px solid #F2C3A1B0
        &.active
          background: linear-gradient(334.35deg, #FFC2B0 -26.03%, #FFF3EF 107.54%); linear-gradient(334.35deg, color(display-p3 0.984 0.769 0.702) -26.03%, color(display-p3 1.000 0.957 0.941) 107.54%);
          border 1.6px solid #ED7061
          .days
            background: linear-gradient(270deg, #615F66 0%, #222124 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          .price
            color rgba(172, 98, 0, 0.8)
            .origin
              color rgba(172, 98, 0, 0.5)
          .day-price
            background: linear-gradient(270deg, #E53700 0%, #FF4D00 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        .tips
          padding 0 10px
          height 40px
          line-height 40px
          font-size 20px
          color #fff
          position absolute
          left 0
          top -20px
          background linear-gradient(90deg, #FF3D6B 0%, #8C1AFF 100%)
          border-radius 10px 10px 10px 0
          &.orange
            background linear-gradient(90deg, #ff3d3d 0%, #ff881a 100%)
        .days
          background: linear-gradient(270deg, #625F66 0%, #222124 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size 26px
          font-weight 300
          margin 39px 0 3px
        .price
          color #883700
          font-size: 22px;
          font-weight 300
          .origin
            color rgba(172, 98, 0, 0.5)
            text-decoration line-through
            margin-left 8px
        .day-price
          color rgba(136, 55, 0, 1)
          font-size: 22px;
          font-weight 500
          margin 8px 0
          span
            font-size 46px
            font-weight 700
            font-family 'DIN Alternate', sans-serif
    .divider
      position absolute
      width 2px
      height 233px
      background-color rgba(220, 163, 79, 0.1) // 设置颜色和透明度
      left 850px
      bottom 224px
    .pay-info
      display flex
      justify-content center
      flex-direction column /* 将子元素排列方向改为垂直 */
      align-items center
      position absolute
      right 51px /* 距离右侧 51px */
      bottom 226px /* 距离底部 246px */
      .left
        width 176px
        text-align center
        .price
          color rgba(136, 55, 0, 1)
          font-size 22px
          //margin-bottom 10px
          span
            font-size 64px
            line-height 85px
        h3
          color rgba(0, 0, 0, 0.8)
          font-size 22px
          margin-bottom 6px
        p
          color rgba(0, 0, 0, 0.6)
          font-weight 400
          font-size 18px
      .code
        width 160px
        height 160px
        background #fff
        border-radius 5px
        margin-top 8px
        img
          width 146px
          height 146px
          margin 7px
    &.climax
      height 780px
      background none
      position relative
      .close
        position absolute
        width 40px
        height 40px
        right 30px
        top 33px
        left initial
        background none
      .bg
        overflow hidden
        height 780px
        background url('https://qncweb.ktvsky.com/20240415/other/f42866874c18905e37fa28f61ffdbb71.png') no-repeat top left
        background-size 100% 100%
      .top
        display none
      .vip-packages
        margin-top 263px
</style>
