<template>
  <div class="song" :class="'song-item-' + renderType">
    <div
      v-if="renderType === 'list'"
      class="song-item"
      :class="[className, {
        ordered: isOrdered,
      }]"
    >
      <div class="left" @click="handleOrder">
        <div class="name">
          <span class="name-txt" v-html="highlightedName"></span>
        </div>
        <div class="desc">
          <template v-if="songItem.singer">
            <span
              class="author"
              :class="singerCanClick && 'clickable'"
              @click="handleClickSinger"
              >{{ songItem.singer }}</span
            >
          </template>
          <span v-if="songItem.flag" class="flag">{{ songItem.flag.toString() }}</span>
          <img
            v-if="songItem.is_vip"
            class="song-block-vip"
            src="https://qncweb.ktvsky.com/20241206/other/5589fc8a7694d35d4808e90e206b56d0.png"
            alt=""
          />
          <i v-else class="free-icon">免费</i>
          <img
            v-if="show1080Icon"
            class="icon-1080"
            src="https://qncweb.ktvsky.com/20240305/other/865db1ca5d7ce94af8cc4ae48d874879.png"
            alt=""
          />
          <img
            v-if="showNewSongIcon"
            class="icon-newsong"
            src="https://qncweb.ktvsky.com/20240325/vadd/6387b6385220cb3385dba761ab5aac23.png"
            alt=""
          />
        </div>
      </div>
      <div class="right">
        <svg
          @click.stop="handlePlay('play')"
          width="72"
          height="73"
          viewBox="0 0 72 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="mask0_332_8257" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="11" y="11" width="50" height="51">
          <rect x="11.25" y="11.75" width="49.5" height="49.5" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_332_8257)">
          <circle cx="43.0957" cy="30.3125" r="12.9375" stroke="#5500C3" stroke-width="3.375"/>
          <path d="M30.4746 33.2539L18.8216 48.8049C17.7163 50.2799 17.8634 52.343 19.1667 53.6463V53.6463C20.47 54.9496 22.5331 55.0966 24.0081 53.9914L39.559 42.3383" stroke="#5500C3" stroke-width="3.375"/>
          </g>
        </svg>
        <svg
          class="order-icon"
          @click.stop="handlePlay('order')"
          width="72"
          height="73"
          viewBox="0 0 72 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M38.3807 18.2C38.3807 17.2611 37.6196 16.5 36.6807 16.5C35.7418 16.5 34.9807 17.2611 34.9807 18.2V34.6818H18.2625C17.3236 34.6818 16.5625 35.4429 16.5625 36.3818C16.5625 37.3207 17.3236 38.0818 18.2625 38.0818H34.9807V54.8C34.9807 55.7389 35.7418 56.5 36.6807 56.5C37.6196 56.5 38.3807 55.7389 38.3807 54.8V38.0818H54.8625C55.8014 38.0818 56.5625 37.3207 56.5625 36.3818C56.5625 35.4429 55.8014 34.6818 54.8625 34.6818H38.3807V18.2Z" fill="#5500C3"/>
        </svg>
      </div>
    </div>
    <div
      v-else
      class="song-item song-block"
      :class="{
        ordered: isOrdered,
      }"
      @click="handleOrder"
    >
      <div class="left">
        <span class="name">{{ songItem.music_name }}</span>
        <div class="desc">
          <template v-if="songItem.singer">
            <span
              class="author"
              :class="singerCanClick && 'clickable'"
              @click="handleClickSinger"
              >{{ songItem.singer }}</span
            >
          </template>
          <span v-if="songItem.flag" class="flag">{{ songItem.flag.toString() }}</span>
          <span
            v-if="songItem.sing_cnt"
            class="sing-cnt"
          >演唱过 <span>{{ songItem.sing_cnt }}</span> 次</span>
          <div class="center">
            <img
              v-if="songItem.is_vip"
              class="song-block-vip"
              src="https://qncweb.ktvsky.com/20241206/other/5589fc8a7694d35d4808e90e206b56d0.png"
              alt=""
            />
            <i v-else class="free-icon">免费</i>
          </div>
        </div>
      </div>

      <div class="right">
        <svg
          v-if="isShowPlayBtn"
          @click.stop="handlePlay('play')"
          viewBox="0 0 72 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="mask0_332_8257" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="11" y="11" width="50" height="51">
          <rect x="11.25" y="11.75" width="49.5" height="49.5" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_332_8257)">
          <circle cx="43.0957" cy="30.3125" r="12.9375" stroke="#5500C3" stroke-width="3.375"/>
          <path d="M30.4746 33.2539L18.8216 48.8049C17.7163 50.2799 17.8634 52.343 19.1667 53.6463V53.6463C20.47 54.9496 22.5331 55.0966 24.0081 53.9914L39.559 42.3383" stroke="#5500C3" stroke-width="3.375"/>
          </g>
        </svg>
        <svg
          v-if="isShowOrderBtn"
          class="song-block-order"
          @click.stop="handlePlay('order')"
          width="72"
          height="73"
          viewBox="0 0 72 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M38.3807 18.2C38.3807 17.2611 37.6196 16.5 36.6807 16.5C35.7418 16.5 34.9807 17.2611 34.9807 18.2V34.6818H18.2625C17.3236 34.6818 16.5625 35.4429 16.5625 36.3818C16.5625 37.3207 17.3236 38.0818 18.2625 38.0818H34.9807V54.8C34.9807 55.7389 35.7418 56.5 36.6807 56.5C37.6196 56.5 38.3807 55.7389 38.3807 54.8V38.0818H54.8625C55.8014 38.0818 56.5625 37.3207 56.5625 36.3818C56.5625 35.4429 55.8014 34.6818 54.8625 34.6818H38.3807V18.2Z" fill="#5500C3"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, toRefs } from "vue";
import { useStore } from "vuex";
import useSongItem from "@/composables/useSongItem";
import useOrder from "@/composables/useOrder";
import { sendLog } from "@/directives/v-log/log";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "SongItem",
  props: {
    className: {
      type: String,
      default: "",
    },
    songItem: Object,
    index: Number,
    logFrom: {
      type: Object,
      default: () => {},
    },
    ponitActionLog: {
      type: Object,
      default: () => {},
    },
    singerEnable: {
      type: Boolean,
      default: true,
    },
    renderType: {
      type: String,
      default: "list",
    },
    isStickButton: {
      type: Boolean,
      default: false,
    },
    show1080Icon: {
      type: Boolean,
      default: false,
    },
    showNewSongIcon: {
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "",
    },
    isShowPlayBtn: {
      type: Boolean,
      default: false,
    },
    isShowOrderBtn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["singer-click", "order-click"],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const {
      songItem,
      logFrom,
      keyword,
      ponitActionLog,
      singerEnable,
      position,
      showNewSongIcon,
    } = toRefs(props);
    const store = useStore();
    const orderedSongIdMap = inject("orderedSongIdMap");
    const { orderSong } = useSongItem();
    const { immediatePlay, orderedList } = useOrder();
    const userInfo = computed(() => store.state.userInfo);
    const vipInfo = computed(() => store.state.vipInfo);
    const isOrdered = computed(() => orderedSongIdMap.value[songItem.value.songid]);
    const singerCanClick = computed(() => store.state.mvIsHide && !route.query.singerid || !store.state.mvIsHide && singerEnable.value);

    const handleOrder = (event, options = { type: "order" }) => {
      emit(options.type === "order" ? "order-click" : "");
      const orderOptions = {
        from: logFrom.value,
        ponitActionLog: ponitActionLog.value,
        isValidNewSong: showNewSongIcon.value,
        isNotSingImmediately: options.type === "order",
      };
      if (Object.keys(orderedSongIdMap.value).length === 0) {
        orderSong(songItem.value, orderOptions);
      } else {
        orderSong(songItem.value, { ...orderOptions, immediate: false });
      }
    };

    const handleClickSinger = (e) => {
      if (!singerCanClick.value) return;
      e.stopPropagation();
      sendLog({
        event_type: "10000~50000",
        event_name: 10108,
        event_data: {
          str1: "任意点歌页",
          str2: "歌曲列表",
          str3: "点击任意歌手",
          str4: "click",
        },
      });
      if (store.state.mvIsHide) {
        router.push({
          name: "songList",
          query: {
            name: songItem.value.singer.split(",")[0],
            image: songItem.value.singer_head,
            singerid: songItem.value.singerid,
          },
        });
      } else {
        emit("singer-click", {
          singer: songItem.value.singer.split(",")[0],
          singerhead: songItem.value.singer_head,
          singerid: songItem.value.singerid,
        });
        sendLog({
          event_type: "10000~50000",
          event_name: 10109,
          event_data: {
            str1: "MV点歌页",
            str2: "歌曲列表",
            str3: "点击任意歌手",
            str4: "click",
          },
        });
      }
    };

    const handlePlay = async (type) => {
      await handleOrder(null, { type });
      if (type === "play") {
        sendLog({
          event_type: "10000~50000",
          event_name: 30239,
          event_data: {
            str1: "通用",
            str2: "立即演唱",
            str3: "点击",
            str4: "click",
          },
        });
        immediatePlay(songItem.value, orderedList.value.length - 1);
      }
    };

    const highlightedName = computed(() => {
      if (!keyword.value) return songItem.value.music_name;
      const regex = new RegExp(`(${keyword.value})`, "gi");
      return songItem.value.music_name.replace(
        regex,
        '<span class="highlight">$1</span>'
      );
    });

    return {
      isOrdered,
      userInfo,
      vipInfo,
      highlightedName,
      handleOrder,
      handleClickSinger,
      singerCanClick,
      handlePlay,
    };
  },
};
</script>

<style lang="stylus" scoped>
.song
  width 100%
  height fit-content !important
  padding 0px
  &.song-item-list
    padding 0 40px
    border-radius 16px
    &:active
      background #0000000D
    @media screen and (max-width 1200px) and (min-height 1200px)
      padding 0 18px
      border-radius 8px
  .song-item
    width 100%
    display flex
    align-items center
    justify-content space-between
    height 144px
    color #1D1D1FCC
    border-bottom 1px solid #1D1D1F1A
    padding 0px
    @media screen and (max-width 1200px) and (min-height 1200px)
      height 136px
    .icon-1080
      width 72px
      margin-left 12px
    .free-icon
      width 56px
      height 26px
      display flex
      align-items center
      justify-content center
      background: linear-gradient(90deg, #009BCB -0.89%, #3D73FF 99.11%);
      color #fff
      font-size 18px
      border-radius 100px
      margin-left 11px
    .icon-newsong
      width 102px
      margin-left 12px

    .song-block-vip
      width 50px
      height 26px
      margin-left 13px
    .left
      flex 1
      display flex
      flex-direction column
      max-width 68%
      @media screen and (max-width 1200px) and (min-height 1200px)
        max-width 65%
    .name
      font-size var(--font-size-song-name)
      margin-bottom 10px
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    .desc
      display flex
      align-items center
      height 33px
      font-size var(--font-size-song-subtitle)
      color #1D1D1F80
      .clickable
        position relative
        padding-right 26px
        &::after
          content ""
          position absolute
          right 0
          top 50%
          margin-top -10px
          width 22px
          height 22px
          background url('https://qncweb.ktvsky.com/20241127/other/04c0294caf0a05c6cd2e59439c73cdab.png') no-repeat
          background-size 100% 100%
      .divider
        margin -4px 16px 0 16px
      .sing-cnt
        margin-left 4px
        border none
        font-size 16px
        color #1D1D1F80
        border 2px solid #1D1D1F80
        border-radius 30px
        white-space nowrap
        padding 0 10px
        margin-left 10px
        span
          color #A04AF0
      .author
        max-width 320px
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
      .flag
        position relative
        padding-left 14px
        margin-left 12px
        white-space nowrap
        &:after
          content ""
          position absolute
          left 0px
          top 50%
          margin-top -11px
          width 2px
          height 22px
          background #1D1D1F66
    .right
      display flex
      align-items center
      margin-right 20px
      svg
        width 72px
        height 72px
        &:first-child
          margin-right 72px

        @media screen and (max-width 1200px) and (min-height 1200px)
          width 64px
          height 64px
          &:first-child
            margin-right 48px
    &.ordered
      .left
        .name, .desc
          color #A04AF0 !important
        .clickable::after
          background-image url('https://qncweb.ktvsky.com/20241127/other/50b7f338d68b13c0236b56276c2e5c1d.png')

  .song-block
    width 498px !important
    height 160px !important
    background #fff
    padding-left 36px !important
    padding-right 36px !important
    border-radius 16px
    .left
      max-width 100%
    &:active
      background #fff !important
    .name
      max-width 400px
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size var(--font-size-large) !important
    .desc
      font-size var(--font-size-medium) !important
</style>
